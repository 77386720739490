import React, {useContext} from 'react';

import {ForDevelopersContext} from '../../../context/DevelopersContext';

import styles from './merchantAPIReference.module.scss';


export const MerchantAPIReference: React.FunctionComponent = () => {

  const context = useContext(ForDevelopersContext);

  return (
    <div className={styles.merchantAPIReference}>
      <div className={styles.content}>
        <img
          className={styles.imageOffice}
          src={context?.dataContext.merchant_api_image}
          alt="office"
        />
        <div className={styles.textContent}>
          <h3 className={styles.title}>{context?.dataContext.merchant_api_title}</h3>
          <p className={styles.text}>
            {context?.dataContext.merchant_api_subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MerchantAPIReference;
