import React, {useContext} from 'react';

import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {HomeContext} from '../../../context/HomeContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './welcomeBlock.module.scss';

export const WelcomeBlock: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const onClick = () => {
    if (isValidURL(context?.dataContext.certificate_button_link)) {
      window.open(context?.dataContext.certificate_button_link, '_blank');
    }
  };
  return (
    <div className={styles.welcomeBlock}>
      <div className={styles.whiteAngle} />
      <div className={styles.background}>
        <h1 className={styles.title}>
          {context?.dataContext.main_screen_title}
        </h1>
        <h2
          className={styles.subtitle}
        >{context?.dataContext.main_screen_subtitle}</h2>
        <div className={styles.card}>
          <h1 className={styles.titleCard}>
            {context?.dataContext.certificate_title}
          </h1>
          <h2 className={styles.subtitleCard}>
            {context?.dataContext.certificate_subtitle}
          </h2>
          <CustomButton
            className={styles.btn}
            onClick={onClick}
            variant={ButtonVariant.Primary}
          >
            <span className={styles.btnText}>
              {context?.dataContext.certificate_button_text}
            </span>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBlock;
