import {FC} from 'react';

import classNames from 'classnames';

import styles from './breadcrumbs.module.scss';

type TBreadcrumbItem = {
  label: string,
  href: string,
}

type TBreadcrumbsProps = {
  crumbs: Array<TBreadcrumbItem>
}

type TBreadcrumbs = FC<TBreadcrumbsProps>

const Breadcrumbs: TBreadcrumbs = ({crumbs}) =>{
  return(
    <div className={styles.breadcrumbs}>
      <div className={styles.list}>
        {crumbs.map((item, index) => {
          const classes = classNames(
            styles.listItem,
            index === crumbs.length - 1 && styles.active,
          );
          return(
            <a href={item.href} className={classes} key={index}>
              {item.label}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
