import React, {createContext, useState} from 'react';

import {TBlogTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TBlogTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TBlogTextData>>;
}

export const BlogContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TBlogTextData = {
  blog_data: {
    blog_title: '',
    blog_subtitle: '',
  },
  categories: [{name: '', slug: ''}],
};

export const BlogProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TBlogTextData>(initialState);

  return (
    <BlogContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </BlogContext.Provider>
  );
};
