import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import {TFooterData, TPaymentTextData} from '../../utils/types/pagesTypes';

import {API_URLS} from '../../api/routes';

import useApi from '../../api/useApi';

import {PaymentContext} from '../../context/PaymentContext';

import {Preloader} from '../../components/preloader/Preloader';

import {FooterContext} from '../../context/FooterContext';

import styles from './PaymentOrchestrationTools.module.scss';
import WelcomeBlock from './components/WelcomBlock/WelcomBlock';
import StepsBlock from './components/StepsBlock/StepsBlock';
import VisaToken from './components/VisaToken/VisaToken';
import PaymentCards from './components/PaymentCards/PaymentCards';
import TabsBlock from './components/TabsBlock/TabsBlock';
import ImgTextBlock from './components/ImgTextBlock/ImgTextBlock';

type TPaymentData = {
  'payment_fields': TPaymentTextData
}

export const PaymentOrchestrationTools: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TPaymentData>(API_URLS.PAYMENT, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.PAYMENT}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(PaymentContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['payment_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  const smartRoutingData = {
    title: context?.dataContext.smart_routing_title as string,
    descr: context?.dataContext.smart_routing_description as string,
    steps: context?.dataContext.smart_routing_list.map((item) =>
      item.smart_routing_item) as Array<string>,
  };

  const payoutManagementSystem = {
    title: context?.dataContext.payout_management_title as string,
    descr: context?.dataContext.payout_management_description as string,
    steps: context?.dataContext.payout_management_list.map((item) =>
      item.payout_management_list_item) as Array<string>,
  };

  return(
    <div className={styles.container}>
      <HeaderBlock />
      <WelcomeBlock />
      <StepsBlock {...smartRoutingData}/>
      <TabsBlock />
      <VisaToken />
      <StepsBlock {...payoutManagementSystem}/>
      <ImgTextBlock />
      <PaymentCards />
    </div>
  );
};

export default PaymentOrchestrationTools;
