import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {useEffect, useState} from 'react';

import {api} from './config';

const useApi = <T>(url: string, options?: AxiosRequestConfig) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const storedLanguage = localStorage.getItem('language');
  const optionsWithLang = {
    ...options,
    params: {
      ...(options?.params),
      lang: storedLanguage || 'en',
    },
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response: AxiosResponse = await api(url, optionsWithLang);
      setData(response.data);
    } catch(err: any) {
      setError(err);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {data, isLoading, error};
};

export default useApi;
