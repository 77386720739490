import {FC} from 'react';

import {TitlePageParagraphs} from '../titlePageParagraphs/TitlePageParagraphs';

import {TParagraphProps} from '../../config/config';

import styles from './hostedPaymentPage.module.scss';

export const HostedPaymentPage: FC<TParagraphProps> = ({paragraphs}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TitlePageParagraphs paragraphs={paragraphs} id={1} />
      </div>
    </div>
  );
};
