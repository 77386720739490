import React, {useContext} from 'react';

import payCard from '../../../../assets/images/payCardOYM.png';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import styles from './welcomeBlockOYM.module.scss';

export const WelcomeBlockOYM = () => {
  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{context?.dataContext.welcome_title}</h1>
      <div className={styles.description}>
        {context?.dataContext.welcome_subtitle}
      </div>
      <div className={styles.bodyDescription}>
        <h2 className={styles.title2}>
          {context?.dataContext.welcome_title_2}
        </h2>
        <div className={styles.description2}>
          {context?.dataContext.welcome_subtitle_2}
        </div>
      </div>
      <img alt="pay_card" src={payCard} className={styles.payCardImg} />
    </div>
  );
};
