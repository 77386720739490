import {FC, memo} from 'react';
import cn from 'classnames';

import bigOne from '../../../../assets/images/bigOne.png';
import bigTwo from '../../../../assets/images/bigTwo.png';
import bigThree from '../../../../assets/images/bigThree.png';
import bigFour from '../../../../assets/images/bigFour.png';
import bigFive from '../../../../assets/images/bigFive.png';
import bigSix from '../../../../assets/images/bigSix.png';

import styles from './sliderItem.module.scss';

export type TProps = {
  carousel_title: string;
  carousel_image: string;
  carousel_text: {
    carousel_text_item: string;
    need_list_in_text?: boolean;
    list_in_text_item?: string | {list_item: string}[];
  }[];
  index: number;
}

const NUMBERS_IMAGES = [
  bigOne,
  bigTwo,
  bigThree,
  bigFour,
  bigFive,
  bigSix,
];

const SliderItem: FC<TProps> = ({
  carousel_title,
  carousel_image,
  carousel_text,
  index,
}) => {
  return(
    <div className={styles.sliderItem}>
      <div className={styles.imgWrap}>
        <img src={carousel_image} alt="" />
      </div>
      <div className={styles.infoWrap}>
        <div className={styles.numbersWrap}>
          <div className={styles.numberImg}>
            { index - 1 >= 0
              && <img src={NUMBERS_IMAGES[index - 1]}
                className={cn(styles.img, styles.shadow)} alt="" />}
          </div>
          <div className={styles.numberImg}>
            <img src={NUMBERS_IMAGES[index]} className={styles.img} alt="" />
          </div>
          <div className={styles.numberImg}>
            { index + 1 < 6
              && <img src={NUMBERS_IMAGES[index + 1]}
                className={cn(styles.img, styles.shadow)} alt="" />}
          </div>
        </div>
        <div className={styles.contentWrap}>
          <h3 className={styles.title}>{carousel_title}</h3>
          {carousel_text.map(((text, textId) => (<div className={styles.textWrap} key={textId}>
            <p className={styles.descr}>
              {text.carousel_text_item}
            </p>
            {(text.need_list_in_text && Array.isArray(text.list_in_text_item)) && (
              <ul className={styles.list}>
                {
                  text.list_in_text_item.map((item, id) => {
                    return(
                      <li className={styles.listItem} key={id}>
                        {item.list_item}
                      </li>
                    );
                  })
                }
              </ul>
            )}
          </div>
          )))}
        </div>
      </div>
    </div>
  );
};

export default memo(SliderItem);
