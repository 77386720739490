import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import img from '../../assets/images/test.png';

import useApi from '../../api/useApi';

import {TFooterData, TIntegrationsTextData} from '../../utils/types/pagesTypes';

import {API_URLS} from '../../api/routes';

import {IntegrationsContext} from '../../context/IntegrationsContext';

import {Preloader} from '../../components/preloader/Preloader';

import {FooterContext} from '../../context/FooterContext';

import {WelcomeBlockIntegrations}
  from './components/welcomeBlockIntegrations/WelcomeBlockIntegrations';
import {ConnectorsWithBanks} from './components/connectorsWithBanks/ConnectorsWithBanks';
import {AcceptGlobalPayments} from './components/acceptGlobalPayments/AcceptGlobalPayments';
import {OfferMerchants} from './components/offerMerchants/OfferMerchants';


import styles from './integrations.module.scss';

type TIntegrationsData = {
  'integrations_fields': TIntegrationsTextData;
}

export const Integrations: React.FunctionComponent = () => {

  const {data, isLoading, error} =
    useApi<TIntegrationsData>(API_URLS.INTEGRATIONS, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.INTEGRATIONS}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(IntegrationsContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['integrations_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }
  return (
    <div className={styles.container}>
      <HeaderBlock src={img} />
      <WelcomeBlockIntegrations />
      <ConnectorsWithBanks />
      <AcceptGlobalPayments />
      <OfferMerchants />
    </div>
  );
};

export default Integrations;
