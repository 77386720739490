import React from 'react';

import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './optionsBlock.module.scss';

type Props = {
  options: {
    title: string,
    subTitle: string,
    highlightSubTitle: string,
    text: string,
    highlightText: string,
  }[];
  img: string;
};

export const OptionsBlock: React.FunctionComponent<Props> = ({options, img}) => {
  return (
    <div className={styles.optionsBlock}>
      <div className={styles.optionContent}>
        {options.map((item, id) => {
          return (
            <div className={styles.cardOption} key={id}>
              <h3 className={styles.optionTitle}>{item.title}</h3>
              <div className={styles.cardTextOption}>
                <div className={styles.optionSubTitleBlock}>
                  <HighlightText
                    text={item.subTitle}
                    highlight={item.highlightSubTitle}
                    className={styles.optionSubTitle}
                    highlightClass={styles.boldText}
                  />
                </div>
                <div className={styles.optionTextBlock}>
                  <HighlightText
                    text={item.text}
                    highlight={item.highlightText}
                    className={styles.optionText}
                    highlightClass={styles.boldText}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <img className={styles.imagePciDss} src={img} alt="pci dss" />
    </div>
  );
};

export default OptionsBlock;
