import React, {useContext} from 'react';

import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {ForDevelopersContext} from '../../../context/DevelopersContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './mainPoints.module.scss';

export const MainPoints: React.FunctionComponent = () => {
  const context = useContext(ForDevelopersContext);

  const onClick = (link: string | undefined) => {
    if (isValidURL(link)) {
      window.open(link, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.points}>
        <div className={styles.firstPoint}>
          <h3 className={styles.title}>
            {context?.dataContext.merchant_api_block_title}
          </h3>
          <p className={styles.desc}>
            {context?.dataContext.merchant_api_block_description}
          </p>
          <CustomButton
            className={styles.btn}
            onClick={() => {
              onClick(context?.dataContext.merchant_api_block_button_link);
            }}
            variant={ButtonVariant.Primary}
          >
            {context?.dataContext.merchant_api_block_button_text}
          </CustomButton>
        </div>
        <div className={styles.secondPoint}>
          <h3 className={styles.title}>
            {context?.dataContext.mobile_sdk_block_title}
          </h3>
          <p className={styles.desc}>
            {context?.dataContext.mobile_sdk_block_description}
          </p>
          <CustomButton
            className={styles.btn}
            onClick={() => {
              onClick(context?.dataContext.mobile_sdk_block_button_link);
            }}
            variant={ButtonVariant.Primary}
          >
            {context?.dataContext.mobile_sdk_block_button_text}
          </CustomButton>
        </div>
        <div className={styles.thirdPoint}>
          <h3 className={styles.title}>
            {context?.dataContext.e_commerce_plugins_block_title}
          </h3>
          <p className={styles.desc}>
            {context?.dataContext.e_commerce_plugins_block_description}
          </p>
          <CustomButton
            className={styles.btn}
            onClick={() => {
              onClick(context?.dataContext.e_commerce_plugins_block_button_link);
            }}
            variant={ButtonVariant.Primary}
          >
            {context?.dataContext.e_commerce_plugins_block_button_text}
          </CustomButton>
        </div>
      </div>
      <div className={styles.background}/>
    </div>
  );
};

export default MainPoints;
