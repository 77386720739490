import React, {useContext} from 'react';

import {ReactComponent as Lithuania} from '../../../assets/icons/lithuania.svg';

import {ContactsContext} from '../../../context/ContactsContext';
import {splitStringByBr} from '../../../utils/hooks/splitString';

import styles from './officeBlock.module.scss';

export const OfficeBlock: React.FunctionComponent = () => {
  const context = useContext(ContactsContext);

  return (
    <div className={styles.officeBlock}>
      <div className={styles.textContainer}>
        <h2 className={styles.title}>{context?.dataContext.visit_our_office_title}</h2>
        <p className={styles.subtitle}>{context?.dataContext.visit_our_office_subtitle}</p>
        <p className={styles.text}>
          {splitStringByBr(context?.dataContext.visit_our_office_info || '').map((part, id) =>
            <span key={id}>
              {part}
              <br/>
            </span>)
          }
        </p>
      </div>
      <Lithuania className={styles.lithuaniaImg} />
    </div>
  );
};

export default OfficeBlock;
