import {useContext} from 'react';

import {PaymentContext} from '../../../../context/PaymentContext';

import styles from './PaymentCards.module.scss';

const PaymentCards: React.FunctionComponent = () => {

  const context = useContext(PaymentContext);

  return (
    <div className={styles.block}>
      <div className={styles.container}>
        <div className={styles.paymentWrap}>
          <div className={styles.infoWrap}>
            <h2 className={styles.title}>{context?.dataContext.payment_split_title}</h2>
            <div className={styles.descr}>{context?.dataContext.payment_split_description}</div>
          </div>
          <div className={styles.cardWrap}>
            <div className={styles.firstCard}>
              <div className={styles.cardTitle}>
                {context?.dataContext.payment_split_block_title}
              </div>
              <div className={styles.cardDescr}>
                {context?.dataContext.payment_split_block_description}
              </div>
            </div>
            <div className={styles.secondCard}>
              <div className={styles.cardTitle}>
                {context?.dataContext.cascading_block_title}
              </div>
              <div className={styles.cardDescr}>
                {context?.dataContext.cascading_block_description}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentCards;
