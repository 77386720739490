import React, {useContext} from 'react';

import imageBlock from '../../../assets/images/benefitFromBlockImage.png';

import {HomeContext} from '../../../context/HomeContext';
import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './benefitFromBlock.module.scss';

const BenefitFromBlock: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  return (
    <div className={styles.benefitFromBlock}>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <h3 className={styles.title}>
            {context?.dataContext.benefit_block_title}
          </h3>
          <p className={styles.subTitle}>
            <HighlightText
              text={context?.dataContext.benefit_block_subtitle as string}
              highlight={context?.dataContext.benefit_block_subtitle_orange as string}
              className={styles.subTitle}
              highlightClass={styles.orange}
            />
          </p>
        </div>
        <div className={styles.professionalCards}>
          {context?.dataContext.benefit_block_list.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <h3 className={styles.number}>{item.benefit_block_item_number}</h3>
                <p className={styles.text}>{item.benefit_block_item_text}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.bevelBlock}></div>
      <img className={styles.image} src={imageBlock} alt="imageBlock" />
    </div>
  );
};

export default BenefitFromBlock;
