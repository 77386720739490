import React from 'react';

import style from './table.module.scss';

export type TableData = {
  host: string;
  cpu: string;
  ram: string;
  hdd: string;
};

type Header = {
  id: string;
  text: string;
};

type TableProps = {
  headers: Header[];
  rows: TableData[];
  className?: string;
};

const Table: React.FC<TableProps> = ({headers, rows, className}) => {

  const rowsArr = rows.map((row) => {
    return Object.values(row);
  });

  return (
    <table className={`${className} ${style.tableBlock}`}>
      <thead className={style.tableHead}>
        <tr>
          {headers.map((header) => (
            <th key={header.id}>
              <h3>{header.text}</h3>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowsArr.map((row, index) => (
          <tr key={index}>
            {row.map((item, rowIndex) => {
              return(
                <td key={rowIndex}>
                  <p>{item}</p>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
