import {useContext} from 'react';


import HighlightText from '../../../components/highlightText/HighlightText';
import {Slider} from '../../../components/slider/Slider';

import {ReportAndBalanceContext} from '../../../context/ReportAndBalanceContext';

import SliderItem from './components/SliderItem';
import {Carousel} from './components/Carousel';

import styles from './statisticalReports.module.scss';

export const StatisticalReports = () => {
  const context = useContext(ReportAndBalanceContext);

  return (
    <div className={styles.statisticalReports}>
      <div className={styles.desktopContainer}>
        <Carousel />
      </div>
      <div className={styles.container}>
        <div className={styles.text}>
          <h2 className={styles.title}>
            <HighlightText
              text={context?.dataContext.statistical_reports_title as string}
              highlight={context?.dataContext.statistical_reports_title_orange as string}
              className={styles.highlightText}
              highlightClass={styles.orange}
            />
          </h2>
          <p className={styles.description}>{context?.dataContext.statistical_reports_subtitle}</p>
        </div>
        <div className={styles.sliderWrap}>
          {context?.dataContext.statistical_reports_carousel &&
            <Slider classNameStepper={styles.pagination}>
              {context?.dataContext.statistical_reports_carousel
                .map((item, index) => (
                  <SliderItem key={index} index={index} {...item} />
                ))}
            </Slider>}
        </div>
      </div>
    </div>
  );
};
