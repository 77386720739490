import React, {useContext} from 'react';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import {SlideShow} from '../../../components/slideShow/SlideShow';

import styles from './merchantShopManagement.module.scss';

export const MerchantShopManagement: React.FunctionComponent = () => {
  const context = useContext(MerchantManagementContext);

  return (
    <div className={styles.merchantShopManagement}>
      <div className={styles.beveledBlock}/>
      <div className={styles.content}>
        <h3 className={styles.title}>{context?.dataContext.merchant_shop_title}</h3>
        <div className={styles.containerInformation}>
          <div className={styles.slider}>
            <SlideShow
              className={styles.sliderBlock}
              slidersLength={context?.dataContext.merchant_shop_image_carousel.length as number}>
              {context?.dataContext.merchant_shop_image_carousel.map((item, index) => {
                return (
                  <img
                    className={styles.images}
                    key={`merchant_shop_image_${index}`}
                    alt="slide"
                    src={item.merchant_shop_image}
                  />
                );
              })}
            </SlideShow>
          </div>
          <div className={styles.textContainer}>
            <div className={styles.listContent}>
              {context?.dataContext.merchant_shop_text_list.map((item, id) => (
                <div key={`merchant_shop_text_list_${id}`}>
                  <p className={styles.listText}>{item.merchant_shop_text_item}</p>
                  {(item.merchant_shop_text_need_list && item.merchant_shop_list_in_text) &&
                    <ol className={styles.list}>
                      {item.merchant_shop_list_in_text.map((point, index) => {
                        return <li key={`merchant_shop_list_in_text_${index}`}>
                          {point.merchant_shop_list_item_in_text}
                        </li>;
                      })}
                    </ol>}
                </div>))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantShopManagement;
