import React, {useContext, useEffect} from 'react';

import contactsImg from '../../assets/images/test.png';

import {API_URLS} from '../../api/routes';
import useApi from '../../api/useApi';
import {PricingOptionsContext} from '../../context/PricingOptionsContext';

import {TFooterData, TPricingOptionsTextData} from '../../utils/types/pagesTypes';

import {Preloader} from '../../components/preloader/Preloader';
import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import {FooterContext} from '../../context/FooterContext';

import PricingOptionsBlock from './pricingOptionsBlock/PricingOptionsBlock';
import NeedFollowingBlock from './needFollowingBlock/NeedFollowingBlock';
import MainStepsBlock from './mainStepsBlock/MainStepsBlock';

import styles from './pricingOptions.module.scss';

type TPricingOptionsData = {
  'pricing_group': TPricingOptionsTextData,
}


export const PricingOptions: React.FunctionComponent = () => {
  const {data, isLoading, error} =
      useApi<TPricingOptionsData>(API_URLS.PRICING_OPTIONS, {method: 'GET'});

  const footer =
      useApi<TFooterData>(`${API_URLS.PRICING_OPTIONS}${API_URLS.FOOTER}`, {method: 'GET'});
  const footerContext = useContext(FooterContext);

  const context = useContext(PricingOptionsContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['pricing_group']);
    }
  },[context, data]);


  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={contactsImg} />
      <PricingOptionsBlock />
      <NeedFollowingBlock />
      <MainStepsBlock />
    </div>
  );
};

export default PricingOptions;
