import {FC, useCallback, useContext} from 'react';

import picture from '../../../../assets/images/bigSix.png';

import {CustomButton} from '../../../../components/customButton/CustomButton';

import {ButtonVariant} from '../../../../utils/constants/buttonVariantEnum';

import HighlightText from '../../../../components/highlightText/HighlightText';

import {AboutUsContext} from '../../../../context/AboutUsContext';

import {isValidURL} from '../../../../utils/hooks/isValidUrl';

import styles from './whatAreTheQuestions.module.scss';
import {QuestionsWATQ} from './components/questionsWATQ/QuestionsWATQ';

export const WhatAreTheQuestions: FC = () => {
  const context = useContext(AboutUsContext);

  const handleClick = useCallback(() => {
    if (isValidURL(context?.dataContext.have_any_question_button_link)) {
      window.open(context?.dataContext.have_any_question_button_link, '_blank');
    }
  }, [context?.dataContext.have_any_question_button_link]);


  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <img className={styles.img} alt="bigSix" src={picture} />
        <h1 className={styles.text}>
          <HighlightText
            text={context?.dataContext.questions_title as string}
            highlight={context?.dataContext.questions_title_bold as string}
            className={styles.text}
            highlightClass={styles.boldText}
          />
        </h1>
      </div>
      <QuestionsWATQ />
      <div className={styles.block}>
        <div className={styles.contentBlock}>
          <div className={styles.textWrapper}>
            <p>{context?.dataContext.have_any_question_title}</p>
            <p>{context?.dataContext.have_any_question_subtitle}</p>
          </div>
          <CustomButton
            className={styles.btn}
            onClick={handleClick}
            variant={ButtonVariant.Primary}
          >
            {context?.dataContext.have_any_question_button_text}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
