import {FC} from 'react';

import styles from './sliderItem.module.scss';

type Props = {
  image: string,
  description: string,
  title: string,
};

export const SliderItem: FC<Props> = ({
  image,
  description,
  title,
}) => {
  return (
    <div className={styles.container}>
      <img src={image} alt="slider_image"/>
      <div className={styles.title}>{title}</div>
      <div className={styles.desc}>{description}</div>
    </div>
  );
};
