import {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';

import {SwiperSlide, Swiper} from 'swiper/react';

import classNames from 'classnames';

import {Autoplay} from 'swiper/modules';

import bigThree from '../../../../assets/images/bigThree.png';
import {ReactComponent as Card1} from '../../../../assets/icons/aboutUsCards/card__1.svg';
import {ReactComponent as Card2} from '../../../../assets/icons/aboutUsCards/card__2.svg';
import {ReactComponent as Card3} from '../../../../assets/icons/aboutUsCards/card__3.svg';
import {ReactComponent as Card4} from '../../../../assets/icons/aboutUsCards/card__4.svg';

import {AboutUsContext} from '../../../../context/AboutUsContext';

import HighlightText from '../../../../components/highlightText/HighlightText';

import {WhoIsInOurTeamSliderItem}
  from './components/whoIsInOurTeamSliderItem/WhoIsInOurTeamSliderItem';
import styles from './whoIsInOurTeam.module.scss';

const SLIDE_DELAY = 5000;

export const WhoIsInOurTeam: FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = useCallback((swiper: any) => {
    setActiveSlide(swiper.realIndex);
  }, []);

  const context = useContext(AboutUsContext);

  const deskSliderRef = useRef<HTMLDivElement>(null);
  const deskSliderWrapRef = useRef<HTMLDivElement>(null);
  const slidesLength = context?.dataContext.our_team_cards?.length as number;

  const autoPlayTimer = useRef<NodeJS.Timeout | null>(null);


  const resetAutoPlayTimer = useCallback(() => {
    if (window.innerWidth < 1440) return;
    if (autoPlayTimer) {
      clearInterval(autoPlayTimer.current as NodeJS.Timeout);
    }
    autoPlayTimer.current = setInterval(() => {
      setActiveSlide((prevIndex) => {
        const newValue = prevIndex + 1;

        if (prevIndex === slidesLength - 1) {
          return 0;
        }

        return newValue;
      });
    }, SLIDE_DELAY);
  }, [slidesLength]);

  useEffect(() => {
    if(window.innerWidth < 1440) return;
    resetAutoPlayTimer();
    return () => {
      if (autoPlayTimer) {
        clearInterval(autoPlayTimer.current as NodeJS.Timeout);
      }
    };
  }, [resetAutoPlayTimer]);

  const handleSlideClick = useCallback(
    (swiper: any) => {
      swiper.slideToLoop(swiper.clickedIndex);
      setActiveSlide(swiper.realIndex);
      if (window.innerWidth < 1440) return;
      resetAutoPlayTimer();
    },
    [resetAutoPlayTimer],
  );

  const cardsSVG = [Card1, Card2, Card3, Card4];

  const calculateTranslate = () => {
    const sliderTrackWidth = deskSliderRef.current?.getBoundingClientRect()
      .width as number;

    const slideWidth = 406;
    const trackWidth = sliderTrackWidth;
    let centerOffset = (window.innerWidth - slideWidth) / 4.5;
    let activeSlideOffset = centerOffset - slideWidth * activeSlide;

    if (activeSlide === 0) {
      activeSlideOffset = 0;
      centerOffset = 0;
    } else if (activeSlide === slidesLength - 1) {
      centerOffset = 0;
      activeSlideOffset = -(trackWidth - window.innerWidth);
    } else if (activeSlide === slidesLength - 2) {
      centerOffset = 0;
      activeSlideOffset = -(trackWidth - window.innerWidth);
    } else if (activeSlide === 1) {
      activeSlideOffset = 0;
      centerOffset = 0;
    }

    const translateX = `calc(${centerOffset}px + ${activeSlideOffset}px)`;

    return translateX;
  };

  return (
    <div className={styles.container}>
      <div className={styles.block} />
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <img alt="" className={styles.img} src={bigThree} />
          <h1 className={styles.title}>
            <HighlightText
              text={context?.dataContext.our_team_title as string}
              highlight={context?.dataContext.our_team_title_bold as string}
              className={styles.title}
              highlightClass={styles.boldText}
            />
          </h1>
        </div>

        <div className={styles.deskWrap} ref={deskSliderWrapRef}>
          <div
            className={styles.deskWrapSlider}
            ref={deskSliderRef}
            style={{
              width:
                slidesLength &&
                slidesLength * 406 + (slidesLength - 1) * 20 + 294,
              transform: `translateX(${calculateTranslate()})`,
            }}
          >
            {context?.dataContext.our_team_cards?.map((item, index) => {
              const isActiveSlide = activeSlide === index;
              const handleDeskClick = () => {
                setActiveSlide(index);
                resetAutoPlayTimer();
              };

              return (
                <div
                  key={item.our_team_card_title}
                  className={classNames(
                    styles.deskCard,
                    isActiveSlide && styles.activeDeskCard,
                  )}
                  onClick={handleDeskClick}
                >
                  <WhoIsInOurTeamSliderItem
                    values={item}
                    activeValue={isActiveSlide}
                    cardHolderIndex={index % cardsSVG.length}
                    classes={isActiveSlide ? styles.activeDeskCard : ''}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Swiper
          className={styles.swiper}
          modules={[Autoplay]}
          speed={300}
          autoplay={{delay: SLIDE_DELAY}}
          slidesPerView={'auto'}
          slidesPerGroup={1}
          spaceBetween={20}
          onBeforeTransitionStart={handleSlideChange}
          onClick={handleSlideClick}
          allowTouchMove={false}
          centerInsufficientSlides={true}
          centeredSlides={true}
        >
          {context?.dataContext.our_team_cards?.map((item, index) => {
            const isActiveSlide = activeSlide === index;
            return (
              <SwiperSlide
                key={index}
                className={classNames(
                  styles.swiperSlide,
                  activeSlide === index ? styles.activeSlide : '',
                )}
              >
                <WhoIsInOurTeamSliderItem
                  key={item.our_team_card_title}
                  values={item}
                  activeValue={isActiveSlide}
                  cardHolderIndex={index % cardsSVG.length}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
