import {FC, useCallback, useContext, useState} from 'react';

import cn from 'classnames';

import {ReactComponent as ArrowDown} from '../../../../../../assets/icons/arrowDown.svg';
import {DescriptionsQuestionsWATQ} from '../descriptionsQuestionsWATQ/DescriptionsQuestionsWATQ';

import {AboutUsContext} from '../../../../../../context/AboutUsContext';

import styles from './questionsWATQ.module.scss';


export const QuestionsWATQ: FC = () => {
  const [activeId, setActiveId] = useState<number>(-1);

  const context = useContext(AboutUsContext);

  const onClick = useCallback(
    (id: number) => {
      if (activeId === id) {
        setActiveId(-1);
        return;
      }
      setActiveId(id);
    },
    [activeId],
  );
  return (
    <div className={styles.container}>
      {context?.dataContext.questions_list.map((question, index) => (
        <div
          className={styles.questionAndDescriptionsWrapper}
          key={index}
        >
          <div
            className={styles.questionWrapper}
            onClick={() => onClick(index)}
          >
            <h3>{index + 1}.</h3>
            <h3>{question.question_list_title}</h3>
            <div
              className={cn(styles.svgWrapper, {
                [styles.svgWrapper_active]: activeId === index,
              })}
            >
              <ArrowDown />
            </div>
          </div>
          {activeId === index && (
            <DescriptionsQuestionsWATQ question={question} />
          )}
        </div>
      ))}
    </div>
  );
};
