import React, {FC} from 'react';

import {ISliderCPWItem} from '../../../../config/config';

import styles from './sliderItemListVariant.module.scss';

export const SliderItemListVariant: FC<ISliderCPWItem> = ({
  item_text,
  item_image,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.paragraph}>
        <div className={styles.annotations}>
          {item_text.map(({item_title, item_description, item_list}) => (
            <div key={item_title}>
              <div className={styles.titleWrapper}>
                <div className={styles.point} />
                <div className={styles.title}>{item_title}</div>
              </div>
              <div className={styles.descriptionDesktop}>{item_description}</div>
              {item_list && (
                <div className={styles.listDesktop}>
                  {item_list.map((el, item_list) => (
                    <div key={item_list} className={styles.elementWrapper}>
                      <div className={styles.slash} />
                      <div className={styles.text}>{el.list_item}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.imgWrapper}>
        <img className={styles.img} src={item_image} alt={item_text[0].item_title} />
      </div>
      {item_text.map(({item_title, item_description, item_list}) => (
        <div key={item_title}>
          <div className={styles.description}>{item_description}</div>
          {item_list && (
            <div className={styles.list}>
              {item_list.map((el, item_list) => (
                <div key={item_list} className={styles.elementWrapper}>
                  <div className={styles.slash} />
                  <div className={styles.text}>{el.list_item}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
