import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import {Preloader} from '../../components/preloader/Preloader';
import useApi from '../../api/useApi';
import {API_URLS} from '../../api/routes';
import {ContactsContext} from '../../context/ContactsContext';
import {TContactsTextData} from '../../utils/types/pagesTypes';

import contactsImg from '../../assets/images/2_project.png';

import OfficeBlock from './officeBlock/OfficeBlock';
import CurrentClientsBlock from './currentClientsBlock/CurrentClientsBlock';
import NewClientsBlock from './newClientsBlock/NewClientsBlock';

import styles from './contacts.module.scss';

type TContactsData = {
  'contacts_fields': TContactsTextData,
}

export const Contacts: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TContactsData>(API_URLS.CONTACTS, {method: 'GET'});

  const context = useContext(ContactsContext);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['contacts_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={contactsImg} />
      <NewClientsBlock />
      <CurrentClientsBlock />
      <OfficeBlock />
    </div>
  );
};

export default Contacts;
