import React, {createContext, useState} from 'react';

import {TCaseSingleTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TCaseSingleTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TCaseSingleTextData>>;
}

export const CaseSingleContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TCaseSingleTextData = {
  breadcrumbs: [{label: '', href: ''}],
  case_title: '',
  case_description: '',
  case_main_image: '',
  chalenge_title: '',
  chalenge_text_list: [{
    chalenge_text: '',
  }],
  solution_title: '',
  solution_text_list: [{
    solution_text: '',
    solution_need_list_in_text: true,
    solution_list_in_text: [{
      list_item:  '',
    }],
  }],

  renting_title: '',
  renting_subtitle: '',
  renting_table_middle_column_title: '',
  renting_table_last_column_title: '',
  renting_table: [{
    renting_table_first_column_text: '',
    renting_table_middle_column_text: '',
    renting_table_second_column_need_link: true,
    renting_table_second_column_link_text: '',
    renting_table_second_column_link: '',
    renting_table_third_column_text: '',
    renting_table_third_column_need_link: true,
    renting_table_third_column_link_text: '',
    renting_table_third_column_link: '',
  }],

  purchase_title: '',
  purchase_text_list:  [{
    purchase_text_item: '',
    purchase_need_link: true,
    purchase_link: '',
    purchase_orange_text: '',
  }],
  key_functionality_title: '',
  key_functionality_list: [{
    functionality_item: '',
  }],
  offer_the_folowing_title: '',
  offer_the_following_list: [{
    offer_list_item: '',
    need_sublist: true,
    offer_sublist: [{
      offer_sublist_item: '',
    }],
  }],
  statistics_title: '',
  statistics_image: '',
  statistics_text_list: [{
    statistics_text_list_item: '',
    need_bold:  true,
    statistics_bold_text_list: [{
      bold_item: '',
    }],
  }],
  cards: [{
    card_ico: '',
    card_title: '',
    card_subtitle: '',
    card_button_text: '',
    card_link: '',
  }],
};

export const CaseSingleProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TCaseSingleTextData>(initialState);

  return (
    <CaseSingleContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </CaseSingleContext.Provider>
  );
};
