import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

import classNames from 'classnames';

import React from 'react';

import {TTable} from '../TableBlock';

import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './tableMob.module.scss';

const TableMob: TTable = ({
  renting_table_middle_column_title,
  renting_table_last_column_title,
  renting_table,
}) => {
  return(
    <div className={styles.tableMob}>
      <Swiper
        slidesPerView={'auto'}
        className={styles.swiper}
      >
        <SwiperSlide>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <div className={styles.cell}>
                    {renting_table_middle_column_title}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renting_table?.map((row, index) => {
                return(
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <div className={styles.cell}>
                          {row.renting_table_first_column_text}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={classNames(styles.cell, styles.value)}>
                          {row.renting_table_second_column_need_link
                            ?
                            <HighlightText
                              text={row.renting_table_middle_column_text}
                              highlight={row.renting_table_second_column_link_text}
                              className={''}
                              highlightClass={''}
                              href={row.renting_table_second_column_link}
                            />
                            :
                            <>
                              {row.renting_table_middle_column_text}
                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}

            </tbody>
          </table>
        </SwiperSlide>
        <SwiperSlide>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  <div className={styles.cell}>
                    {renting_table_last_column_title}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renting_table?.map((row, index) => {
                return(
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        <div className={styles.cell}>
                          {row.renting_table_first_column_text}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={classNames(styles.cell, styles.value)}>
                          {row.renting_table_third_column_need_link
                            ?
                            <HighlightText
                              text={row.renting_table_third_column_text}
                              highlight={row.renting_table_third_column_link_text}
                              className={''}
                              highlightClass={''}
                              href={row.renting_table_third_column_link}
                            />
                            :
                            <>
                              {row.renting_table_third_column_text}
                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default TableMob;
