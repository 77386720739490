import React, {useContext} from 'react';
import 'swiper/css';

import {Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import {HomeContext} from '../../../context/HomeContext';

import styles from './runningLine.module.scss';

const RunningLine: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{context?.dataContext.secured_main_title}</h3>
      <div className={styles.container}>
        <div className={styles.lineWrapper}>
          <div className={styles.deskLine}>
            <span className={styles.lineItem}>
              {context?.dataContext.secured_slides.map((item, index) => (
                <span key={index} className={styles.item}>
                  <p className={styles.lineText}>{item.slide_text}</p>
                  <img
                    src={item.slide_image}
                    alt={item.slide_text}
                    className={styles.lineImage}
                  />
                </span>
              ))}
            </span>
          </div>
          <div className={styles.line}>

            <Swiper
              modules={[Autoplay]}
              speed={5000}
              autoplay={{delay: 0}}
              slidesPerView={2}
              loop
              className={styles.swiper}
              spaceBetween={30}

              breakpoints={{

                500: {
                  slidesPerView: 3,
                },
              }}
            >
              {context?.dataContext.secured_slides.map((item, index) => (
                <SwiperSlide key={index}>
                  <span className={styles.item}>
                    <p className={styles.lineText}>{item.slide_text}</p>
                    <img
                      src={item.slide_image}
                      alt={item.slide_text}
                      className={styles.lineImage}
                    />
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunningLine;
