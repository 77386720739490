import React, {useContext} from 'react';

import {CustomButton} from '../../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../../utils/constants/buttonVariantEnum';

import connectors from '../../../../assets/images/connectorsWithBanks.png';

import {IntegrationsContext} from '../../../../context/IntegrationsContext';
import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './connectorsWithBanks.module.scss';

export const ConnectorsWithBanks = () => {

  const context = useContext(IntegrationsContext);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <HighlightText
          text={context?.dataContext.launch_faster_title as string}
          highlight={context?.dataContext.launch_faster_title_orange as string}
          className={styles.titleText}
          highlightClass={styles.orange}
        />
      </h1>

      <div className={styles.contentWrapper}>
        <div className={styles.connectorsImgWrapper}>
          <img alt="" src={connectors} className={styles.connectorsImg} />
          <div className={styles.shadow} />
        </div>
        <div className={styles.textsWrapper}>
          <div className={styles.paragraphs}>
            {context?.dataContext.launch_faster_ol.map((paragraph, index) => (
              <div key={index} className={styles.paragraph}>
                <h1 className={styles.step}>{index + 1}</h1>
                <div className={styles.text}>{paragraph.launch_faster_li}</div>
              </div>
            ))}
          </div>

          <CustomButton
            className={styles.btn}
            variant={ButtonVariant.Redirect}
            onClick={() => ({})}
          >
            Request a list of existing connectors
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
