import {useContext, useEffect, useState} from 'react';

import {useNavigate, useParams} from 'react-router-dom';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import picture from '../../assets/images/sitemapPicture.png';

import useApi from '../../api/useApi';
import {TCaseSingleTextData} from '../../utils/types/pagesTypes';

import {CaseSingleContext} from '../../context/CaseSingleContext';

import {Preloader} from '../../components/preloader/Preloader';


import styles from './caseSingle.module.scss';
import ChallengeSolutionBlock from './ChallengeSolutionBlock/ChallengeSolutionBlock';
import TextColumnsBlock from './TextColumnsBlock/TextColumnsBlock';
import ListMarkedBlock from './ListMarkedBlock/ListMarkedBlock';
import NumericListBlock from './NumericListBlock/NumericListBlock';
import ImgTextBlock from './ImgTextBlock/ImgTextBlock';
import CardsBlock from './CardsBlock/CardsBlock';
import TableBlock from './TableBlock/TableBlock';
import HeroBlock from './HeroBlock/HeroBlock';

type TCaseSingleData = {
  'case_fields': TCaseSingleTextData
}

const CaseSingle: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [isRedirect, setIsRedirect] = useState(false);
  const {caseName} = useParams<{caseName: string}>();

  const {data, isLoading, error} =
    useApi<TCaseSingleData>(`/cases/${caseName}`, {method: 'GET'});

  const context = useContext(CaseSingleContext);

  useEffect(() => {
    if (context && data) {
      if (data['case_fields']) {
        context.setDataContext(data['case_fields']);
      }
      else {
        setIsRedirect(true);
      }
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  if (isRedirect) {
    navigate('/error', {replace: true});
  }

  return(
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <HeroBlock />
      <ChallengeSolutionBlock />
      <TableBlock />
      <TextColumnsBlock />
      <ListMarkedBlock />
      <NumericListBlock />
      <ImgTextBlock />
      <CardsBlock />

    </div>
  );
};

export default CaseSingle;
