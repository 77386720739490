import React, {useContext} from 'react';

import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {HomeContext} from '../../../context/HomeContext';
import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './brandsBlock.module.scss';

export const BrandsBlock: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const onClickContacts = () => {
    if (isValidURL(context?.dataContext.sales_button_link)) {
      window.open(context?.dataContext.sales_button_link, '_blank');
    }
  };

  const cardsItems = context?.dataContext.get_a_complete_payment_items;

  return (
    <div className={styles.brandsBlock}>
      <div className={styles.brandsTextContainer}>
        <h3 className={styles.title}>
          {context?.dataContext.get_a_complete_payment_title}
        </h3>
        <p className={styles.subTitle}>
          {context?.dataContext.get_a_complete_payment_subtitle}
        </p>
      </div>
      <div className={styles.cardsContainer_block}>
        <div className={styles.cardsContainer}>
          {cardsItems?.map((item, index) => {
            const handelClick = () => {
              if (isValidURL(item.get_a_complete_payment_item_link)) {
                window.open(item.get_a_complete_payment_item_link, '_blank');
              }
            };

            return (
              <div onClick={handelClick} className={styles.card} key={index}>
                <div className={styles.cardTitle}>
                  <span>{item.get_a_complete_payment_item_title}</span>
                </div>
                <ol className={styles.cardList}>
                  {item.get_a_complete_payment_item_list.map((el, id) => {
                    return (
                      <li className={styles.cardListItem} key={id}>
                        {el.get_a_complete_payment_item_text}
                      </li>
                    );
                  })}
                </ol>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <CustomButton
          className={styles.btn}
          onClick={onClickContacts}
          variant={ButtonVariant.Primary}
        >
          {context?.dataContext.sales_button_text}
        </CustomButton>
      </div>
    </div>
  );
};

export default BrandsBlock;
