import {FC, useContext} from 'react';
import cn from 'classnames';

import bigEight from '../../../../../assets/images/bigEight.png';

import {AboutUsContext} from '../../../../../context/AboutUsContext';
import HighlightText from '../../../../../components/highlightText/HighlightText';

import styles from './whereToFindUs.module.scss';

export const WhereToFindUs: FC = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      <div className={styles.contactsWrapper}>
        <div
          className={cn(styles.contactWrapper, styles.contactWrapper__address)}
        >
          <div className={styles.contactWrapper_title}>
            {context?.dataContext.where_to_find_address_title}
          </div>
          <div className={styles.contactWrapper_info}>
            {context?.dataContext.where_to_find_address_list.map((item, index) => {
              return(
                <HighlightText
                  text={item.where_to_find_address_list_item as string}
                  highlight={item.where_to_find_address_list_item_bold as string}
                  highlightClass={styles.contactWrapper_info_global}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        <div className={cn(styles.contactWrapper, styles.contactWrapper__web)}>
          <div className={styles.contactWrapper_title}>
            {context?.dataContext.where_to_find_web_title}
          </div>
          <div className={styles.contactWrapper_info}>
            {context?.dataContext.where_to_find_web_list.map((item, index) => {
              return(
                <a href={item.where_to_find_web_list_link} key={index}>
                  {item.where_to_find_web_list_item}
                </a>
              );
            })}
          </div>
        </div>
        <div
          className={cn(styles.contactWrapper, styles.contactWrapper__phone)}
        >
          <div className={styles.contactWrapper_title}>
            {context?.dataContext.where_to_find_phone_title}
          </div>
          <div className={styles.contactWrapper_info}>
            {context?.dataContext.where_to_find_phone_list.map((item, index) => {
              return(
                <a href={item.where_to_find_phone_list_item} key={index}>
                  {item.where_to_find_phone_list_item}
                </a>
              );
            })}

          </div>
        </div>
        <div
          className={cn(styles.contactWrapper, styles.contactWrapper__socials)}
        >
          <div className={styles.contactWrapper_title}>
            {context?.dataContext.where_to_find_email_title}
          </div>
          <div className={styles.contactWrapper_info}>
            {context?.dataContext.where_to_find_email_list.map((item, index) => {
              return(
                <a href={item.where_to_find_email_list_link} key={index}>
                  {item.where_to_find_email_list_item}
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.titleWrapper}>
        <img className={styles.titleWrapper__img} alt="bigEight" src={bigEight} />
        <h1 className={styles.titleWrapper__text}>
          <HighlightText
            text={context?.dataContext.where_to_find_title as string}
            highlight={context?.dataContext.where_to_find_title_bold as string}
            className={styles.titleWrapper__text}
            highlightClass={styles.boldText}
          />
        </h1>
      </div>
    </div>
  );
};
