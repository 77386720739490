export function isValidURL(url: string | undefined) {
  try {
    if (url) {
      new URL(url);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
