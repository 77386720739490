import React, {createContext, useState} from 'react';

import {TForDevelopersTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TForDevelopersTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TForDevelopersTextData>>;
}

export const ForDevelopersContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TForDevelopersTextData = {
  title: '',
  merchant_api_title: '',
  merchant_api_subtitle: '',
  merchant_api_image: '',
  merchant_api_block_title: '',
  merchant_api_block_description: '',
  merchant_api_block_button_text: '',
  merchant_api_block_button_link: '',
  mobile_sdk_block_title: '',
  mobile_sdk_block_description: '',
  mobile_sdk_block_button_text: '',
  mobile_sdk_block_button_link: '',
  e_commerce_plugins_block_title: '',
  e_commerce_plugins_block_description: '',
  e_commerce_plugins_block_button_text: '',
  e_commerce_plugins_block_button_link: '',
  psp_api_title: '',
  psp_api_subtitle: '',
  dwh_api_title: '',
  dwh_api_subtitle: '',
  dwh_api_button_text: '',
  dwh_api_button_link: '',
  system_requirements_title: '',
  system_requirements_subtitle: '',
  system_requirements_tab: [{
    host: '',
    cpu: '',
    ram: '',
    hdd: '',
  }],
  system_requirements_aftertab_text: '',
  system_requirements_aftertab_list: [{
    system_requirements_aftertab_list_item: '',
  }],
  any_questions_title: '',
  sales_block_image: false,
  sales_title: '',
  sales_block_description: '',
  sales_button_text: '',
  sales_button_link: '',
  tech_support_block_image: false,
  tech_support_block_title: '',
  tech_support_block_description: '',
  tech_support_button_text: '',
  tech_support_button_link: '',
  dev_block_image: false,
  dev_block_title: '',
  dev_block_description: '',
  dev_button_text: '',
  dev_button_link: '',
};

export const ForDevelopersProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TForDevelopersTextData>(initialState);

  return (
    <ForDevelopersContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </ForDevelopersContext.Provider>
  );
};
