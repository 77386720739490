import {useCallback, useContext} from 'react';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './numericListBlock.module.scss';

const NumericListBlock: React.FunctionComponent = () => {

  const context = useContext(CaseSingleContext);

  const chunkArray = (array: any, chunkSize: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  const renderListItem = useCallback((item: any, index: number) => {
    return(
      <li className={styles.listItem} key={item.id}>
        <div className={styles.indexLabel}>{item.id}</div>
        <div className={styles.textBlock}>

          <div className={styles.descr}>{item.offer_list_item}</div>
          {item.need_sublist && item.offer_sublist.length && (
            <ul className={styles.sublist}>
              {item.offer_sublist.map((subListtem: any, index: number) =>
                <li className={styles.sublistItem} key={index}>{subListtem.offer_sublist_item}</li>,
              )}
            </ul>
          )}
        </div>
		 </li>
    );
  }, []);
  const renderList = useCallback((list: any, index: number) => {
    return(
      <div className={styles.list} key={index}>
        {list.map(renderListItem)}
      </div>
    );
  }, []);

  const renderLists = useCallback((list: Array<any>) => {

    const newList = list.map((item, index) => {
      return {...item, id: index + 1};
    });

    const firstList = newList?.splice(0, 3);

    return(
      <>
        <div className={styles.list}>
          {firstList?.map(renderListItem)}
        </div>
        {chunkArray(newList, 6).map(renderList)}
      </>
    );
  }, [])
;
  return(
    <div className={styles.container}>
      <h2 className={styles.title}>{context?.dataContext.offer_the_folowing_title}</h2>
      <div className={styles.wrap}>
        {context?.dataContext.offer_the_following_list &&
		  renderLists(context?.dataContext.offer_the_following_list)}
      </div>
    </div>
  );
};

export default NumericListBlock;
