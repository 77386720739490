import React, {createContext, useState} from 'react';

import {TAboutUsTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TAboutUsTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TAboutUsTextData>>;
}

export const AboutUsContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TAboutUsTextData = {
  about_us_title: '',
  who_we_are_title: '',
  who_we_are_title_bold: 'Who',
  who_we_are_image: '',
  who_we_are_text_list: [{
    who_we_are_paragraph: '',
  }],
  what_is_our_history_title: '',
  what_is_our_history_title_bold: 'What',
  what_is_our_history_list: [{
    what_is_our_history_list_title: '',
    what_is_our_history_list_text: '',
    what_is_our_history_list_text_orange: '',
  }],
  our_team_title: '',
  our_team_title_bold: 'Who',
  our_team_cards: [{
    our_team_card_image: '',
    our_team_card_title: '',
    our_team_card_description: '',
    our_team_card_description_2: '',
  }],
  what_do_we_trust_title: '',
  what_do_we_trust_title_bold: 'That',
  what_do_we_trust_image: '',
  what_do_we_trust_text: [{
    what_do_we_trust_text_item: '',
  }],
  our_cases_title: '',
  our_cases_title_bold: 'What',
  our_cases_subtitle: '',
  our_cases_text_list: [{
    our_cases_text_list_item: '',
  }],
  our_cases_list: [{
    our_cases_list_title: '',
    our_cases_list_image: '',
    our_cases_list_link: '',
  }],
  questions_title: '',
  questions_title_bold: 'What',
  questions_list: [{
    question_list_title: '',
    question_list_text: [{
      question_list_text_item: '',
    }],
    need_list_articles: false,
    question_list_articles: [{
      question_list_articles_text: '',
      question_list_articles_link: '',
    }],
    need_link_in_bottom: false,
    question_list_paragraph: {
      question_list_paragraph_text: '',
      question_list_paragraph_link: '',
      question_list_paragraph_links_text: '',
    },
  }],
  have_any_question_title: '',
  have_any_question_subtitle: '',
  have_any_question_button_text: '',
  have_any_question_button_link: '',
  how_to_connect_title: '',
  how_to_connect_title_bold: 'How',
  how_to_connect_cards: [{
    contact_person_image: '',
    contact_person_position: '',
    contact_person_name: '',
    contact_person_qr: '',
    contact_person_list: [{
      contact_person_list_item: '',
    }],
    person_contacts_list: [{
      contact_item_logo: '',
      contact_item_text: '',
      contact_item_link: '',
    }],
  }],
  where_to_find_title: '',
  where_to_find_title_bold: 'Where',
  where_to_find_address_title: '',
  where_to_find_address_list: [{
    where_to_find_address_list_item: '',
    where_to_find_address_list_item_bold: '',
  }],
  where_to_find_phone_title: '',
  where_to_find_phone_list: [{
    where_to_find_phone_list_item: '',
    where_to_find_phone_list_item_link: '',
  }],
  where_to_find_email_title: '',
  where_to_find_email_list: [{
    where_to_find_email_list_item: '',
    where_to_find_email_list_link: '',
  }],
  where_to_find_web_title: '',
  where_to_find_web_list: [{
    where_to_find_web_list_item: '',
    where_to_find_web_list_link: '',
  }],
};

export const AboutProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TAboutUsTextData>(initialState);

  return (
    <AboutUsContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </AboutUsContext.Provider>
  );
};
