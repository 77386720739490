import React from 'react';

import {CustomButton} from '../../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../../utils/constants/buttonVariantEnum';

import styles from './saveTimeBlockInformation.module.scss';

interface ListItem {
  developers_team_block_text_list_text?: string,
  developers_team_block_text_list_need_sublist?: boolean,
  developers_team_block_text_sublist?: boolean |
    {
      developers_team_block_text_sublist_text?: string
    }[]
}

export interface ItemInformation {
  id: number;
  img?: string;
  title?: string;
  subTitle?: string;
  text?: string;
  optionList?: {
    listTitle?: string;
    options?: {save_time_information_block_list_item?: string}[];
  };
  list?: {
    developers_team_block_text_list_title?: string;
    developers_team_block_text_list_items?: ListItem[];
  }[];
  textBottom?: string;
  buttontext?: string;
  buttonLink?: string;
}

interface SaveTimeBlockInformationProps {
  itemInformation: ItemInformation[];
}

const SaveTimeBlockInformation: React.FC<SaveTimeBlockInformationProps> = ({
  itemInformation,
}) => {
  return (
    <div className={styles.informationBlock}>
      {itemInformation.map((item) => (
        <div className={styles.informationItem} key={item.id}>
          <img
            className={styles.imgItem}
            src={item.img}
            alt={`Item ${item.id}`}
          />
          <div className={styles.content}>
            <h3 className={styles.title}>{item.title}</h3>
            <p className={styles.subTitle}>{item.subTitle}</p>
            <p className={styles.text}>{item.text}</p>
            {item.optionList && (
              <div className={styles.listOption}>
                <p className={styles.listOptionTitle}>
                  {item.optionList.listTitle}
                </p>
                <ol className={styles.list}>
                  {item.optionList.options?.map((option, index) => (
                    <li key={index}>{option.save_time_information_block_list_item}</li>
                  ))}
                </ol>
              </div>
            )}
            {item.list && (
              <div className={styles.listItemsBlock}>
                {item.list.map((listItem, index) => (
                  <div className={styles.listOptions} key={index}>
                    <h3 className={styles.title}>
                      {listItem.developers_team_block_text_list_title}
                    </h3>

                    {listItem.developers_team_block_text_list_items?.map((el, elIndex) => {
                      return (
                        <div className={styles.text} key={elIndex}>
                          <p>{el.developers_team_block_text_list_text}</p>
                          <ul>
                            {
                              el.developers_team_block_text_list_need_sublist
                              &&
                              typeof el.developers_team_block_text_sublist !== 'boolean'
                              &&
                              (
                                (el.developers_team_block_text_sublist as Array<any>).map(
                                  (e, eIndex) => (
                                    <li key={eIndex}>
                                      {e.developers_team_block_text_sublist_text}
                                    </li>
                                  ),
                                )
                              )
                            }
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
            <p className={styles.bottomText}>{item.textBottom}</p>
            <CustomButton
              className={styles.linkButton}
              onClick={() => {}}
              variant={ButtonVariant.Primary}
            >
              <span className={styles.btnText}>{item.buttontext}</span>
            </CustomButton>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SaveTimeBlockInformation;
