import {FC, useContext} from 'react';

import picture from '../../../../assets/images/bigTwo.png';

import {AboutUsContext} from '../../../../context/AboutUsContext';

import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './whatIsOurHistory.module.scss';


export const WhatIsOurHistory: FC = () => {
  const context = useContext(AboutUsContext);
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          <HighlightText
            text={context?.dataContext.what_is_our_history_title as string}
            highlight={context?.dataContext.what_is_our_history_title_bold as string}
            className={styles.title}
            highlightClass={styles.boldText}
          />
        </div>
        <img src={picture} className={styles.img} alt="" />
      </div>
      <div className={styles.annotations}>
        {context?.dataContext.what_is_our_history_list.map((item, index) => (
          <div key={index} className={styles.annotation}>
            <div className={styles.annotation__title}>{item.what_is_our_history_list_title}</div>
            <HighlightText
              text={item.what_is_our_history_list_text as string}
              highlight={item.what_is_our_history_list_text_orange as string}
              className={styles.annotation__description}
              highlightClass={styles.orange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
