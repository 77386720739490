import React, {useContext} from 'react';

import PrivacyPolicyLists from '../../../components/privacyPolicyLists/PrivacyPolicyLists';

import {PricingOptionsContext} from '../../../context/PricingOptionsContext';

import styles from './needFollowingBlock.module.scss';

interface Listitems {
  following_block_list_item: string;
}

function splitArray(array: Listitems[]):
    [Listitems[], Listitems[]] {
  const half = Math.floor(array.length / 2);
  const firsctColumn = array.slice(0, half + array.length % 2);
  const secondColumn = array.slice(half + array.length % 2);
  return [firsctColumn, secondColumn];
};

export const NeedFollowingBlock: React.FunctionComponent = () => {
  const context = useContext(PricingOptionsContext);

  const [firsctColumn, secondColumn] = splitArray(context?.dataContext.following_block_list || []);

  return (
    <div className={styles.mainStepsBlock}>
      <div className={styles.content}>
        <h3 className={styles.title}>{context?.dataContext.following_block_title}</h3>
        <div className={styles.listsBlock}>
          <ol className={styles.listContainer}>
            {firsctColumn.map((item, id) => (
              <PrivacyPolicyLists
                key={id}
                text={item.following_block_list_item}
                className={styles.listItem}
              />
            ))}
          </ol>
          <ol className={styles.listContainer}>
            {secondColumn.map((item, id) => (
              <PrivacyPolicyLists
                key={id}
                text={item.following_block_list_item}
                className={styles.listItem}
              />
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default NeedFollowingBlock;
