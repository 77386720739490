import React, {useContext} from 'react';

import PrivacyPolicyLists from '../../../components/privacyPolicyLists/PrivacyPolicyLists';

import {PrivacyPolicyContext} from '../../../context/PrivacyPolicyContext';

import styles from './accessPersonalData.module.scss';

export const AccessPersonalData: React.FunctionComponent = () => {
  const context = useContext(PrivacyPolicyContext);

  return (
    <div className={styles.accessPersonalData}>
      <h2 className={styles.title}>
        {context?.dataContext.access_to_personal_data_title}
      </h2>
      <ol className={styles.listContainer}>
        {context?.dataContext.access_to_personal_data_ol.map((item, id) => (
          <PrivacyPolicyLists
            key={id}
            text={item.access_to_personal_data_li}
            className={styles.listItem}
          />
        ))}
      </ol>
    </div>
  );
};

export default AccessPersonalData;
