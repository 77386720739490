import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import '@wordpress/block-library/build-style/style.css';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {Preloader} from '../../components/preloader/Preloader';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

import useApi from '../../api/useApi';
import {API_URLS} from '../../api/routes';

import {TArticleTextData, TFooterData} from '../../utils/types/pagesTypes';
import {FooterContext} from '../../context/FooterContext';

import picture from '../../assets/images/sitemapPicture.png';

import styles from './article.module.scss';

type TArticleData = {
  'post_fields': TArticleTextData,
}

export const Article: React.FunctionComponent = () => {
  const {articleName} = useParams();
  const {data, isLoading, error} =
    useApi<TArticleData>(`${API_URLS.BLOG}/${articleName}`, {method: 'GET'});
  const footer =
    useApi<TFooterData>(`${API_URLS.BLOG}${API_URLS.FOOTER}`, {method: 'GET'});
  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);


  if (isLoading || error || !data) {
    return <Preloader />;
  }

  const fields = data['post_fields'];

  const breadcrumbs = fields.breadcrumb.map(item => {return {label: item.title, href: item.link};});

  return (
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <div className={styles.wrapper}>
        <Breadcrumbs crumbs={breadcrumbs} />
        <div className={styles.title}>{fields.title.rendered}</div>
        <p className={styles.subtitle}>{fields.author} | {fields.date}</p>
        <div dangerouslySetInnerHTML={{__html: fields.content.rendered}} />
      </div>
    </div>
  );
};

export default Article;
