const NOT_FOUND = '/404';
const PRIVACY_POLICY = '/privacy-policy';
const PCI_CERTIFICATE = '/pci-dss-certificate';
const CONTACTS = '/contacts';
const OFFER_YOUR_MERCHANTS = '/offer-your-merchants';
const FOR_DEVELOPERS = '/developers';
const ABOUT_US = '/about-us';
const PAYMENT = '/payment';
const RELIABITITY_AND_SAFETY = '/anti-fraud';
const PRICING_OPTIONS = '/pricing';
const REPORT_AND_BALANCE = '/reports';
const INTEGRATIONS = '/integrations';
const HOME = '/homepage';
const MERCHANT = '/merchant';
const FOOTER = '/getready';
const SITEMAP = '/sitemap';
const USE_CASES = '/use-cases';
const BLOG = '/blog';

export const API_URLS = {
  NOT_FOUND,
  PRIVACY_POLICY,
  PCI_CERTIFICATE,
  CONTACTS,
  FOR_DEVELOPERS,
  ABOUT_US,
  RELIABITITY_AND_SAFETY,
  PRICING_OPTIONS,
  PAYMENT,
  OFFER_YOUR_MERCHANTS,
  REPORT_AND_BALANCE,
  INTEGRATIONS,
  HOME,
  MERCHANT,
  FOOTER,
  SITEMAP,
  USE_CASES,
  BLOG,
};
