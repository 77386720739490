import {FC, Fragment, useContext} from 'react';

import bigFour from '../../../../assets/images/bigFour.png';

import HighlightText from '../../../../components/highlightText/HighlightText';
import {AboutUsContext} from '../../../../context/AboutUsContext';

import styles from './whatDoWeTrust.module.scss';

export const WhatDoWeTrust: FC = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      <div className={styles.paragraph}>
        <img alt="" className={styles.bigFourImg} src={bigFour} />
        <div className={styles.block} />
        <div className={styles.title}>
          <HighlightText
            text={context?.dataContext.what_do_we_trust_title as string}
            highlight={context?.dataContext.what_do_we_trust_title_bold as string}
            className={styles.title}
            highlightClass={styles.boldText}
          />
        </div>
        <div className={styles.description}>
          {context?.dataContext.what_do_we_trust_text.map((item, index) => {
            return (
              <Fragment key={index}>
                <p>{item.what_do_we_trust_text_item}</p>
                <br />
              </Fragment>
            );
          })}
        </div>
      </div>
      <img alt="" className={styles.img} src={context?.dataContext.what_do_we_trust_image} />
    </div>
  );
};
