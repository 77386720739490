import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {Select} from '@mantine/core';

import {ReactComponent as Logo} from '../../assets/icons/logo.svg';
import {ReactComponent as ArrowDown} from '../../assets/icons/arrowDown.svg';
import {PATH} from '../../utils/constants/routeConstants';

import {CustomButton} from '../customButton/CustomButton';
import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';

import {MenuBtnMobile} from './components/menuBtnMobile/MenuBtnMobile';
import {HeaderLinks} from './components/headerLinks/HeaderLinks';

import styles from './header.module.scss';

export const Header: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const languages = ['en', 'es'];

  const [lang, setLang] = useState<string| undefined>((() => {
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage || 'en';
  }));

  const [isDown, setIsDown] = useState<boolean>(true);

  useEffect(() => {
    if (lang) {
      localStorage.setItem('language', lang);
    }
  }, [lang]);

  const onClick = () => {
    navigate(PATH.contacts());
  };

  const changeLang = (newLang: string | null) => {
    if (newLang && newLang !== lang) {
      setLang(newLang);
      window.location.reload();
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.rightHeaderWrapper}>
          <NavLink className={styles.linkLogo} to={PATH.home}>
            <Logo />
          </NavLink>
          <div className={styles.linksWrapper}>
            <div className={styles.separatorBlock} />
            <HeaderLinks />
          </div>
        </div>
        <div className={styles.contactsWithLang}>
          <CustomButton
            className={styles.btn}
            onClick={onClick}
            variant={ButtonVariant.OutLine}
          >
            Contact sales
          </CustomButton>
          <div className={styles.langWrapper}>
            {lang && <Select
              variant="unstyled"
              data={languages}
              value={lang}
              onChange={changeLang}
              rightSection={ isDown ? <ArrowDown/> : <ArrowDown className={styles.arrowUp}/>}
              onDropdownOpen={() => {setIsDown(false);}}
              onDropdownClose={() => {setIsDown(true);}}
              styles={{rightSection: {pointerEvents: 'none'}}}
              classNames={{
                root: styles.langRoot,
                input: styles.langInput,
                dropdown: styles.langDropdown,
                item: styles.langItem,
                rightSection: styles.langArrow,
              }}
            />}
          </div>
        </div>
        <MenuBtnMobile />
      </div>
    </div>
  );
};

export default Header;
