import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {Preloader} from '../../components/preloader/Preloader';

import psciDssImage from '../../assets/images/4_project.png';

import useApi from '../../api/useApi';
import {API_URLS} from '../../api/routes';

import {TFooterData} from '../../utils/types/pagesTypes';
import {FooterContext} from '../../context/FooterContext';

import OptionsBlock from './optionsBlock/OptionsBlock';

import styles from './pciDssCertificate.module.scss';

type TPciDssCertificateData = {
  'pci_dss_fields': TPciDssCertificateTextData,
}

type TPciDssCertificateTextData = {
  pci_title: string,
  pci_subtitle: string,
  option1_title: string,
  option1_up: string,
  option1_up_orange: string,
  option1_down: string,
  option1_down_orange: string,
  option2_title: string,
  option2_up: string,
  option2_up_orange: string,
  option2_down: string
  option2_down_orange: string;
  pci_image: string;
}

export const PciDssCertificate: React.FunctionComponent = () => {
  const context = useContext(FooterContext);
  const {data, isLoading, error} =
    useApi<TPciDssCertificateData>(API_URLS.PCI_CERTIFICATE, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.PCI_CERTIFICATE}${API_URLS.FOOTER}`, {method: 'GET'});

  useEffect(() => {
    if (context && footer.data) {
      context?.setDataContext(footer.data['get_ready_fields']);
    }
  },[context, footer]);

  if (isLoading || footer.isLoading ||
      error || footer.error ||
      !data || !footer.data) {
    return <Preloader />;
  }

  const text = data['pci_dss_fields'];

  const options = [{
    title: text?.option1_title,
    subTitle: text?.option1_up,
    highlightSubTitle: text?.option1_up_orange,
    text: text?.option1_down,
    highlightText: text?.option1_down_orange,
  },
  {
    title: text?.option2_title,
    subTitle: text?.option2_up,
    highlightSubTitle: text?.option2_up_orange,
    text: text?.option2_down,
    highlightText: text?.option2_down_orange,
  },
  ];

  return (
    <div className={styles.container}>
      <HeaderBlock>
        <img className={styles.mainImage} alt="" src={psciDssImage}/>
      </HeaderBlock>
      <div className={styles.mainTextContent}>
        <h1 className={styles.title}>{text?.pci_title}</h1>
        <p className={styles.text}>{text?.pci_subtitle}</p>
      </div>
      <OptionsBlock options={options} img={text?.pci_image}/>
    </div>
  );
};

export default PciDssCertificate;
