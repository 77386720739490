import React, {useContext, useEffect} from 'react';

import buttonImage from '../../assets/images/button_block.png';

import {Preloader} from '../../components/preloader/Preloader';
import {API_URLS} from '../../api/routes';
import {ReliabilitySafetyContext} from '../../context/ReliabilitySafetyContext';
import {TFooterData, TReliabilitySafetyTextData} from '../../utils/types/pagesTypes';
import useApi from '../../api/useApi';

import img from '../../assets/images/test.png';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import {FooterContext} from '../../context/FooterContext';

import BeGatewaySafety from './beGatewaySafety/BeGatewaySafety';
import BeGatewayReliability from './beGatewayReliability/BeGatewayReliability';
import SecurityAndComplianc from './securityAndCompliance/SecurityAndCompliance';

import styles from './reliabilitySafety.module.scss';


type TReliabilitySafetyData = {
  'reliability_group': TReliabilitySafetyTextData,
}

export const ReliabilitySafety: React.FunctionComponent = () => {

  const {data, isLoading, error} =
    useApi<TReliabilitySafetyData>(API_URLS.RELIABITITY_AND_SAFETY, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.RELIABITITY_AND_SAFETY}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(ReliabilitySafetyContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['reliability_group']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.privacyPolicyTitle}>
        <HeaderBlock src={img} />
      </div>
      <h1 className={styles.title}>{context?.dataContext.reliability_title}</h1>
      <div className={styles.startListContent}>
        <SecurityAndComplianc />
        <BeGatewayReliability />
      </div>
      <div className={styles.startListContent}>
        <div className={styles.imageBlock}>
          <div className={styles.imageContainer}></div>
          <img className={styles.buttonImage} src={buttonImage} alt="star" />
        </div>
        <BeGatewaySafety />
      </div>
    </div>
  );
};

export default ReliabilitySafety;
