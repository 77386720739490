import React, {useCallback, useContext} from 'react';

import {ReliabilitySafetyContext} from '../../../context/ReliabilitySafetyContext';

import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './beGatewayReliability.module.scss';

export const BeGatewayReliability: React.FunctionComponent = () => {
  const context = useContext(ReliabilitySafetyContext);

  const renderListItems = useCallback((item: any, index: number) => {
    return(
      <li className={styles.listItem} key={index}>
        <HighlightText
          text={item.begateway_reliability_list_item as string}
          highlight={item.begateway_reliability_list_item_bold as string}
          highlightClass={styles.baldText}
        />
      </li>
    );
  }, []);

  const renderLists = useCallback((arr: Array<any>) => {

    const length = arr.length;
    const middleIndex = Math.floor(length / 2);

    const firstArray = arr.slice(0, middleIndex + (length % 2));
    const secondArray = arr.slice(middleIndex + (length % 2));

    return(
      <>
        <ul className={styles.list}>
          {firstArray.map(renderListItems)}
        </ul>
        <ul className={styles.list}>
          {secondArray.map(renderListItems)}
        </ul>
      </>
    );

  }, [renderListItems]);

  return (
    <div className={styles.beGatewayReliability}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          <HighlightText
            text={context?.dataContext.begateway_reliability_title as string}
            highlight={context?.dataContext.begateway_reliability_title_orange as string}
            highlightClass={styles.orange}
          />
        </h2>
        <div className={styles.listsBlock}>
          {renderLists(context?.dataContext.begateway_reliability_list as Array<any>)}
        </div>
      </div>
    </div>
  );
};

export default BeGatewayReliability;
