import React, {useContext} from 'react';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import styles from './subscriptionManagements.module.scss';

export const SubscriptionManagements: React.FunctionComponent = () => {
  const context = useContext(MerchantManagementContext);

  return (
    <div className={styles.subscriptionManagements}>
      <div className={styles.content}>
        <h3 className={styles.title}>{context?.dataContext.subscription_managements_title}</h3>
        <img
          className={styles.image}
          src={context?.dataContext.subscription_managements_image}
          alt="imageSubscriptionManagements"
        />
        <div className={styles.textContent}>
          {context?.dataContext.subscription_managements_text_list.map((text, id) => (
            <div key={id}>
              <p>{text.subscription_managements_text_item}</p>
              {(text.subscription_managements_text_need_list &&
                    text.subscription_managements_list_in_text) &&
                  <ol className={styles.list}>
                    {text.subscription_managements_list_in_text.map((point, index) => {
                      return <li key={`point${index}`}>
                        {point.subscription_managements_list_item_in_text}
                      </li>;
                    })}
                  </ol>}
            </div>
          ))}
        </div>
      </div>
      <img
        className={styles.imageDesktop}
        src={context?.dataContext.subscription_managements_image}
        alt="imageSubscriptionManagements"
      />
    </div>
  );
};

export default SubscriptionManagements;
