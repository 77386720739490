import {FC, useContext} from 'react';

import {ContactPersonHTCU} from '../contactPersonHTCU/ContactPersonHTCU';

import {AboutUsContext} from '../../../../../../context/AboutUsContext';

import styles from './contactPersonsHTCU.module.scss';

export const ContactPersonsHTCU: FC = () => {

  const context = useContext(AboutUsContext);

  return <div className={styles.container}>
    {context?.dataContext.how_to_connect_cards.map((values) => {
      return(
        <ContactPersonHTCU key={values.contact_person_position} {...values} />
      );
    },
    )}
  </div>;
};
