import React, {useContext} from 'react';
import {Carousel} from '@mantine/carousel';

import {SlideShow} from '../../../components/slideShow/SlideShow';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import {SliderItem} from './sliderItem/SliderItem';

import styles from './transactionManagements.module.scss';

export const TransactionManagements: React.FunctionComponent = () => {
  const context = useContext(MerchantManagementContext);

  const renderList =
  (list: {transaction_managements_list_item_in_text: string}[]) => {
    const half = Math.floor(list.length / 2);
    const firsctColumn = list.slice(0, half + list.length % 2);
    const secondColumn = list.slice(half + list.length % 2);
    return (
      <div className={styles.listsContainer}>
        <ol className={styles.list}>
          {firsctColumn.map((point, index) => {
            return <li key={`firsctColumn${index}`}>
              {point.transaction_managements_list_item_in_text}
            </li>;
          })}
        </ol>
        <ol className={styles.list}>
          {secondColumn.map((point, index) => {
            return <li key={`secondColumn${index}`}>
              {point.transaction_managements_list_item_in_text}
            </li>;
          })}
        </ol>
      </div>
    );
  };

  return (
    <div className={styles.transactionManagements}>
      <div className={styles.containerTop}>
        <div className={styles.beveledBlockTop}></div>
        <div className={styles.content}>
          <div className={styles.titleContent}>
            <h3 className={styles.title}>{context?.dataContext.transaction_managements_title}</h3>
            <p className={styles.text}>
              {context?.dataContext.transaction_managements_description}
            </p>
          </div>
          <p className={styles.titleSlider}>
            {context?.dataContext.transaction_managements_carousel_title}
          </p>
        </div>
        <div className={styles.slider}>
          <Carousel
            withControls={false}
            initialSlide={0}
            align={'start'}
            loop
          >
            {context?.dataContext.transaction_managements_carousel.map(
              (item, index) => {
                return(<SliderItem
                  key={`transaction_managements_carousel${index}`}
                  image={item.transaction_managements_item_icon}
                  description={item.transaction_managements_item_description}
                  title={item.transaction_managements_item_title}
                />);
              })
            }
          </Carousel>
        </div>
      </div>
      <div>
      </div>
      <div className={styles.sliderContent}>
        <div className={styles.contentContainer}>
          {context?.dataContext.transaction_managements_text_list.map((text, id) => {
            return (
              <div key={id} className={styles.textContent}>
                <p className={styles.text}>{text.transaction_managements_text_item}</p>
                {(text.transaction_managements_text_need_list &&
                    text.transaction_managements_list_in_text) &&
                  renderList(text.transaction_managements_list_in_text)
                }
              </div>
            );})}
          <div className={styles.sliderBlock}>
            <SlideShow
              className={styles.slides}
              slidersLength={context?.dataContext
                .transaction_managements_image_carousel.length as number}
            >
              {context?.dataContext.transaction_managements_image_carousel.map((item, index) => {
                return (
                  <img
                    className={styles.images}
                    key={index}
                    alt="slide"
                    src={item.transaction_managements_image}
                  />
                );
              })}
            </SlideShow>
          </div>
        </div>
        <div className={styles.beveledBlock}></div>
      </div>
    </div>
  );
};

export default TransactionManagements;
