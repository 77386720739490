import React, {createContext, useState} from 'react';

import {THomeTextData} from '../utils/types/pagesTypes';


interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: THomeTextData;
  setDataContext: React.Dispatch<React.SetStateAction<THomeTextData>>;
}

export const HomeContext = createContext<DataContextProps | undefined>(undefined);

const initialState: THomeTextData = {
  main_screen_title: '',
  main_screen_subtitle: '',
  certificate_title: '',
  certificate_subtitle: '',
  certificate_button_text: '',
  certificate_button_link: '',
  build_screen_title: '',
  build_screen_subtitle: '',
  build_screen_subtitle_orange: '',
  merchant_title: '',
  merchant_subtitle: '',
  simple_integration_title: '',
  simple_integration_h1: '',
  simple_integration_h2: '',
  simple_integration_image: '',
  payment_service_provider_title: '',
  you_psp_title: '',
  brand_domain_name: '',
  powered_by: '',
  connectors_title: '',
  connectors_h1: '',
  connectors_h2: '',
  connectors_image: '',
  payment_methods_title: '',
  payment_methods: [{
    payment_method: '',
  }],
  all_shopping_carts_button_text: '',
  all_shopping_carts_button_link: '',
  request_a_list_button_text: '',
  request_a_list_button_link: '',
  all_payment_brands_button_text: '',
  all_payment_brands_button_link: '',
  request_demo_button_text: '',
  request_demo_button_link: '',
  carousel: [{
    carousel_title: '',
    carousel_description: '',
    carousel_image: '',
  }],
  get_a_complete_payment_title: '',
  get_a_complete_payment_subtitle: '',
  get_a_complete_payment_items: [{
    get_a_complete_payment_item_title: '',
    get_a_complete_payment_item_link: '',
    get_a_complete_payment_item_list: [{
      get_a_complete_payment_item_text: '',
    }],
  }],
  sales_button_text: '',
  sales_button_link: '',
  up_payment_system_title: '',
  up_payment_system_subtitle: '',
  up_payment_system_subtitle_orange: '',
  up_payment_system_list_title: '',
  up_payment_system_list: [{
    up_payment_system_list_item: '',
  }],
  compare_pricing_button_text: '',
  compare_pricing_button_link: '',
  option_1_header_title: '',
  option_1_title: '',
  option_1_description: '',
  option_1_description_bold: '',
  option_2_header_title: '',
  option_2_title: '',
  option_2_description: '',
  option_2_description_bold: '',
  option_3_header_title: '',
  option_3_title: '',
  option_3_description: '',
  option_3_description_bold: '',
  save_your_time_title: '',
  save_your_time_subtitle: '',
  save_your_time_image: '',
  save_time_information_block_title: '',
  save_time_information_block_subtitle: '',
  save_time_information_block_text: '',
  save_time_information_block_list_title: '',
  save_time_information_block_list: [{
    save_time_information_block_list_item: '',
  }],
  save_time_information_block_bottom_text: '',
  save_time_information_block_button_text: '',
  save_time_information_block_button_link: '',
  developers_team_block_title: '',
  developers_team_block_image: '',
  developers_team_block_subtitle: '',
  developers_team_block_text: '',
  developers_team_block_text_list: [{
    developers_team_block_text_list_title: '',
    developers_team_block_text_list_items: [{
      developers_team_block_text_list_text: '',
      developers_team_block_text_list_need_sublist: false,
      developers_team_block_text_sublist: false,
    }],
  }],
  developers_team_block_bottom_text: '',
  developers_team_block_button_text: '',
  developers_team_block_button_link: '',
  technical_support_block_title: '',
  technical_support_block_subtitle: '',
  technical_support_block_image: '',
  technical_support_block_text: '',
  technical_support_block_bottom_text: '',
  technical_support_block_button_text: '',
  technical_support_block_button_link: '',
  fight_fraud_block_title: '',
  fight_fraud_block_subtitle: '',
  fight_fraud_cards: [{
    fight_fraud_card_title: '',
    fight_fraud_cards_title_orange: '',
    fight_fraud_cards_list: [{
      fight_fraud_cards_list_item: '',
    }],
  }],
  fight_fraud_cards_button_text: '',
  fight_fraud_button_link: '',
  cases_title: '',
  cases_slider: [{
    img: '',
    img_md: '',
    img_s: '',
    title: '',
    subtitle: '',
    textInformationItems: [{
      title: '',
      text: '',
    }],
    case_link: '',
  }],
  benefit_block_title: '',
  benefit_block_subtitle: '',
  benefit_block_subtitle_orange: '',
  benefit_block_list: [{
    benefit_block_item_number: '',
    benefit_block_item_text: '',
  }],
  secured_main_title: '',
  secured_slides: [{
    slide_text: '',
    slide_image: '',
  }],
};

export const HomeProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<THomeTextData>(initialState);

  return (
    <HomeContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </HomeContext.Provider>
  );
};
