import React, {useContext, useEffect} from 'react';

import {API_URLS} from '../../api/routes';
import useApi from '../../api/useApi';

import picture from '../../assets/images/sitemapPicture.png';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {Preloader} from '../../components/preloader/Preloader';

import {TFooterData, TPrivacyPolicyTextData} from '../../utils/types/pagesTypes';

import {PrivacyPolicyContext} from '../../context/PrivacyPolicyContext';
import {FooterContext} from '../../context/FooterContext';

import GeneralProvisions from './generalProvisions/GeneralProvisions';
import PersonalDataManagement from './personalDataManagement/PersonalDataManagement';
import AccessPersonalData from './accessPersonalData/AccessPersonalData';
import PersonalDataForClient from './personalDataForClient/PersonalDataForClient';
import FinalProvisions from './finalProvisions/FinalProvisions';

import styles from './privacyPolicy.module.scss';

type TPrivacyPolicyData = {
  'privacy_policy_fields': TPrivacyPolicyTextData,
}

export const PrivacyPolicy: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TPrivacyPolicyData>(API_URLS.PRIVACY_POLICY, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.PRIVACY_POLICY}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(PrivacyPolicyContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['privacy_policy_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <GeneralProvisions />
      <PersonalDataManagement />
      <AccessPersonalData />
      <PersonalDataForClient />
      <FinalProvisions />
    </div>
  );
};

export default PrivacyPolicy;
