import {FC} from 'react';

import {ISliderTTPYM} from '../../../../config/config';

import styles from './sliderItemTTOYM.module.scss';

export const SliderItemTTOYM: FC<ISliderTTPYM> = ({
  carousel_title,
  carousel_description,
  carousel_image,
}) => {
  return (
    <div className={styles.container}>
      {carousel_image && (
        <div>{carousel_image}</div>
      )}
      <div className={styles.title}>{carousel_title}</div>
      <div className={styles.desc}>{carousel_description}</div>
    </div>
  );
};
