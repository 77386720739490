import React, {FC, useState} from 'react';

import cn from 'classnames';

import {IPersonsHTCU} from '../../config/personHTCUConstant';
import {ReactComponent as ArrowDown} from '../../../../../../assets/icons/arrowDown.svg';

import styles from './contactPersonHTCU.module.scss';

interface ContactPersonHTCUProps extends IPersonsHTCU {}

export const ContactPersonHTCU: FC<ContactPersonHTCUProps> = ({
  contact_person_position,
  contact_person_image,
  contact_person_name,
  contact_person_qr,
  contact_person_list,
  person_contacts_list,
}) => {
  const [isActive, setActive] = useState<boolean>(false);
  const onClick = () => {
    setActive((pr) => !pr);
  };
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <img className={styles.img} alt={contact_person_name} src={contact_person_image} />
        <div className={styles.infoWrapper}>
          <h3 className={styles.position}>{contact_person_position}</h3>
          <div onClick={onClick} className={styles.name}>
            <p className={styles.name_text}>{contact_person_name}</p>
            <div
              className={cn(styles.name_svgWrapper, {
                [styles.name_svgWrapper_active]: isActive,
              })}
            >
              <ArrowDown />
            </div>
          </div>
          {contact_person_list.map((item, index) => (
            <div
              className={cn(
                styles.specificationWrapper,
                {[styles.specificationWrapper_active]: isActive},
              )}
              key={index}>
              <div className={styles.dottedLine} />
              <p className={styles.specifications}>{item.contact_person_list_item}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.contacts}>
        <div className={styles.contactsInfoWrapper}>
          {person_contacts_list && person_contacts_list.map((item, index) => {
            return(
              <div className={styles.contactInfoWrapper} key={index}>
                <img className={styles.contactInfoLogo} alt="Logo" src={item.contact_item_logo} />
                <a href={item.contact_item_link}
                  className={styles.contactInfoWrapper__text}
                >
                  {item.contact_item_text}
                </a>
              </div>
            );
          })}
        </div>
        <img
          className={styles.qrCode}
          alt={`qrCode ${contact_person_name}`}
          src={contact_person_qr}
        />
      </div>
    </div>
  );
};
