import {FunctionComponent} from 'react';

import {Loader} from '@mantine/core';

import styles from './preloader.module.scss';

export const Preloader: FunctionComponent = () => {
  return (
    <div className={styles.container}>
      <Loader color="gray" size={70} />
    </div>
  );
};
