import React, {useContext} from 'react';

import imageBackground from '../../../assets/images/Illustration (1).png';
import imageDesktop from '../../../assets/images/Illustration (2).png';
import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {HomeContext} from '../../../context/HomeContext';
import HighlightText from '../../../components/highlightText/HighlightText';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './fightFraud.module.scss';

const FightFraud: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const handleClick = () => {
    if (isValidURL(context?.dataContext.fight_fraud_button_link)) {
      window.open(context?.dataContext.fight_fraud_button_link, '_blank');
    }
  };

  return (
    <div className={styles.fightFraud}>
      <img
        className={styles.imageBackground}
        src={imageBackground}
        alt="background"
      />
      <img
        className={styles.imageBackgroundDesktop}
        src={imageDesktop}
        alt="background"
      />
      <div className={styles.content}>
        <h3 className={styles.title}>
          {context?.dataContext.fight_fraud_block_title}
        </h3>
        <p className={styles.text}>
          {context?.dataContext.fight_fraud_block_subtitle}
        </p>
        <div className={styles.cardContainer}>
          {context?.dataContext.fight_fraud_cards.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <p className={styles.cardTitle}>
                  <HighlightText
                    text={item.fight_fraud_card_title as string}
                    highlight={item.fight_fraud_cards_title_orange as string}
                    className={styles.cardTitle}
                    highlightClass={styles.orange}
                  />
                </p>
                <ol className={styles.cardList}>
                  {item.fight_fraud_cards_list.map((item, index) => (
                    <li key={index}>{item.fight_fraud_cards_list_item}</li>
                  ))}
                </ol>
              </div>
            );
          })}
        </div>
        <CustomButton
          className={styles.linkButton}
          onClick={handleClick}
          variant={ButtonVariant.Primary}
        >
          <span className={styles.btnText}>
            {context?.dataContext.fight_fraud_cards_button_text}
          </span>
        </CustomButton>
      </div>
    </div>
  );
};

export default FightFraud;
