import React, {useContext} from 'react';

import ContactCard from '../../../components/contactCard/ContactCard';

import {ForDevelopersContext} from '../../../context/DevelopersContext';

import styles from './questionsBlock.module.scss';


export const QuestionsBlock: React.FunctionComponent = () => {

  const context = useContext(ForDevelopersContext);

  const cardItems = [
    {
      id: 1,
      icon: context?.dataContext.sales_block_image,
      title: context?.dataContext.sales_title,
      text: context?.dataContext.sales_block_description,
      buttonText: context?.dataContext.sales_button_text,
      link: context?.dataContext.sales_button_link,
    },
    {
      id: 2,
      icon: context?.dataContext.tech_support_block_image,
      title: context?.dataContext.tech_support_block_title,
      text: context?.dataContext.tech_support_block_description,
      buttonText: context?.dataContext.tech_support_button_text,
      link: context?.dataContext.tech_support_button_link,
    },
    {
      id: 3,
      icon: context?.dataContext.dev_block_image,
      title: context?.dataContext.dev_block_title,
      text: context?.dataContext.dev_block_description,
      buttonText: context?.dataContext.dev_button_text,
      link: context?.dataContext.dev_button_link,
    },
  ];
  return (
    <div className={styles.questionsBlock}>
      <h3 className={styles.title}>{context?.dataContext.any_questions_title}</h3>
      <div className={styles.cardsContainer}>
        {cardItems.map((item) => (
          <ContactCard
            key={item.id}
            icon={item.icon}
            title={item.title}
            text={item.text}
            buttonText={item.buttonText}
            onClick={() => {window.open(item.link, '_blank');}}
          />
        ))}
      </div>
    </div>
  );
};

export default QuestionsBlock;
