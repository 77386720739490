import React, {useContext} from 'react';

import {IntegrationsContext} from '../../../../context/IntegrationsContext';

import styles from './welcomeBlockIntegrations.module.scss';

export const WelcomeBlockIntegrations = () => {
  const context = useContext(IntegrationsContext);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{context?.dataContext.integrations_title}</h1>
      <p className={styles.description}>
        {context?.dataContext.integrations_text}
      </p>
    </div>
  );
};
