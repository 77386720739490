import {createContext, useState} from 'react';

import {TReliabilitySafetyTextData} from '../utils/types/pagesTypes';

interface Props {
    children?: JSX.Element
}

interface DataContextProps {
  dataContext: TReliabilitySafetyTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TReliabilitySafetyTextData>>;
}

export const ReliabilitySafetyContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TReliabilitySafetyTextData = {
  reliability_title: '',
  security_title: '',
  security_description: '',
  begateway_reliability_title: '',
  begateway_reliability_title_orange: '',
  begateway_reliability_list: [{
    begateway_reliability_list_item: '',
    begateway_reliability_list_item_bold: '',
  }],
  begateway_safety_title: '',
  begateway_safety_title_orange: '',
  begateway_safety_img_list: [{
    begateway_safety_image_item: '',
  }],
  begateway_safety_list: [{
    begateway_safety_list_title: '',
    begateway_safety_list_text: '',
    begateway_safety_list_text_in_list: false,
  }],
  begateway_safety_button_text: '',
  begateway_safety_button_link: '',
};


export const ReliabilitySafetyProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TReliabilitySafetyTextData>(initialState);
  return (
    <ReliabilitySafetyContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </ReliabilitySafetyContext.Provider>
  );
};
