import React, {useContext} from 'react';

import imageStar from '../../../assets/images/star_image.png';

import {ReliabilitySafetyContext} from '../../../context/ReliabilitySafetyContext';

import styles from './securityAndCompliance.module.scss';

export const SecurityAndComplianc: React.FunctionComponent = () => {

  const context = useContext(ReliabilitySafetyContext);

  return (
    <div className={styles.securityAndComplianc}>
      <div className={styles.content}>
        <h2 className={styles.title}>{context?.dataContext.security_title}</h2>
        <p className={styles.text}>
          {context?.dataContext.security_description}
        </p>
      </div>
      <div className={styles.imageBlock}>
        <div className={styles.imageContainer}></div>
        <img className={styles.starImage} src={imageStar} alt="star" />
      </div>
    </div>
  );
};

export default SecurityAndComplianc;
