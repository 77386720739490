import {useCallback, useContext} from 'react';

import classNames from 'classnames';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './imgTextBlock.module.scss';

const ImgTextBlock: React.FunctionComponent = () => {
  const context = useContext(CaseSingleContext);

  const getParts = (text: string, highlightedText: Array<any>): Array<string> => {
    if(!highlightedText.length) return text ? [text] : [];

    const parts = text.split(new RegExp(`(${highlightedText[0]})`, 'gi'));

    return [...parts.slice(0, 2), ...getParts(parts[parts.length - 1], highlightedText.slice(1))];

  };
  const renderHighlightedText = useCallback(
    (text: string, highlight: Array<string>, className: string, highlightClass: string) => {
      const parts = getParts(text, highlight);

      return(
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              className={classNames(className, {
                [highlightClass]:
                highlight.some((item) => part.toLowerCase() === item.toLocaleLowerCase()),
              })}
            >
              {part}
            </span>

          ))}
        </span>
      );
    },[]);


  return(
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.imgContainer}>
          <div className={styles.imgWrap}>
            <img src={context?.dataContext.statistics_image} alt="" />
          </div>
          <div className={styles.triangle}></div>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.contentItem}>
            <h2 className={styles.title}>{context?.dataContext.statistics_title}</h2>
            <div className={styles.textBlock}>
              {context?.dataContext.statistics_text_list.map((item, index) => {
                return(
                  <div className={styles.descr} key={index}>
                    {
                      item.need_bold
                        ?
                        renderHighlightedText(
                          item.statistics_text_list_item,
                          item.statistics_bold_text_list.map((highlight) => highlight.bold_item),
                          styles.textBlock,
                          styles.bold,
                        )
                        :
                        <p className={styles.descr}>{item.statistics_text_list_item}</p>
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgTextBlock;

