import {useContext} from 'react';

import {ReactComponent as Row} from '../../../assets/icons/arrowRightOrange.svg';
import {splitStringByBr} from '../../../utils/hooks/splitString';

import {ReportAndBalanceContext} from '../../../context/ReportAndBalanceContext';

import styles from './pspReportApi.module.scss';

export const PspReportApi = () => {
  const context = useContext(ReportAndBalanceContext);

  return (
    <div className={styles.pspReportApi}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          {splitStringByBr(context?.dataContext.report_api_title || '').map((part, id) =>
            <span key={id}>
              {part}
              <br/>
            </span>)
          }
        </h3>
        <div className={styles.textBlock}>
          {context?.dataContext.report_api_text_list.map((item, id) => {
            return (
              <p className={styles.text} key={id}>
                {item.report_api_list_item}
              </p>
            );
          })}
          <a
            href={context?.dataContext.report_api_bottom_text_url}
            rel="noreferrer"
            target="_blank"
            className={styles.linkContainer}
          >
            <Row />
            <p>{context?.dataContext.report_api_bottom_text}</p>
          </a>
        </div>
      </div>
    </div>
  );
};
