import React, {useContext} from 'react';

import {ReactComponent as Sales} from '../../../assets/icons/sales.svg';
import {ReactComponent as Calendar} from '../../../assets/icons/calendar.svg';
import ContactCard from '../../../components/contactCard/ContactCard';
import LinksCard from '../../../components/linksCard/LinksCard';

import {ContactsContext} from '../../../context/ContactsContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';
import {LinkCard} from '../../../utils/types/commonTypes';

import styles from './newClientsBlock.module.scss';

export const NewClientsBlock: React.FunctionComponent = () => {
  const context = useContext(ContactsContext);

  const onClick = (link: string | undefined) => {
    if (isValidURL(link)) {
      window.open(link, '_blank');
    }
  };

  const links: LinkCard[] = context?.dataContext
    .other_form_info.map((item) => {
      const link: LinkCard = {
        img: item.other_info_image,
        text: item.other_info_text,
        link: item.other_info_link,
        underlineStyle: item.other_info_link_underline,
      };
      return link;
    }) || [];

  const cardItems = [
    {
      icon: <Sales />,
      title: context?.dataContext.contact_sales_title,
      text: context?.dataContext.contact_sales_subtitle,
      buttonText: context?.dataContext.contact_sales_button_text,
      buttonLink: context?.dataContext.contact_sales_button_link,
    },
    {
      icon: <Calendar />,
      title: context?.dataContext.book_a_demo_title,
      text: context?.dataContext.book_a_demo_subtitle,
      buttonText: context?.dataContext.book_a_demo_button_text,
      buttonLink: context?.dataContext.book_a_demo_button_link,
    },
  ];

  return (
    <div className={styles.newClientsBlock}>
      <h1 className={styles.globalTitle}>{context?.dataContext.contacts_title}</h1>
      <h2 className={styles.title}>{context?.dataContext.contacts_subtitle}</h2>
      <div className={styles.cardsContainer}>
        {cardItems.map((item, id) => (
          <ContactCard
            key={id}
            icon={item.icon}
            title={item.title}
            text={item.text}
            buttonText={item.buttonText}
            onClick={() => {onClick(item.buttonLink);}}
          />
        ))}
        <LinksCard links={links} />
      </div>
    </div>
  );
};

export default NewClientsBlock;
