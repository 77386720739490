import 'swiper/css';
import 'swiper/css/effect-fade';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectFade} from 'swiper/modules';

import {useCallback, useContext, useState} from 'react';
import classNames from 'classnames';

import {ReactComponent as Arrow} from '../../../assets/icons/whiteArrow.svg';

import {ReactComponent as CardDecorItem1} from '../../../assets/icons/cardDecorItem1.svg';
import {ReactComponent as CardDecorItem2} from '../../../assets/icons/cardDecorItem2.svg';


import HighlightText from '../../../components/highlightText/HighlightText';

import {UseCasesContext} from '../../../context/UseCasesContext';

import styles from './useCasesSlider.module.scss';

const UseCasesSlider: React.FunctionComponent = () => {

  const context = useContext(UseCasesContext);

  const textSlides = context?.dataContext.text_slides;
  const imagesSlides = context?.dataContext.img_slides;

  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = useCallback((swiper: any) => {
    setActiveSlide(swiper.realIndex);
  },[]);

  return(
    <div className={styles.container}>
      <h1 className={styles.title}>Use Cases</h1>
      <div className={styles.swipersWrap}>
        <div className={styles.textSlider}>
          <Swiper
            modules={[Autoplay, EffectFade]}
            speed={300}
            autoplay={{delay: 5000, reverseDirection: true}}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            slidesPerView={1}
            loop
            allowTouchMove={false}
          >
            {textSlides?.map((item, index) => {
              return(
                <SwiperSlide key={index}>
                  <div className={styles.textSlide}>
                    <div className={styles.titleText}>

                      <HighlightText
                        className={styles.slideTitle}
                        highlightClass={styles.orange}
                        text={item.title}
                        highlight={item.title_orange}
                      />
                    </div>
                    <p className={styles.slideDescr}>{item.description}</p>
                  </div>

                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div className={styles.imgSlider}>
          <Swiper
            className={styles.swiper}
            modules={[Autoplay]}
            speed={300}
            autoplay={
              (imagesSlides?.length && imagesSlides?.length > 4) ?
                undefined : {delay: 5000, reverseDirection: true}
            }
            slidesPerView={'auto'}
            slidesPerGroup={1}
            loop
            spaceBetween={20}
            onBeforeTransitionStart={handleSlideChange}
            allowTouchMove={false}
            breakpoints={{
              1660: {
                spaceBetween: 50,
              },
              1440: {
                spaceBetween: 20,
              },
              744: {
                spaceBetween: 20,

              },
            }}
          >
            {imagesSlides?.map((item, index) => {
              return(
                <SwiperSlide
                  key={index}
                  className={classNames(styles.swiperSlide,
                    activeSlide === index ? styles.activeSlide : '')}
                >
                  <a
                    href={item.image_link}
                    target="_blank"
                    className={styles.imgSlide}
                    rel="noreferrer"
                  >
                    <div className={styles.decorItemTop}>
                      <CardDecorItem1 />
                    </div>
                    <div className={styles.imgSlideTitle}>{item.img_title}</div>
                    <div className={styles.imgDescr}>{item.img_description}</div>
                    <div className={styles.imgWrap}>
                      <img src={item.image} alt="" />
                    </div>
                    <div className={styles.decorItemBot}>
                      <CardDecorItem2 />
                    </div>
                    <div className={styles.imgSlideLink}>
                      <Arrow />
                      {activeSlide === index ? 'Read the full story' : 'Read Case'}</div>
                    <div className={styles.triangle}></div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default UseCasesSlider;
