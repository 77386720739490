import React, {createContext, useState} from 'react';

import {TPaymentTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TPaymentTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TPaymentTextData>>;
}

export const PaymentContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TPaymentTextData = {
  payment_title: '',
  payment_main_text: [{text_paragraph: ''}],
  smart_routing_title: '',
  smart_routing_description: '',
  smart_routing_list: [{smart_routing_item: ''}],
  interface_block_text: '',
  interface_block_image: '',
  smart_routing_tab_title: '',
  smart_routing_tab_description: '',
  smart_routing_tab_bold_text: '',
  smart_routing_tab_orange_in_bold: '',
  smart_routing_tab_list: [{tab_list_item: ''}],
  settings_tab_title: '',
  settings_tab_description: '',
  settings_tab_bold_text: '',
  settings_tab_orange_in_bold: '',
  settings_tab_list: false,
  contact_sales_link_text: '',
  contact_sales_link: '',
  bot_prompt: '',
  visa_token_service_title: '',
  visa_token_service_image: '',
  visa_token_service_text: [{visa_token_paragraph: ''}],
  payout_management_title: '',
  payout_management_description: '',
  payout_management_list: [{payout_management_list_item: ''}],
  auto_currency_converter_title: '',
  auto_currency_converter_description: '',
  auto_currency_converter_image: '',
  payment_split_title: '',
  payment_split_description: '',
  payment_split_block_title: '',
  payment_split_block_description: '',
  cascading_block_title: '',
  cascading_block_description: '',
};

export const PaymentProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TPaymentTextData>(initialState);

  return (
    <PaymentContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </PaymentContext.Provider>
  );
};
