import {useCallback, useContext} from 'react';

import classNames from 'classnames';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './listMarkedBlock.module.scss';

const ListMarkedBlock: React.FunctionComponent = () => {
  const context = useContext(CaseSingleContext);

  const renderListItem = useCallback((item: any, index: number) => {
    return(
      <li className={styles.listItem} key={index}>{item.functionality_item}</li>
    );
  }, []);
  const renderList = useCallback((list: any, index: number) => {
    return(
      <div className={styles.list} key={index}>
        {list.map(renderListItem)}
      </div>
    );
  }, []);

  const renderLists = useCallback((list: Array<any>) => {
    return(
      <>
        {chunkArray(list, 7).map(renderList)}
      </>
    );
  },[]);

  const renderTabLists = useCallback((list: Array<any>) => {
    return(
      <>
        {chunkArray(list, 9).map(renderList)}
      </>
    );
  },[]);

  const chunkArray = (array: Array<{functionality_item: string}>, chunkSize: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  return(
    <div className={styles.container}>
      <div className={styles.title}>{context?.dataContext.key_functionality_title}</div>
      <div className={classNames(styles.wrap, styles.desk)}>
        {context?.dataContext.key_functionality_list &&
          renderLists(context?.dataContext.key_functionality_list)}
      </div>
      <div className={classNames(styles.wrap, styles.tab)}>
        {context?.dataContext.key_functionality_list &&
          renderTabLists(context?.dataContext.key_functionality_list)}
      </div>
    </div>
  );
};

export default ListMarkedBlock;
