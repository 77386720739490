import React, {useContext, useEffect, useState} from 'react';

import classNames from 'classnames';

import {AxiosResponse} from 'axios';

import {BlogContext} from '../../../context/BlogContext';

import {api} from '../../../api/config';

import BlogCard, {TBlogCardProps} from '../blogCard/BlogCard';

import Pagination from '../pagination/Pagination';

import styles from './blogContent.module.scss';

const INITIAL_CATEGORY = 'all-articles';

type TBlogContentData = {
  blog_posts: TBlogCardProps[],
}

type TCategoryData = {
  name: string;
  slug: string;
}

const BlogContent: React.FunctionComponent = () => {

  const getInitialCategory = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const category = urlSearchParams.get('category');

    return category || INITIAL_CATEGORY;
  };

  const [activeCategory, setActiveCategory] = useState(getInitialCategory());

  const [page, setPage] = useState(0);
  const [dataToDisplay, setDataToDisplay] = useState<TBlogCardProps[][]>([[
    {
      post_author: '',
      post_date: '',
      post_title: '',
      post_excerpt: '',
      post_name: '',
      thumbnail: '',
      template_type: 'BigBlack',
      categories: [{name: '', slug: ''}],
    },
  ]]);

  const context = useContext(BlogContext);


  const getBlogContent = async () => {
    try {
      const response: AxiosResponse<TBlogContentData> =
        await api(`/blog/category/${activeCategory}/`);

      setDataToDisplay(getDataToDisplay(response.data.blog_posts));
    } catch(err: any) {
      console.log(err);
    }

  };

  const getDataToDisplay = (arr: Array<TBlogCardProps>) => {

    const chunkSize = 10;
    const result = [];
    const length = arr.length;
    for (let i = 0; i < length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }

    return result;
  };

  useEffect(() => {
    getBlogContent();
  }, [activeCategory]);

  const renderListItem = (value: TCategoryData, index: number) => {

    const handleClick = (event: any) => {
      event.preventDefault(value.slug);
      setActiveCategory(value.slug);

      const queryParams = new URLSearchParams(window.location.search);

      queryParams.set('category', value.slug);
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;

      window.history.pushState(null, '', newUrl);
    };

    return(
      <li
        className={
          classNames(styles.categoryItem, (activeCategory === value.slug) && styles.active)
        }
        key={index}
        onClick={handleClick}
      >
        <a className={styles.categoryLink} href={`blog?category=${value.slug}`}>{value.name}</a>
      </li>
    );
  };

  const getFilterdCards = (cards: Array<TBlogCardProps>) => {
    const result = [];
    let smallGroup = [];

    const length = cards.length;

    for (let i = 0; i < length; i++) {
      if (cards[i].template_type === 'Small') {
        smallGroup.push(cards[i]);

        const nextIndex = i + 1;
        const hasNextSmall = nextIndex < cards.length && cards[nextIndex].template_type === 'Small';

        if (smallGroup.length === 2 || !hasNextSmall) {
          result.push(smallGroup);
          smallGroup = [];
        }
      } else {
        result.push(cards[i]);
      }
    }

    return result;
  };

  const renderCards = (cards: Array<TBlogCardProps>) => {

    const filteredCards = getFilterdCards(cards);

    return filteredCards.map((item, index) => {

      if(Array.isArray(item)) {
        return(
          <div className={styles.doubleWrap} key={index}>
            {item.map((smallCard, smallCardIndex) => {
              return <BlogCard {...smallCard as TBlogCardProps} key={smallCardIndex}/>;
            })}
          </div>
        );
      }

      return <BlogCard {...item as TBlogCardProps} key={index} />;
    });
  };

  return(
    <div className={styles.container}>
      <div className={styles.categorieswrap}>
        <div className={styles.categoriesList}>
          {context?.dataContext.categories.map(renderListItem)}
        </div>
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.cardsWrap}>
          {
            renderCards(dataToDisplay[page])
          }
        </div>
        {dataToDisplay.length > 1 && (
          <div className={styles.paginationWrap}>

            <Pagination pagesCount={dataToDisplay.length} activeIndex={page} onClick={setPage} />
          </div>

        )}
      </div>
    </div>
  );
};

export default BlogContent;
