import React, {ReactElement, useState, useEffect, ComponentType} from 'react';
import cn from 'classnames';

import styles from './slideShow.module.scss';

type SlideShowProps = {
  children?:
    | ReactElement<any, string | ComponentType<any>>
    | (ReactElement<any, string | ComponentType<any>> | string)[];
  className?: string;
  slidersLength: number;
};

export const SlideShow: React.FunctionComponent<SlideShowProps> = ({
  children,
  className,
  slidersLength,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slidersLength);
    }, 3000);

    return () => clearInterval(interval);
  }, [slidersLength]);

  const renderSlides = () => {
    return React.Children.map(children, (child, index) => {
      const isActive = index === activeIndex;
      return (
        <div className={cn(styles.slide, {[styles.active]: isActive}, className)}>
          {child}
        </div>
      );
    });
  };

  return <div className={styles.slider}>{renderSlides()}</div>;
};
