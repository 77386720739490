import React, {useContext, useEffect} from 'react';

import useApi from '../../api/useApi';
import {API_URLS} from '../../api/routes';

import {TFooterData, TMerchantManagementTextData} from '../../utils/types/pagesTypes';

import {MerchantManagementContext} from '../../context/MerchantManagementContext';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {Preloader} from '../../components/preloader/Preloader';

import imageHeader from '../../assets/images/merchant_management_tools_header.png';

import {FooterContext} from '../../context/FooterContext';

import SubscriptionManagements from './subscriptionManagements/SubscriptionManagements';
import TransactionManagements from './transactionManagements/TransactionManagements';
import ConfigurationProtectionTools
  from './configurationProtectionTools/ConfigurationProtectionTools';
import MerchantShopManagement from './merchantShopManagement/MerchantShopManagement';
import MerchantAccountManagement from './merchantAccountManagement/MerchantAccountManagement';
import ConfigurationPayment from './configurationPayment/ConfigurationPayment';

import styles from './merchantManagementTools.module.scss';

type TContactsData = {
  'merchant_group': TMerchantManagementTextData,
}

export const MerchantManagementTools: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TContactsData>(API_URLS.MERCHANT, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.MERCHANT}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(MerchantManagementContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['merchant_group']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock />
      <img className={styles.headerImage} src={imageHeader} alt=""/>
      <h1 className={styles.title}>{context?.dataContext.merchant_title}</h1>
      <MerchantAccountManagement />
      <MerchantShopManagement />
      <ConfigurationPayment />
      <ConfigurationProtectionTools />
      <TransactionManagements />
      <SubscriptionManagements />
    </div>
  );
};

export default MerchantManagementTools;
