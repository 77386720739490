import {FC} from 'react';

import cn from 'classnames';

import {CustomButton} from '../../../../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../../../../utils/constants/buttonVariantEnum';

import {isValidURL} from '../../../../../../utils/hooks/isValidUrl';

import styles from './descriptionsQuestionsWATQ.module.scss';

type Props = {
  question: {
    question_list_title: string,
    question_list_text: {
      question_list_text_item: string
    }[],
    need_list_articles: boolean,
    question_list_articles: {
      question_list_articles_text: string,
      question_list_articles_link: string,
    }[],
    need_link_in_bottom: boolean,
    question_list_paragraph: {
      question_list_paragraph_text: string,
      question_list_paragraph_link: string,
      question_list_paragraph_links_text: string,
    },
  },
};

export const DescriptionsQuestionsWATQ: FC<Props> = ({
  question,
}) => {
  const onClick = (link: string) => {
    if (isValidURL(link)) {
      window.open(link, '_blank');
    }
  };

  const renderLinkInBottom = (text: string, link: string, highlight: string) => {
    if (!highlight) {
      return;
    }
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

    return (
      <span>
        {parts.map((part, i) => {
          if(part.toLowerCase() === highlight.toLowerCase()) {
            return (<a
              key={i}
              className={styles.hightlightText}
              href={link}
              rel="noreferrer"
              target="_blank"
            >
              {part}
            </a>);
          }
          return (<span key={i} className={styles.text}>{part}</span>);
        })}
      </span>
    );
  };

  return (
    <div className={styles.container}>
      {question.question_list_text.map((item, index) => {
        return(<p className={styles.text} key={index}>
          {item.question_list_text_item}
        </p>);
      })}
      {question.need_list_articles &&
        question.question_list_articles?.map((value, index) => (
          <CustomButton
            key={index}
            className={cn(styles.text, styles.text__link)}
            onClick={() => {onClick(value.question_list_articles_link);}}
            variant={ButtonVariant.Redirect}
          >
            {value.question_list_articles_text}
          </CustomButton>
        ))
      }
      {question.need_link_in_bottom &&
        renderLinkInBottom(
          question.question_list_paragraph.question_list_paragraph_text,
          question.question_list_paragraph.question_list_paragraph_link,
          question.question_list_paragraph.question_list_paragraph_links_text,
        )
      }
    </div>
  );
};
