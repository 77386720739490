import {FC, useContext} from 'react';

import {AboutUsContext} from '../../../../context/AboutUsContext';

import styles from './welcomeBlockAboutUs.module.scss';

export const WelcomeBlockAboutUs: FC = () => {
  const context = useContext(AboutUsContext);
  return (
    <h1 className={styles.container}>
      {context?.dataContext.about_us_title}
    </h1>
  );
};
