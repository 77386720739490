import {useContext} from 'react';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';
import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './globalPaymentMethods.module.scss';

export const GlobalPaymentMethods = () => {
  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>
          {context?.dataContext.global_payment_title}
        </h3>
        <p className={styles.desc}>
          {context?.dataContext.global_payment_subtitle}
        </p>
        {context?.dataContext.global_payment_annotations.map((item, index) => {
          return(
            <p className={styles.annotations} key={index}>
              <HighlightText
                text={item.annotation}
                highlight={item.annotation_bold_text}
                className={styles.annotations}
                highlightClass={styles.annotations__bold} />
            </p>
          );
        })}
      </div>
      <img className={styles.img} src={context?.dataContext.global_payment_image} alt="" />
    </div>
  );
};
