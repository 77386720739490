import {FC} from 'react';

import {EmployeesWAOC} from './components/employeesWAOC/EmployeesWAOC';
import {AnnotationWAOC} from './components/annotationWAOC/AnnotationWAOC';
import styles from './whatAreOurCases.module.scss';

export const WhatAreOurCases: FC = () => {
  return (
    <div className={styles.container}>
      <EmployeesWAOC />
      <AnnotationWAOC />
    </div>
  );
};
