import React, {useContext} from 'react';

import {ReportAndBalanceContext} from '../../../context/ReportAndBalanceContext';

import styles from './balanceManagement.module.scss';

export const BalanceManagement = () => {
  const context = useContext(ReportAndBalanceContext);

  return (
    <div className={styles.balanceManagement}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{context?.dataContext.balance_management_title}</h3>
          <p className={styles.subTitle}>
            {context?.dataContext.balance_management_text_list[0].balance_management_text}
          </p>
        </div>
        <div className={styles.information}>
          <div className={styles.informationText}>
            {context?.dataContext.balance_management_text_list.map((item, index) => (
              <div key={index} className={styles.textItem}>
                <h4 className={styles.itemTitle}>{item.balance_management_bold_text}</h4>
                {item.need_list_in_text && item.balance_management_list_in_text ? (
                  <ol className={styles.itemList}>
                    {item.balance_management_list_in_text.map((text, subIndex) => (
                      <li key={subIndex} className={styles.itemListText}>
                        {text.balance_management_list_item}
                      </li>
                    ))}
                  </ol>
                ) : (
                  <p className={styles.itemtext}>{item.balance_management_bold_text}</p>
                )}
              </div>
            ))}
          </div>
          <img
            className={styles.image}
            src={context?.dataContext.balance_management_image}
            alt="Balance ManagementImage"
          />
        </div>
      </div>
      <div className={styles.bevelBlock}></div>
    </div>
  );
};
