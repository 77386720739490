import {FC, useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import picture from '../../assets/images/test.png';

import {Preloader} from '../../components/preloader/Preloader';

import {OfferYourMerchantsContext} from '../../context/OfferYourMerchantContexts';

import useApi from '../../api/useApi';

import {API_URLS} from '../../api/routes';

import {TFooterData, TOfferYourMerchantsTextData} from '../../utils/types/pagesTypes';

import {FooterContext} from '../../context/FooterContext';

import {WelcomeBlockOYM} from './components/welcomeBlockOYM/WelcomeBlockOYM';
import {
  CustomizedPaymentWidget,
} from './components/customizedPaymentWidget/CustomizedPaymentWidget';
import {HostedPaymentPage} from './components/hostedPaymentPage/HostedPaymentPage';
import {ShareablePaymentLinks} from './components/shareablePaymentLinks/ShareablePaymentLinks';
import {MobileApps} from './components/mobileApps/MobileApps';
import {TitlePageParagraphs} from './components/titlePageParagraphs/TitlePageParagraphs';
import {PluginsOYM} from './components/pluginsOYM/PluginsOYM';
import {GlobalPaymentMethods} from './components/globalPaymentMethods/GlobalPaymentMethods';
import {TransactionTypesOYM} from './components/transactionTypesOYM/TransactionTypesOYM';
import {MerchantBusiness} from './components/merchantBusiness/MerchantBusiness';
import {ProcessTransactionsOYM} from './components/processTransactionsOYM/ProcessTransactionsOYM';

import {IParagraph} from './config/config';

import styles from './offerYourMerchants.module.scss';

type TOfferYourMerchantsData = {
  'offer_your_merchants_fields': TOfferYourMerchantsTextData
}

const OfferYourMerchants: FC = () => {
  const {data, isLoading, error} =
    useApi<TOfferYourMerchantsData>(API_URLS.OFFER_YOUR_MERCHANTS, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.OFFER_YOUR_MERCHANTS}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(OfferYourMerchantsContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if(context && data) {
      context.setDataContext(data['offer_your_merchants_fields']);
    }
  }, [context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  const PARAGRAPHS: IParagraph[] = [
    {
      paragraph: 1,
      title: context?.dataContext.payment_widget_title as string,
      description: context?.dataContext.payment_widget_description as string,
      variant: 'white',
      annotations: context?.dataContext.payment_widget_annotation as string,
    },
    {
      paragraph: 2,
      title: context?.dataContext.hosted_payment_title as string,
      description: context?.dataContext.hosted_payment_subtitle as string,
      variant: 'black',
      annotations: context?.dataContext.hosted_payment_text as string,
    },
    {
      paragraph: 3,
      title: context?.dataContext.shareable_payment_title as string,
      description: context?.dataContext.shareable_payment_subtitle as string,
      variant: 'white',
      annotations: context?.dataContext.shareable_payment_annotation as string,
    },
    {
      paragraph: 4,
      title: context?.dataContext.mobile_apps_title as string,
      description: context?.dataContext.mobile_apps_subtitle as string,
      variant: 'white',
      annotations: context?.dataContext.mobile_apps_description as string,
    },
    {
      paragraph: 5,
      title: context?.dataContext.server_title as string,
      description: context?.dataContext.server_subtitle as string,
      variant: 'black',
      annotations: context?.dataContext.server_description as string,
    },
    {
      paragraph: 6,
      title: context?.dataContext.virtual_terminal_title as string,
      description: context?.dataContext.virtual_terminal_subtitle as string,
      variant: 'black',
      annotations: context?.dataContext.virtual_terminal_description as string,
    },
  ];

  return (
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <WelcomeBlockOYM />
      <CustomizedPaymentWidget pharagraphs={PARAGRAPHS} />
      <HostedPaymentPage paragraphs={PARAGRAPHS} />
      <div className={styles.SPLAndMAWrapper}>
        <div className={styles.SPLAndMAWrapper__block} />
        <ShareablePaymentLinks paragraphs={PARAGRAPHS} />
        <MobileApps paragraphs={PARAGRAPHS} />
      </div>
      <div className={styles.STSIAndVTWrapper}>
        <TitlePageParagraphs paragraphs={PARAGRAPHS} id={4} />
        <TitlePageParagraphs paragraphs={PARAGRAPHS} id={5} />
      </div>
      <PluginsOYM />
      <GlobalPaymentMethods />
      <div className={styles.transactionAndMerchantBusinessWrapper}>
        <div className={styles.transactionAndMerchantBusinessWrapper__block} />
        <TransactionTypesOYM />
        <MerchantBusiness />
      </div>
      <ProcessTransactionsOYM />
    </div>
  );
};

export default OfferYourMerchants;
