import React, {createContext, useState} from 'react';

import {TUseCasesTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataUseCasesProps {
  dataContext: TUseCasesTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TUseCasesTextData>>;
}

export const UseCasesContext = createContext<DataUseCasesProps | undefined>(undefined);

const initialState: TUseCasesTextData = {
  text_slides: [{
    title: '',
    title_orange: '',
    description: '',
  }],
  img_slides: [{
    img_title: '',
    img_description: '',
    image: '',
    image_link: '',
  }],
};

export const UseCasesProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TUseCasesTextData>(initialState);

  return (
    <UseCasesContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </UseCasesContext.Provider>
  );
};
