import {FC, useContext} from 'react';

import {CustomButton} from '../../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../../utils/constants/buttonVariantEnum';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';
import {isValidURL} from '../../../../utils/hooks/isValidUrl';

import styles from './pluginsOYM.module.scss';

export const PluginsOYM: FC = () => {

  const context = useContext(OfferYourMerchantsContext);

  const onClick = () => {
    if (isValidURL(context?.dataContext.simple_integration_link)) {
      window.open(context?.dataContext.simple_integration_link, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h3 className={styles.title}>
            {context?.dataContext.simple_integration_title}
          </h3>
          <div className={styles.desc}>
            {context?.dataContext.simple_integration_subtitle}
          </div>
          <p className={styles.annotation}>
            {context?.dataContext.simple_integration_description}
          </p>
          <CustomButton
            className={styles.btn}
            variant={ButtonVariant.Redirect}
            onClick={onClick}
          >
            {context?.dataContext.simple_integration_link_text}
          </CustomButton>
        </div>
        <div className={styles.imgWrapper}>
          <img
            className={styles.img}
            src={context?.dataContext.simple_integration_image}
            alt="logos"
          />
        </div>
      </div>
    </div>
  );
};
