import React, {useContext} from 'react';

import {CaseSingleContext} from '../../../context/CaseSingleContext';
import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './textColumnsBlock.module.scss';

const TextColumnsBlock: React.FunctionComponent = () => {
  const context = useContext(CaseSingleContext);
  return(
    <div className={styles.container}>
      <h2 className={styles.title}>{context?.dataContext.purchase_title}</h2>
      <div className={styles.textWrap}>
        {context?.dataContext.purchase_text_list.map((item, index) => {
          return(
            <div className={styles.textBlock} key={index}>
              <HighlightText
                text={item.purchase_text_item}
                highlight={item.purchase_orange_text}
                className={styles.textBlock}
                highlightClass={styles.orange}
                href={item.purchase_need_link ? item.purchase_link : undefined}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextColumnsBlock;
