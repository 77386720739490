import React from 'react';
import cn from 'classnames';

type Props = {
  text: string;
  highlight: string;
  className?: string;
  highlightClass: string;
  href?: string;
};

const HighlightText = ({
  text,
  highlight,
  className,
  highlightClass,
  href,
}: Props) => {
  if (!highlight) {
    return <span className={className}>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <span>
      {parts.map((part, i) => (
        <span key={i}>
          {
            href && part.toLowerCase() === highlight.toLowerCase()
              ?
              <a
                href={href}
                className={cn(className, {
                  [highlightClass]: part.toLowerCase() === highlight.toLowerCase(),
                })}
              >
                {part}
              </a>
              :
              <span
                className={cn(className, {
                  [highlightClass]: part.toLowerCase() === highlight.toLowerCase(),
                })}
              >
                {part}
              </span>
          }
        </span>
      ))}
    </span>
  );
};

export default React.memo(HighlightText);
