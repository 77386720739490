import {useContext} from 'react';

import {BlogContext} from '../../../context/BlogContext';

import styles from './heroBlock.module.scss';

const HeroBlock: React.FunctionComponent = () => {
  const context = useContext(BlogContext);
  return(
    <div className={styles.container}>

      <div className={styles.wrap}>
        <h1 className={styles.title}>{context?.dataContext.blog_data.blog_title}</h1>
        <p className={styles.descr}>{context?.dataContext.blog_data.blog_subtitle}</p>
      </div>
    </div>
  );
};

export default HeroBlock;
