
import {useContext, useState} from 'react';
import cn from 'classnames';

import HighlightText from '../../../../components/highlightText/HighlightText';

import {ReportAndBalanceContext} from '../../../../context/ReportAndBalanceContext';

import DesktopSlide from './DesktopSlide';

import styles from './carousel.module.scss';

export const Carousel = () => {
  const context = useContext(ReportAndBalanceContext);
  const [activeSlide, setActiveSlide] = useState(0);

  console.log();

  return (
    <div className={styles.carousel}>
      <div className={styles.leftPart}>
        <div className={styles.mainTitle}>
          <HighlightText
            text={context?.dataContext.statistical_reports_title as string}
            highlight={context?.dataContext.statistical_reports_title_orange as string}
            className={styles.highlightText}
            highlightClass={styles.orange}
          />
        </div>
        <p className={styles.description}>{context?.dataContext.statistical_reports_subtitle}</p>
        <img
          src={context?.dataContext.statistical_reports_carousel[activeSlide].carousel_image}
          className={styles.mainImageSlide}
          alt=""
        />
      </div>
      <div className={styles.rightPart}>
        {context?.dataContext.statistical_reports_carousel
          .map((item, index) => (
            <DesktopSlide key={index} index={index} {...item} changeActiveIndex={setActiveSlide} />
          ))}
      </div>
      <div className={styles.indicator}>
        {context?.dataContext.statistical_reports_carousel
          .map((item, index) => (
            <div key={`ind_${index}`}
              className={cn(styles.indicate, {[styles.active]: index === activeSlide})} />
          ))}
      </div>
    </div>
  );
};
