import React, {createContext, useState} from 'react';

import {TIntegrationsTextData} from '../utils/types/pagesTypes';


interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TIntegrationsTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TIntegrationsTextData>>;
}

export const IntegrationsContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TIntegrationsTextData = {
  integrations_title: '',
  integrations_text: '',
  launch_faster_title: '',
  launch_faster_title_orange: '',
  launch_faster_ol: [{
    launch_faster_li: '',
  }],
  accept_payments_title: '',
  accept_payments_text: '',
  cards_title: '',
  cards_images: [{
    cards_image: false,
  }],
  wallet_title: '',
  wallet_images: false,
  wallet_text: '',
  wallet_text_orange: '',
  wallet_text_orange_link: '',
  alternative_payment_title: '',
  alternative_payment_images: false,
  alternative_payment_text: '',
  alternative_payment_text_orange: '',
  alternative_payment_text_orange_link: '',
  bank_transfers_title: '',
  bank_transfers_images: [{
    bank_transfers_image: false,
  }],
  bank_transfers_text: '',
  bank_transfers_text_orange: '',
  bank_transfers_text_orange_link: '',
  offer_your_merchants_title: '',
  offer_your_merchants_subtitle: '',
  offer_your_merchants_image: '',
  shopping_carts_plugins_title: '',
  shopping_carts_plugins_list: [{
    carts_plugin: '',
  }],
  builders_and_crm_title: '',
  builders_and_crm_list: [{
    builders_and_crm: '',
  }],
};

export const IntegrationsProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TIntegrationsTextData>(initialState);

  return (
    <IntegrationsContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </IntegrationsContext.Provider>
  );
};
