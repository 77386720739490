import React, {useContext} from 'react';

import {PricingOptionsContext} from '../../../context/PricingOptionsContext';

import styles from './mainStepsBlock.module.scss';

export const MainStepsBlock: React.FunctionComponent = () => {
  const context = useContext(PricingOptionsContext);

  return (
    <div className={styles.mainStepsBlock}>
      <h3 className={styles.title}>{context?.dataContext.steps_block_title}</h3>
      <div className={styles.stepsContainer}>
        <div className={styles.line}></div>
        {context?.dataContext.steps_block_list.map((item, index) => {
          const isLastItem = index === context?.dataContext.steps_block_list.length - 1;
          return (
            <div className={styles.stepItem} key={index}>
              <div
                className={`${styles.stepСircle} ${
                  isLastItem ? styles.lastCircle : ''
                }`}
              >
                {index + 1}
              </div>
              <div className={styles.stepText}>{item.steps_block_list_item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainStepsBlock;
