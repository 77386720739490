import React, {createContext, useState} from 'react';

import {TPrivacyPolicyTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TPrivacyPolicyTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TPrivacyPolicyTextData>>;
}

export const PrivacyPolicyContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TPrivacyPolicyTextData = {
  privacy_policy_title: '',
  general_provisions_title: '',
  general_provisions_ol: [{general_provisions_li: ''}],
  personal_data_title: '',
  personal_data_ol: [{personal_data_li: ''}],
  access_to_personal_data_title: '',
  access_to_personal_data_ol: [{access_to_personal_data_li: ''}],
  processing_data_title: '',
  processing_data_text: '',
  third_party_title: '',
  third_party_text: '',
  logotype_title: '',
  logotype_text: '',
  ensuring_information_title: '',
  ensuring_information_text: '',
  final_provisions_title: '',
  final_provisions_ol: [{final_provisions_li: ''}],
};

export const PrivacyPolicyProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TPrivacyPolicyTextData>(initialState);

  return (
    <PrivacyPolicyContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </PrivacyPolicyContext.Provider>
  );
};
