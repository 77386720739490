import React, {useContext} from 'react';

import {HomeContext} from '../../../context/HomeContext';

import styles from './saveTimeAndResourcesBlock.module.scss';
import
SaveTimeBlockInformation,
{ItemInformation}
  from './saveTimeBlockInformation/SaveTimeBlockInformation';


export const SaveTimeAndResourcesBlock: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const itemIformation: ItemInformation[] = [
    {
      id: 1,
      img: context?.dataContext.save_your_time_image,
      title: context?.dataContext.save_time_information_block_title,
      subTitle: context?.dataContext.save_time_information_block_subtitle,
      text: context?.dataContext.save_time_information_block_text,
      optionList: {
        listTitle: context?.dataContext.save_time_information_block_list_title,
        options: context?.dataContext.save_time_information_block_list,
      },
      textBottom: context?.dataContext.save_time_information_block_bottom_text,
      buttontext: context?.dataContext.save_time_information_block_button_text,
      buttonLink: context?.dataContext.save_time_information_block_button_link,
    },
    {
      id: 2,
      img: context?.dataContext.developers_team_block_image,
      title: context?.dataContext.developers_team_block_title,
      subTitle: context?.dataContext.developers_team_block_subtitle,
      text: context?.dataContext.developers_team_block_text,
      list: context?.dataContext.developers_team_block_text_list,
      textBottom: context?.dataContext.developers_team_block_bottom_text,
      buttontext: context?.dataContext.developers_team_block_button_text,
      buttonLink: context?.dataContext.developers_team_block_button_link,
    },
    {
      id: 3,
      img: context?.dataContext.technical_support_block_image,
      title: context?.dataContext.technical_support_block_title,
      subTitle: context?.dataContext.technical_support_block_subtitle,
      text: context?.dataContext.technical_support_block_text,
      textBottom: context?.dataContext.technical_support_block_bottom_text,
      buttontext: context?.dataContext.technical_support_block_button_text,
      buttonLink: context?.dataContext.technical_support_block_button_link,
    },
  ];

  return (
    <div className={styles.cotainer}>
      <div className={styles.content}>
        <h3 className={styles.title}>
          {context?.dataContext.save_your_time_title}
        </h3>
        <p className={styles.subTitle}>
          {context?.dataContext.save_your_time_subtitle}
        </p>
      </div>
      <SaveTimeBlockInformation itemInformation={itemIformation} />
    </div>
  );
};

export default SaveTimeAndResourcesBlock;
