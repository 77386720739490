import {useContext} from 'react';

import classNames from 'classnames';

import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import HighlightText from '../../../components/highlightText/HighlightText';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './heroBlock.module.scss';

const HeroBlock: React.FunctionComponent = () => {
  const context = useContext(CaseSingleContext);
  return(
    <div className={
      classNames(styles.container, context?.dataContext.breadcrumbs && styles.hasBreadcrumbs)
    }>
      {context?.dataContext.breadcrumbs &&
        <Breadcrumbs crumbs={context?.dataContext.breadcrumbs} />
      }
      <div className={styles.wrap}>

        <h1 className={styles.title}>{context?.dataContext.case_title}</h1>
        <HighlightText
          text={
            context?.dataContext.case_description as string
          }
          highlight={''}
          className={styles.descr}
          highlightClass={styles.black}
        />
      </div>
    </div>
  );
};

export default HeroBlock;
