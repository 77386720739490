import React, {useCallback, useContext} from 'react';

import {PrivacyPolicyContext} from '../../../context/PrivacyPolicyContext';

import styles from './personalDataManagement.module.scss';

export const PersonalDataManagement: React.FunctionComponent = () => {
  const context = useContext(PrivacyPolicyContext);

  const splitArray = (arr?: Array<any>) => {
    if(!arr) return;
    const firstCol = arr.slice(0, 5);
    const secondCol = arr.slice(5, 10);

    return [firstCol, secondCol];
  };

  const splited = splitArray(context?.dataContext.personal_data_ol);

  const renderCol = useCallback((item: any, index: number) => {
    return(
      <div className={styles.col} key={index}>
        {item.map((listItem: any, itemIndex: number) => {
          return (
            <div className={styles.listItem} key={itemIndex}>
              <span className={styles.number}>{`${(index * 5) + itemIndex + 1}.`}</span>
              {`${listItem.personal_data_li}`}
            </div>
          );
        })}
      </div>
    );
  }, []);

  return (
    <div className={styles.personalDataManagement}>
      <h2 className={styles.title}>{context?.dataContext.personal_data_title}</h2>
      <div className={styles.listContainer}>
        {
          splited?.map(renderCol)
        }
      </div>
    </div>
  );
};

export default PersonalDataManagement;
