import React, {createContext, useState} from 'react';

import {TMerchantManagementTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TMerchantManagementTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TMerchantManagementTextData>>;
}

export const MerchantManagementContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TMerchantManagementTextData = {
  merchant_title: '',
  main_content_title: '',
  main_content_description: '',
  merchant_account_title: '',
  merchant_account_image: '',
  merchant_account_text_list: [{
    merchant_account_text_item: '',
    merchant_account_text_need_list: false,
    merchant_account_list_in_text: [{merchant_account_list_item_in_text: ''}],
  }],
  merchant_shop_title: '',
  merchant_shop_image_carousel: [{merchant_shop_image: ''}],
  merchant_shop_text_list: [{
    merchant_shop_text_item: '',
    merchant_shop_text_need_list: false,
    merchant_shop_list_in_text: [{merchant_shop_list_item_in_text: ''}],
  }],
  mockdata_title: '',
  mockdata_description: [{mockdata_text: ''}],
  mockdata_tabs: [{
    img: '',
    triggertext: '',
  }],
  configuration_of_fraud_title: '',
  configuration_of_fraud_orange_text: '',
  configuration_of_fraud_subtitle: '',
  configuration_of_fraud_image_carousel: [{configuration_of_fraud_image: ''}],
  configuration_of_fraud_text_list: [{
    configuration_of_fraud_text_item: '',
    configuration_of_fraud_text_need_list: false,
    configuration_of_fraud_list_in_text:
        [{configuration_of_fraud_list_item_in_text: ''}],
  }],
  transaction_managements_title: '',
  transaction_managements_description: '',
  transaction_managements_carousel_title: '',
  transaction_managements_image_carousel: [{transaction_managements_image: ''}],
  transaction_managements_text_list: [{
    transaction_managements_text_item: '',
    transaction_managements_text_need_list: false,
    transaction_managements_list_in_text:
        [{transaction_managements_list_item_in_text: ''}],
  }],
  transaction_managements_carousel: [{
    transaction_managements_item_icon: '',
    transaction_managements_item_title: '',
    transaction_managements_item_description: '',
  }],
  subscription_managements_title: '',
  subscription_managements_image: '',
  subscription_managements_text_list: [{
    subscription_managements_text_item: '',
    subscription_managements_text_need_list: false,
    subscription_managements_list_in_text:
        [{subscription_managements_list_item_in_text: ''}],
  }],
};

export const MerchantManagementProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TMerchantManagementTextData>(initialState);

  return (
    <MerchantManagementContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </MerchantManagementContext.Provider>
  );
};
