import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {CustomButton} from '../../components/customButton/CustomButton';
import {Preloader} from '../../components/preloader/Preloader';
import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';
import {PATH} from '../../utils/constants/routeConstants';
import {API_URLS} from '../../api/routes';
import useApi from '../../api/useApi';

import errorPicture from '../../assets/images/404picture.png';

import {FooterContext} from '../../context/FooterContext';
import {TFooterData} from '../../utils/types/pagesTypes';

import styles from './page404.module.scss';

type TPage404Data = {
  '404_fields': TPage404TextData,
}

type TPage404TextData = {
  title: string,
  subtitle: string,
  button_text: string,
  button_link: string
}

export const Page404: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const {data, isLoading, error} =
    useApi<TPage404Data>(API_URLS.NOT_FOUND, {method: 'GET'});

  const onClick = () => {
    navigate(PATH.home);
  };

  const footer =
    useApi<TFooterData>(`${API_URLS.OFFER_YOUR_MERCHANTS}${API_URLS.FOOTER}`, {method: 'GET'});

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  const {title, subtitle, button_text} = data['404_fields'];

  return (
    <div className={styles.container}>
      <HeaderBlock>
        <img className={styles.img} alt="" src={errorPicture}/>
      </HeaderBlock>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.description}>
          {subtitle}
        </div>
        <CustomButton variant={ButtonVariant.Primary} className={styles.btn} onClick={onClick}>
          {button_text}
        </CustomButton>
      </div>
    </div>
  );
};

export default Page404;
