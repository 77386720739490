import React, {FC} from 'react';

import {TitlePageParagraphs} from '../titlePageParagraphs/TitlePageParagraphs';
import {SliderCPW} from '../sliderCPW/SliderCPW';

import {IParagraph} from '../../config/config';

import styles from './customizedPaymentWidget.module.scss';

type TCustomizedPaymentWidgetProps = {
  pharagraphs: IParagraph[]
}

export const CustomizedPaymentWidget: FC<TCustomizedPaymentWidgetProps> = ({pharagraphs}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TitlePageParagraphs
          paragraphs={pharagraphs}
          key={pharagraphs[0].paragraph}
          id={0}
        />
        <SliderCPW />
      </div>
    </div>
  );
};
