import {FC} from 'react';

import {IWhatAreOurCaseItem} from '../../EmployeesWAOC';

import {isValidURL} from '../../../../../../../../utils/hooks/isValidUrl';

import styles from './employeeWAOC.module.scss';

interface EmployeeeWAOCProps extends IWhatAreOurCaseItem {}
export const EmployeeWAOC: FC<EmployeeeWAOCProps> = ({
  our_cases_list_title,
  our_cases_list_image,
  our_cases_list_link,
}) => {
  const onClick = () => {
    if (isValidURL(our_cases_list_link)) {
      window.open(our_cases_list_link, '_blank');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {our_cases_list_title}
      </div>
      <img className={styles.img} src={our_cases_list_image} alt={our_cases_list_title} />
      <div onClick={onClick} className={styles.link}>
        {'Read Case'}
      </div>
    </div>
  );
};
