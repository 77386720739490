import {useContext, useEffect} from 'react';


import {Preloader} from '../../components/preloader/Preloader';
import {TBlogTextData, TFooterData} from '../../utils/types/pagesTypes';


import picture from '../../assets/images/sitemapPicture.png';
import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import {API_URLS} from '../../api/routes';
import useApi from '../../api/useApi';
import {BlogContext} from '../../context/BlogContext';

import {FooterContext} from '../../context/FooterContext';

import styles from './blog.module.scss';
import HeroBlock from './heroBlock/HeroBlock';
import BlogContent from './blogContent/BlogContent';



const Blog: React.FunctionComponent = () => {

  const {data, isLoading, error} =
    useApi<TBlogTextData>(API_URLS.BLOG, {method: 'GET'});

  const context = useContext(BlogContext);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data);
    }
  },[context, data]);

  const footer =
    useApi<TFooterData>(`${API_URLS.BLOG}${API_URLS.FOOTER}`, {method: 'GET'});

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (FooterContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return(
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <HeroBlock />
      <BlogContent />
    </div>
  );
};

export default Blog;
