import {FC, useContext} from 'react';

import {AboutUsContext} from '../../../../../../context/AboutUsContext';

import styles from './employeesWAOC.module.scss';
import {EmployeeWAOC} from './componets/employeeWAOC/EmployeeWAOC';

export interface IWhatAreOurCaseItem {
  our_cases_list_title: string;
  our_cases_list_image: string;
  our_cases_list_link: string;
}

export const EmployeesWAOC: FC = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      {context?.dataContext.our_cases_list.map((value) => (
        <EmployeeWAOC key={value.our_cases_list_title} {...value} />
      ))}
    </div>
  );
};
