import {FC, useContext} from 'react';

import picture from '../../../../../../assets/images/bigFive.png';

import HighlightText from '../../../../../../components/highlightText/HighlightText';
import {AboutUsContext} from '../../../../../../context/AboutUsContext';

import styles from './annotationWAOC.module.scss';

export const AnnotationWAOC: FC = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <img className={styles.img} src={picture} alt="5 paragraph" />
        <h1 className={styles.title}>
          <HighlightText
            text={context?.dataContext.our_cases_title as string}
            highlight={context?.dataContext.our_cases_title_bold as string}
            className={styles.title}
            highlightClass={styles.boldText}
          />
        </h1>
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.description}>
          {context?.dataContext.our_cases_subtitle}
        </div>
        <div className={styles.descriptionItems}>
          {context?.dataContext.our_cases_text_list.map((item, index) => (
            <div key={index} className={styles.descriptionItem}>
              <div className={styles.descriptionItem__point} />
              <div className={styles.descriptionItem__text}>{item.our_cases_text_list_item}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
