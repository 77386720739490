import {useContext, useState} from 'react';

import cn from 'classnames';

import {ReportAndBalanceContext} from '../../../context/ReportAndBalanceContext';

import styles from './transactionReports.module.scss';

type TTabData = {
  title: string,
  description: string,
  image: string,
};

export const TransactionReports = () => {
  const context = useContext(ReportAndBalanceContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const tabItems: TTabData[] = [{
    title: context?.dataContext.transaction_reports_title as string,
    description: context?.dataContext.transaction_reports_text as string,
    image: context?.dataContext.transaction_reports_image as string,
  },
  {
    title: context?.dataContext.risk_reports_title as string,
    description: context?.dataContext.risk_reports_text as string,
    image: context?.dataContext.risk_reports_image as string,
  },
  ];

  const renderTabTriggers = (item: TTabData, index: number) => {
    const handleClick = () => {
      setActiveIndex(index);
    };

    const itemClasses = cn(
      styles.tabItem,
      activeIndex === index && styles.active,
    );

    return(
      <div className={itemClasses} onClick={handleClick} key={index}>
        <h3 className={styles.title}>
          {item.title}
        </h3>
        <p className={styles.descr}>
          {item.description}
        </p>
        <div className={styles.tabImg}>
          <img src={tabItems[index].image} alt="" />
        </div>
      </div>
    );
  };

  return(
    <div className={styles.transactionReports}>
      <div className={styles.container}>
        <div className={styles.contentWrap}>
          <div className={styles.imgWrap}>
            <img src={tabItems[activeIndex].image} alt="" />
          </div>
          <div className={styles.tabsWrap}>
            {tabItems.map(renderTabTriggers)}
          </div>
        </div>
      </div>
      <div className={styles.rect}></div>
    </div>
  );
};
