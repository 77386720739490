import React, {useContext} from 'react';

import {Slider} from '../../../../components/slider/Slider';
import {VerticalSlider} from '../../../../components/verticalSlider/VerticalSlider';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import {SliderItemListVariant} from './components/sliderItemListVariant/SliderItemListVariant';

import styles from './sliderCPW.module.scss';

export const SliderCPW = () => {

  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <div className={styles.sliderTablet}>
        {context?.dataContext.payment_widget_carousel &&
          <Slider classNameStepper={styles.stepper}>
            {context?.dataContext.payment_widget_carousel.map(
              (item) =>
                <SliderCPW.ListVariant key={item.item_image} {...item} />,
            )}
          </Slider>}
      </div>
      <div className={styles.sliderDesktop}>
        {context?.dataContext.payment_widget_carousel &&
          <VerticalSlider classNameStepper={styles.stepper}>
            {context?.dataContext.payment_widget_carousel.map(
              (item) =>
                <SliderCPW.ListVariant key={item.item_image} {...item} />,
            )}
          </VerticalSlider>}
      </div>
    </div>
  );
};

SliderCPW.ListVariant = SliderItemListVariant;
