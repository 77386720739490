import {useContext} from 'react';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './cardsBlock.module.scss';

const CardsBlock: React.FunctionComponent = () => {

  const context = useContext(CaseSingleContext);

  return(
    <div className={styles.container}>
      <div className={styles.wrap}>
        {context?.dataContext.cards.map((card, id) => {
          const handleClick = () => {
            if(isValidURL(card.card_link)) {
              window.open(card.card_link, '_blank');
            }
          };
          return(
            <div className={styles.card} key={id}>
              <div className={styles.contentWrap}>
                <div className={styles.cardIcon}>
                  <img src={card.card_ico} alt="" />
                </div>
                <div className={styles.cardTitle}>{card.card_title}</div>
                <div className={styles.cardDescr}>{card.card_subtitle}</div>
              </div>
              <div className={styles.btn} onClick={handleClick}>{card.card_button_text}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardsBlock;
