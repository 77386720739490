import React, {useContext} from 'react';


import PrivacyPolicyLists from '../../../components/privacyPolicyLists/PrivacyPolicyLists';
import Table, {TableData} from '../../../components/table/Table';

import {ForDevelopersContext} from '../../../context/DevelopersContext';

import styles from './minHardwareBlock.module.scss';


const headers = [
  {id: 'host', text: 'Host'},
  {id: 'cpu', text: 'CPU'},
  {id: 'ram', text: 'RAM'},
  {id: 'hdd', text: 'HDD'},
];

export const MinHardwareBlock: React.FunctionComponent = () => {

  const context = useContext(ForDevelopersContext);

  return (
    <div className={styles.minHardwareBlock}>
      <div className={styles.constent}>
        <h3 className={styles.title}>
          {context?.dataContext.system_requirements_title}
        </h3>
        <p className={styles.text}>
          {context?.dataContext.system_requirements_subtitle}
        </p>
        <Table
          headers={headers}
          rows={context?.dataContext.system_requirements_tab as TableData[]}
          className={styles.table}
        />
        <p className={styles.tableText}>
          {context?.dataContext.system_requirements_aftertab_text}
        </p>
        <ol className={styles.listContainer}>
          {context?.dataContext.system_requirements_aftertab_list.map((item, index) => (
            <PrivacyPolicyLists
              key={index}
              text={item.system_requirements_aftertab_list_item}
              className={styles.listItem}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

export default MinHardwareBlock;
