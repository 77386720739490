import {createContext, useState} from 'react';

import {TFooterTextData} from '../utils/types/pagesTypes';

interface Props {
    children?: JSX.Element
}

interface DataContextProps {
  dataContext: TFooterTextData;
  setDataContext: (data: TFooterTextData) => void;
}

export const FooterContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TFooterTextData = {
  ready_to_get_title: '',
  ready_to_get_subtitle: '',
  ready_to_get_button_text: '',
  ready_to_get_button_link: '',
};

export const FooterProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setData] = useState<TFooterTextData>(initialState);

  const setDataContext = (newData: TFooterTextData) => {
    if (newData) {
      setData(newData);
    }
  };

  return (
    <FooterContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </FooterContext.Provider>
  );
};
