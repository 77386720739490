import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';
import contactsImg from '../../assets/images/2_project.png';

import useApi from '../../api/useApi';

import {API_URLS} from '../../api/routes';

import {TForDevelopersTextData} from '../../utils/types/pagesTypes';

import {ForDevelopersContext} from '../../context/DevelopersContext';

import {Preloader} from '../../components/preloader/Preloader';

import QuestionsBlock from './questionsBlock/QuestionsBlock';
import MainPoints from './mainPoints/MainPoints';
import MinHardwareBlock from './minHardwareBlock/MinHardwareBlock';
import MerchantAPIReference from './merchantAPIReference/MerchantAPIReference';
import PspApiReference from './pspApiReference/PspApiReference';

import styles from './forDevelopers.module.scss';

type TForDevelopersData = {
  'developers_fields': TForDevelopersTextData;
}

export const ForDevelopers: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<TForDevelopersData>(API_URLS.FOR_DEVELOPERS, {method: 'GET'});

  const context = useContext(ForDevelopersContext);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['developers_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={contactsImg} />
      <h1 className={styles.globalTitle}>{context?.dataContext.title}</h1>
      <MerchantAPIReference />
      <MainPoints />
      <PspApiReference />
      <MinHardwareBlock />
      <QuestionsBlock />
    </div>
  );
};

export default ForDevelopers;
