import {FC, useContext} from 'react';
import {Carousel} from '@mantine/carousel';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import {SliderItemTTOYM} from './components/sliderItemTTOYM/SliderItemTTOYM';


import styles from './transactionTypesOYM.module.scss';

export const TransactionTypesOYM: FC = () => {

  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        {context?.dataContext.enable_revenue_title}
      </h3>
      <p className={styles.desc}>
        {context?.dataContext.enable_revenue_subtitle}
      </p>
      <div className={styles.slider}>
        <Carousel
          withControls={false}
          initialSlide={0}
          align={'start'}
          loop
        >
          {context?.dataContext.enable_revenue_carousel.map(
            (items, index) => {
              return(<SliderItemTTOYM key={index} {...items} />);
            })
          }
        </Carousel>
      </div>
    </div>
  );
};
