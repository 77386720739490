import {useContext} from 'react';

import {PaymentContext} from '../../../../context/PaymentContext';

import styles from './ImgTextBlock.module.scss';


const ImgTextBlock: React.FunctionComponent = () => {

  const context = useContext(PaymentContext);

  return(
    <div className={styles.container}>
      <div className={styles.imgTextBlockWrap}>
        <div className={styles.imgWrap}>
          <img src={context?.dataContext.auto_currency_converter_image} alt="" />
        </div>
        <div className={styles.textWrap}>
          <h2 className={styles.title}>{context?.dataContext.auto_currency_converter_title}</h2>
          <div className={styles.descr}>
            {context?.dataContext.auto_currency_converter_description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgTextBlock;
