import React, {useContext} from 'react';

import {PricingOptionsContext} from '../../../../context/PricingOptionsContext';

import styles from './tableBlock.module.scss';

export const TableBlock: React.FC = () => {
  const context = useContext(PricingOptionsContext);

  return (
    <div className={styles.table}>
      <div className={styles.tableHead}>
        <div className={styles.headItem}>{context?.dataContext.existing_location_head_title}</div>
        <div className={styles.headItem}>{context?.dataContext.new_server_head_title}</div>
      </div>
      <div className={styles.tableBody}>
        {context?.dataContext.table_row.map((item, index) => (
          <div className={styles.tableRow} key={index}>
            <div className={styles.rowTitle}>
              <p className={styles.title}>{item.table_row_title}</p>
              <p className={styles.subTitle}>{item.table_row_subtitle}</p>
            </div>
            <div className={styles.rowTextBlocks}>
              <div className={styles.rowTextItem}>
                <div className={styles.priceBlock}>
                  <p className={styles.priceTitle}>{item.existing_location_price_title}</p>
                  {item.need_existing_location_orange_subtitle &&
                    <p className={styles.dataPrice}>{item.existing_location_orange_subtitle}</p>
                  }
                </div>
                {item.need_existing_location_text &&
                  <p className={styles.priceText}>
                    {item.existing_location_text}
                  </p>
                }
                {item.need_existing_location_text_list &&
                  <div className={styles.blockList}>
                    <p className={styles.listTitle}>{item.existing_location_text_list_title}</p>
                    <ol className={styles.listBlock}>
                      {item.existing_location_text_list.map((el, index) => {
                        return <li key={index}>{el.existing_location_text_list_item}</li>;
                      })}
                    </ol>
                  </div>
                }
              </div>
              <div className={styles.rowTextItem}>
                <div className={styles.priceBlock}>
                  <p className={styles.priceTitle}>{item.new_server_price_title}</p>
                  {item.need_new_server_price_orange_subtitle &&
                    <p className={styles.dataPrice}>{item.new_server_price_orange_subtitle}</p>
                  }
                </div>
                {item.need_new_server_price_text &&
                  <p className={styles.priceText}>{item.new_server_text}</p>
                }
                {item.need_new_server_text_list &&
                  <div className={styles.blockList}>
                    <p className={styles.listTitle}>{item.new_server_text_list_title}</p>
                    <ol className={styles.listBlock}>
                      {item.new_server_text_list.map((el, index) => {
                        return <li key={index}>{el.new_server_text_list_item}</li>;
                      })}
                    </ol>
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableBlock;
