import React, {useContext} from 'react';

import {IntegrationsContext} from '../../../../context/IntegrationsContext';

import {TechList} from './componets/techList/TechList';


import styles from './offerMerchants.module.scss';

export const OfferMerchants = () => {

  const context = useContext(IntegrationsContext);

  const shopingList = context?.dataContext.shopping_carts_plugins_list.map((item) => {
    return item.carts_plugin;
  });
  const builders = context?.dataContext.builders_and_crm_list.map((item) => {
    return item.builders_and_crm;
  });

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>
            {context?.dataContext.offer_your_merchants_title}
          </h1>
          <p className={styles.description}>
            {context?.dataContext.offer_your_merchants_subtitle}
          </p>
        </div>
        <img
          alt="logos"
          src={context?.dataContext.offer_your_merchants_image}
          className={styles.logosImg}
        />
      </div>
      <TechList
        className={styles.shoppingCards}
        list={shopingList}
        title={context?.dataContext.shopping_carts_plugins_title as string}
      />
      <TechList
        className={styles.integrations}
        list={builders}
        title={context?.dataContext.builders_and_crm_title as string}
      />
    </div>
  );
};
