import {FC} from 'react';

import classNames from 'classnames';

import {ReactComponent as Arrow} from '../../../assets/icons/arrowRightGrey.svg';

import styles from './pagination.module.scss';

type TPaginationProps = {
  pagesCount: number;
  activeIndex: number;
  onClick: (number: number) => void;
}

type TPagination = FC<TPaginationProps>;

const Pagination: TPagination = ({
  pagesCount,
  activeIndex,
  onClick,
}) => {

  const renderPagination = () => {
    const elements = [];


    for(let i = 0; i < pagesCount; i++) {
      const handleClick = () => {
        onClick(i);
      };

      elements.push(
        <div className={
          classNames(styles.pagItem, activeIndex === i && styles.active)
        }
        onClick={handleClick}
        key={i}>
          {i + 1}
        </div>,
      );
    };

    return elements;
  };

  return(
    <div className={styles.pagination}>
      {activeIndex !== 0 && (
        <div className={styles.pagItem}
          style={{transform: 'scaleX(-1)'}}
          onClick={() => {
            onClick(0);
          }}>
          <Arrow/>
        </div>
      )}
      {renderPagination()}
      {activeIndex !== pagesCount - 1 && (
        <div className={styles.pagItem}
          onClick={() => {
            onClick(pagesCount - 1);
          }}>
          <Arrow/>
        </div>
      )}
    </div>
  );
};

export default Pagination;
