import React, {useContext} from 'react';

import {PricingOptionsContext} from '../../../context/PricingOptionsContext';

import styles from './pricingOptionsBlock.module.scss';
import TableBlock from './table/TableBlock';

export const PricingOptionsBlock: React.FunctionComponent = () => {
  const context = useContext(PricingOptionsContext);

  return (
    <div className={styles.mainStepsBlock}>
      <div className={styles.comtent}>
        <div className={styles.textBlock}>
          <h1 className={styles.title}>{context?.dataContext.pricing_title}</h1>
          <p className={styles.text}>{context?.dataContext.pricing_subtitle}</p>
        </div>
        <div className={styles.tableBlock}>
          <TableBlock/>
        </div>
      </div>
      <div className={styles.beveledBlock}></div>
    </div>
  );
};

export default PricingOptionsBlock;
