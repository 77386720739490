import React, {createContext, useState} from 'react';

import {TContactsTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TContactsTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TContactsTextData>>;
}

export const ContactsContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TContactsTextData = {
  contacts_title: '',
  contacts_subtitle: '',
  contact_sales_title: '',
  contact_sales_subtitle: '',
  contact_sales_button_text: '',
  contact_sales_button_link: '',
  book_a_demo_title: '',
  book_a_demo_subtitle: '',
  book_a_demo_button_text: '',
  book_a_demo_button_link: '',
  other_form_title: '',
  other_form_info: [{
    other_info_image: '',
    other_info_text: '',
    other_info_link: '',
    other_info_link_underline: false,
  }],
  contacts_subtitle_for_current: '',
  get_technical_support_title: '',
  get_technical_support_subtitle: '',
  get_technical_support_button_text: '',
  get_technical_support_button_link: '',
  new_development_title: '',
  new_development_subtitle: '',
  new_development_button_text: '',
  new_development_button_link: '',
  other_form_contact_title: '',
  other_form_contact_info: [{
    other_contact_info_image: '',
    other_contact_info_text: '',
    other_contact_info_link: '',
    other_contact_info_link_underline: false,
  }],
  visit_our_office_title: '',
  visit_our_office_subtitle: '',
  visit_our_office_info: '',
};

export const ContactsProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TContactsTextData>(initialState);

  return (
    <ContactsContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </ContactsContext.Provider>
  );
};
