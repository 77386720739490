import {FC, useContext} from 'react';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import styles from './merchantBusiness.module.scss';

export const MerchantBusiness: FC = () => {
  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <div className={styles.imgWrapper}>
        <img
          className={styles.imgWrapper}
          src={context?.dataContext.merchant_business_image}
          alt="merchant business"
        />
      </div>
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>
          {context?.dataContext.merchant_business_title}
        </h3>
        <p className={styles.desc}>
          {context?.dataContext.merchant_business_subtitle}
        </p>
        <p className={styles.annotations}>
          {context?.dataContext.merchant_business_annotation}
        </p>
      </div>
    </div>
  );
};
