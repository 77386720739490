import {FC, useCallback, useContext, useState} from 'react';

import {TitlePageParagraphs} from '../titlePageParagraphs/TitlePageParagraphs';

import {TParagraphProps} from '../../config/config';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import {VariantDescSPL} from './components/variantDescSPL/VariantDescSPL';

import styles from './shareablePaymentLinks.module.scss';

export const ShareablePaymentLinks: FC<TParagraphProps> = ({paragraphs}) => {
  const [activeVariant, setActiveVariant] = useState<number>(0);
  const onVariantDescClick = (value: number) => {
    setActiveVariant(value);
  };

  const context = useContext(OfferYourMerchantsContext);

  const renderVariantDescSPL = useCallback((item: any, index: number) => {

    const handleClick = () => {
      onVariantDescClick(index);
    };

    return(
      <VariantDescSPL
        key={index}
        index={index}
        isActive={activeVariant === index}
        img={item.link_image}
        onClick={handleClick}
        variantDesc={item}
      />
    );
  }, [activeVariant]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.img}
          src={context?.dataContext.shareable_payment_list_links[activeVariant].link_image}
          alt=""
        />
        <div className={styles.annotationsWrapper}>
          <TitlePageParagraphs paragraphs={paragraphs} id={2} />
          {context?.dataContext.shareable_payment_list_links.map(renderVariantDescSPL)}
        </div>
      </div>
    </div>
  );
};
