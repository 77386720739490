import React, {useContext} from 'react';

import {SlideShow} from '../../../components/slideShow/SlideShow';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './configurationProtectionTools.module.scss';


export const ConfigurationProtectionTools: React.FunctionComponent = () => {
  const context = useContext(MerchantManagementContext);

  return (
    <div className={styles.configurationProtectionTools}>
      <div className={styles.content}>
        <div className={styles.titleContent}>
          <HighlightText
            text={context?.dataContext.configuration_of_fraud_title as string}
            highlight={context?.dataContext.configuration_of_fraud_orange_text as string}
            highlightClass={styles.hightlightTitle}
            className={styles.title}
          />
          <p className={styles.text}>{context?.dataContext.configuration_of_fraud_subtitle}</p>
        </div>
        <div className={styles.containerInformation}>
          <div className={styles.sliderBlock}>
            {context?.dataContext.configuration_of_fraud_image_carousel &&
            <SlideShow className={styles.slides} slidersLength={2}>
              {context?.dataContext.configuration_of_fraud_image_carousel.map((item, id) => {
                return (
                  <img
                    className={styles.images}
                    key={`configuration_of_fraud_image_${id}`}
                    alt="slide"
                    src={item.configuration_of_fraud_image}
                  />
                );
              })}
            </SlideShow>}
          </div>
          <div className={styles.textContainer}>
            <div className={styles.listContent}>
              {context?.dataContext.configuration_of_fraud_text_list.map((text, id) => (
                <div key={`configuration_of_fraud_text_list_${id}`}>
                  <p className={styles.listText}>{text.configuration_of_fraud_text_item}</p>
                  {(text.configuration_of_fraud_text_need_list &&
                    text.configuration_of_fraud_list_in_text) &&
                  <ol className={styles.list}>
                    {text.configuration_of_fraud_list_in_text.map((point, index) => {
                      return <li key={`point${index}`}>
                        {point.configuration_of_fraud_list_item_in_text}
                      </li>;
                    })}
                  </ol>}
                </div>))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationProtectionTools;
