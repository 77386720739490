import {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import reliabilitySafety from '../../assets/images/reliabilitySafety.png';

import {TFooterData, TReportAndBalanceTextData} from '../../utils/types/pagesTypes';

import {ReportAndBalanceContext} from '../../context/ReportAndBalanceContext';

import useApi from '../../api/useApi';

import {API_URLS} from '../../api/routes';

import {Preloader} from '../../components/preloader/Preloader';

import {FooterContext} from '../../context/FooterContext';

import {BalanceManagement} from './balanceManagement/BalanceManagement';
import {PspReportApi} from './pspReportApi/PspReportApi';
import {TitleBlock} from './titleBlock/TitleBlock';
import {StatisticalReports} from './statisticalReports/StatisticalReports';
import {TransactionReports} from './transactionReports/TransactionReports';

import styles from './reportsBalanceManagement.module.scss';

type TReportAndBalanceData = {
  'reports_group': TReportAndBalanceTextData,
}

export const ReportsBalanceManagement = () => {
  const {data, isLoading, error} =
    useApi<TReportAndBalanceData>(API_URLS.REPORT_AND_BALANCE, {method: 'GET'});

  const footer =
    useApi<TFooterData>(`${API_URLS.REPORT_AND_BALANCE}${API_URLS.FOOTER}`, {method: 'GET'});

  const context = useContext(ReportAndBalanceContext);

  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['reports_group']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={reliabilitySafety} />
      <TitleBlock />
      <TransactionReports />
      <StatisticalReports />
      <BalanceManagement />
      <PspReportApi />
    </div>
  );
};
