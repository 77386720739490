import React from 'react';

import HighlightText from '../../../../components/highlightText/HighlightText';
import {TTable} from '../TableBlock';

import styles from './tableTab.module.scss';

const TableTab: TTable = ({
  renting_table_middle_column_title,
  renting_table_last_column_title,
  renting_table,
}) => {
  return(
    <div className={styles.tableTab}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div className={styles.cell}>
                {renting_table_middle_column_title}
              </div>
            </th>
            <th>
              <div className={styles.cell}>
                {renting_table_last_column_title}

              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {renting_table?.map((row, index) => {
            return(
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <div className={styles.cell}>
                      {row.renting_table_first_column_text}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={styles.cell}>
                      {row.renting_table_second_column_need_link
                        ?
                        <HighlightText
                          text={row.renting_table_middle_column_text}
                          highlight={row.renting_table_second_column_link_text}
                          className={''}
                          highlightClass={''}
                          href={row.renting_table_second_column_link}
                        />
                        :
                        <>
                          {row.renting_table_middle_column_text}
                        </>
                      }
                    </div>
                  </td>
                  <td>
                    <div className={styles.cell}>
                      {row.renting_table_third_column_need_link
                        ?
                        <HighlightText
                          text={row.renting_table_third_column_text}
                          highlight={row.renting_table_third_column_link_text}
                          className={''}
                          highlightClass={''}
                          href={row.renting_table_third_column_link}
                        />
                        :
                        <>
                          {row.renting_table_third_column_text}
                        </>
                      }
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableTab;
