import React, {useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import {headersNavigation} from '../../utils/constants/headerConstants';

import picture from '../../assets/images/sitemapPicture.png';


import {API_URLS} from '../../api/routes';
import {TFooterData} from '../../utils/types/pagesTypes';
import {FooterContext} from '../../context/FooterContext';
import useApi from '../../api/useApi';

import styles from './sitemap.module.scss';
import {GlobalLinkWithAdditionalLinks}
  from './components/globalLinkWithAdditionalLinks/GlobalLinkWithAdditionalLinks';

export const Sitemap: React.FunctionComponent = () => {
  const footer =
    useApi<TFooterData>(`${API_URLS.SITEMAP}${API_URLS.FOOTER}`, {method: 'GET'});
  const footerContext = useContext(FooterContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  return (
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <div className={styles.wrapper}>
        <div className={styles.title}>Sitemap</div>
        <div className={styles.linksWrapper}>
          {headersNavigation.map((values) => (
            <GlobalLinkWithAdditionalLinks key={values.id} values={values} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sitemap;
