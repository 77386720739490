import {FC, useContext} from 'react';

import {CarouselWrapper} from '../carouselWrapper/CarouselWrapper';

import {IntegrationsContext} from '../../../../context/IntegrationsContext';

import styles from './acceptGlobalPayments.module.scss';

export const AcceptGlobalPayments: FC = () => {

  const context = useContext(IntegrationsContext);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{context?.dataContext.accept_payments_title}</div>
        <p className={styles.description}>
          {context?.dataContext.accept_payments_text}
        </p>
      </div>
      <div className={styles.carouselsWrapper}>
        <div className={styles.block} />
        <CarouselWrapper
          title={{text: context?.dataContext.cards_title as string}}
        >
          {
            typeof context?.dataContext.cards_images === 'boolean'
              ?
              undefined
              :
              context?.dataContext.cards_images.map((item, index) => {
                return(
                  <div key={`cards_images_${index}`}>
                    {item.cards_image && (
                      <img
                        className={styles.img}
                        alt={item.cards_image as string}
                        src={item.cards_image as string}
                      />
                    )}
                  </div>
                );
              })
          }
        </CarouselWrapper>

        <CarouselWrapper
          title={{text: context?.dataContext.wallet_title as string}}
          description={{theme: 'white'}}
          descriptionText={context?.dataContext.wallet_text as string}
          highlightText={context?.dataContext.wallet_text_orange as string}
        >
          {
            typeof context?.dataContext.wallet_images === 'boolean'
              ?
              undefined
              :
              context?.dataContext.wallet_images.map((item, index) => {
                return(
                  <div key={`wallet_images_${index}`}>
                    {item.wallet_image && (
                      <img
                        className={styles.img}
                        alt={item.wallet_image}
                        src={item.wallet_image}
                      />
                    )}
                  </div>
                );
              })
          }
        </CarouselWrapper>

        <CarouselWrapper
          title={{text: context?.dataContext.alternative_payment_title as string}}
          description={{theme: 'white'}}
          descriptionText={context?.dataContext.alternative_payment_text as string}
          highlightText={context?.dataContext.alternative_payment_text_orange as string}
        >
          {
            typeof context?.dataContext.alternative_payment_images === 'boolean'
              ?
              undefined
              :
              context?.dataContext.alternative_payment_images.map((item, index) => {
                return(
                  <div key={`alternative_payment_images_${index}`}>
                    {item.alternative_payment_image && (
                      <img
                        className={styles.img}
                        alt={item.alternative_payment_image}
                        src={item.alternative_payment_image}
                      />
                    )}
                  </div>
                );
              })
          }
        </CarouselWrapper>

        <CarouselWrapper
          title={{
            text: context?.dataContext.bank_transfers_title as string,
            theme: 'white',
          }}
          description={{theme: 'white'}}
          descriptionText={context?.dataContext.bank_transfers_text}
          highlightText={context?.dataContext.bank_transfers_text_orange}
        >
          {
            typeof context?.dataContext.bank_transfers_images === 'boolean'
              ?
              undefined
              :
              context?.dataContext.bank_transfers_images.map((item, index) => {
                return(
                  <div key={`transfers_images${index}`}>
                    {item.bank_transfers_image && (
                      <img
                        className={styles.img}
                        alt={item.bank_transfers_image as string}
                        src={item.bank_transfers_image as string}
                      />
                    )}
                  </div>
                );
              })
          }
        </CarouselWrapper>
      </div>
    </div>
  );
};
