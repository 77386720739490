import {FC, memo, useEffect, useState, useRef} from 'react';

import bigOne from '../../../../assets/images/bigOne.png';
import bigTwo from '../../../../assets/images/bigTwo.png';
import bigThree from '../../../../assets/images/bigThree.png';
import bigFour from '../../../../assets/images/bigFour.png';
import bigFive from '../../../../assets/images/bigFive.png';
import bigSix from '../../../../assets/images/bigSix.png';

import styles from './desktopSlide.module.scss';

export type TProps = {
  carousel_title: string;
  carousel_image: string;
  carousel_text: {
    carousel_text_item: string;
    need_list_in_text?: boolean;
    list_in_text_item?: string | {list_item: string}[];
  }[];
  index: number;
  changeActiveIndex: (id: number) => void;
}

const NUMBERS_IMAGES = [
  bigOne,
  bigTwo,
  bigThree,
  bigFour,
  bigFive,
  bigSix,
];

const DesktopSlide: FC<TProps> = ({
  carousel_title,
  carousel_text,
  index,
  changeActiveIndex,
}) => {
  const [isShadow, setIsShadow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const distance = ref.current?.getBoundingClientRect().top as number;
    if ( 99 < distance && distance < 450) {
      setIsShadow(false);
      changeActiveIndex(index);
    }
    else {
      setIsShadow(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return(
    <div className={styles.desktopSlide} ref={ref}>
      {isShadow && <div className={styles.shadow}/>}
      <div className={styles.number}>
        <img src={NUMBERS_IMAGES[index]} className={styles.numberImage} alt="" />
      </div>
      <div className={styles.text}>
        <div className={styles.title}>
          {carousel_title}
        </div>
        {carousel_text.map(((text, textId) => (<div className={styles.description} key={textId}>
          <p>
            {text.carousel_text_item}
          </p>
          {(text.need_list_in_text && Array.isArray(text.list_in_text_item)) && (
            <ul className={styles.list}>
              {
                text.list_in_text_item.map((item, id) => {
                  return(
                    <li className={styles.listItem} key={id}>
                      {item.list_item}
                    </li>
                  );
                })
              }
            </ul>
          )}
        </div>
        )))}
      </div>
    </div>
  );
};

export default memo(DesktopSlide);
