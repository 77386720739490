import {FC} from 'react';

import classNames from 'classnames';

import styles from './blogCard.module.scss';

type TCardTypeStyles = 'BigWhite' | 'BigBlack' | 'Small';

const CARD_TYPE_STYLES: Record<TCardTypeStyles, string> = {
  'BigWhite': styles.white,
  'BigBlack': styles.black,
  'Small': styles.small,
};

export type TBlogCardProps = {
  post_author: string,
  post_date: string,
  post_title: string,
  post_excerpt: string,
  post_name: string,
  thumbnail: string,
  template_type: TCardTypeStyles,
  categories: {
    name: string,
    slug: string,
  }[];
}

type TBlogCard = FC<TBlogCardProps>;

const BlogCard: TBlogCard = ({
  post_author,
  post_date,
  post_title,
  post_excerpt,
  post_name,
  thumbnail,
  template_type,
  categories,
}) => {

  const handleClick = () => {
    window.open(`/blog/${post_name}`, '_blank');
  };

  const cardClasses = classNames(
    styles.card,
    CARD_TYPE_STYLES[template_type],
  );

  return(
    <div className={cardClasses} onClick={handleClick}>
      {thumbnail && (
        <div className={styles.imgWrap}>
          <img src={thumbnail} alt="blog img" />
        </div>
      )}
      <div className={styles.contentWrap}>
        <div className={styles.authorDateWrap}>
          <span className={styles.author}>{post_author}</span>
          <span className={styles.date}>{post_date}</span>
        </div>
        <div className={styles.title}>{post_title}</div>
        <div className={styles.descr}>{post_excerpt}</div>
        <div className={styles.tagWrap}>
          {categories.map((item) => {
            return(
              <div className={styles.tag} key={item.slug}>{item.name}</div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
