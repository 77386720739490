import React, {useContext} from 'react';

import processTransactions1 from '../../../assets/images/processTransactions1.png';
import processTransactions2 from '../../../assets/images/processTransactions2.png';
import processTransactions3 from '../../../assets/images/processTransactions3.png';

import {HomeContext} from '../../../context/HomeContext';
import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './options.module.scss';



const Options: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const optionsItem = [
    {
      id: 1,
      headerTitle: context?.dataContext.option_1_header_title as string,
      title: context?.dataContext.option_1_title as string,
      description: (
        <HighlightText
          text={context?.dataContext.option_1_description as string}
          highlight={context?.dataContext.option_1_description_bold as string}
          className={styles.description}
          highlightClass={styles.bold}
        />
      ),
      imageNumber: processTransactions1,
      className: styles.firstOption,
    },
    {
      id: 2,
      headerTitle: context?.dataContext.option_2_header_title as string,
      title: context?.dataContext.option_2_title as string,
      description: (
        <HighlightText
          text={context?.dataContext.option_2_description as string}
          highlight={context?.dataContext.option_2_description_bold as string}
          className={styles.description}
          highlightClass={styles.bold}
        />
      ),
      imageNumber: processTransactions2,
      className: styles.secondOption,
    },
    {
      id: 3,
      headerTitle: context?.dataContext.option_3_header_title as string,
      title: context?.dataContext.option_3_title as string,
      description: (
        <HighlightText
          text={context?.dataContext.option_3_description as string}
          highlight={context?.dataContext.option_3_description_bold as string}
          className={styles.description}
          highlightClass={styles.bold}
        />
      ),
      imageNumber: processTransactions3,
      className: styles.thirdOption,
    },
  ];

  return (<div className={styles.container}>
    <div className={styles.options}>
      {optionsItem.map((option) => (
        <div className={option.className} key={option.id}>
          <div className={styles.header}>
            <h3 className={styles.headerTitle}>
              {option.headerTitle}
            </h3>
            <img alt="" src={option.imageNumber} className={styles.img} />
          </div>
          <h3 className={styles.title}>
            {option.title}
          </h3>
          <p className={styles.description}>
            {option.description}
          </p>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Options;
