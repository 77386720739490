import styles from './StepsBlock.module.scss';

type TStepsBlock = {
  title: string;
  descr: string;
  steps: Array<string>;
}

const StepsBlock: React.FunctionComponent<TStepsBlock> = (props) => {
  const {title, descr, steps} = props;
  const renderSteps = () => {
    return steps.map((item, index) => {
      return(
        <div className={styles.stepItem} key={index}>
          <div className={styles.stepNumber}>{index + 1}</div>
          <div className={styles.stepText}>{item}</div>
        </div>
      );
    });
  };

  return(
    <div className={styles.container}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.stepsBlockWrap}>
        <div className={styles.infoWrap}>
          <div className={styles.textWrap}>
            <p className={styles.descr}>{descr}</p>
          </div>
        </div>
        <div className={styles.stepsWrap}>
          {renderSteps()}
        </div>
      </div>
    </div>
  );
};

export default StepsBlock;
