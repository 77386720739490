import React, {ReactElement, useContext} from 'react';
import cn from 'classnames';

import {ReactComponent as Merchant} from '../../../assets/icons/merchant.svg';
import {ReactComponent as Cards} from '../../../assets/icons/cards.svg';
import {ReactComponent as Point} from '../../../assets/icons/point.svg';
import {ReactComponent as PointWhite} from '../../../assets/icons/pointWhite.svg';
import {ReactComponent as OrageRow} from '../../../assets/icons/arrowRightOrange.svg';
import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {HomeContext} from '../../../context/HomeContext';

import HighlightText from '../../../components/highlightText/HighlightText';

import Item from './Item/Item';
import ItemAdaptive from './ItemAdaptive/ItemAdaptive';

import styles from './paymentBuild.module.scss';

type ItemType = {
  item: ReactElement;
  hoverItem: ReactElement;
  title: string;
  isBlack?: boolean;
};

type ItemAdaptiveType = {
  title: string;
  content: ReactElement;
};

export const PaymentBuild: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const cardsList = context?.dataContext.payment_methods;

  const items: ItemType[] = [
    {
      item: <Merchant className={styles.merchantIcon} />,
      hoverItem: <div className={styles.merchantsTitle}>
        {context?.dataContext.merchant_subtitle}
      </div>,
      title: context?.dataContext.merchant_title as string,
    },
    {
      item: (
        <div className={styles.itemText}>
          <h1 className={styles.itemTitle}>{context?.dataContext.simple_integration_h1}</h1>
          <h2 className={styles.itemSubtitle}>
            {context?.dataContext.simple_integration_h2}
          </h2>
        </div>
      ),
      hoverItem: (
        <img
          alt={context?.dataContext.simple_integration_title as string}
          className={styles.itemText}
          src={context?.dataContext.simple_integration_image}
        />
      ),
      title: context?.dataContext.simple_integration_title as string,
    },
    {
      item: (
        <div className={styles.pspBlock}>
          <div className={styles.pspTitle}>
            <Point className={styles.iconPoint} /> {context?.dataContext.you_psp_title}
          </div>
          <p className={styles.pspSubtitle}>{context?.dataContext.brand_domain_name}</p>
          <p className={styles.pspFooter}>{context?.dataContext.powered_by}</p>
        </div>
      ),
      hoverItem: (
        <div className={styles.pspBlock}>
          <div className={styles.pspTitle}>
            <PointWhite className={styles.iconPoint} /> {context?.dataContext.you_psp_title}
          </div>
          <p className={styles.pspSubtitle}>{context?.dataContext.brand_domain_name}</p>
          <p className={styles.pspFooter}>{context?.dataContext.powered_by}</p>
        </div>
      ),
      title: context?.dataContext.payment_service_provider_title as string,
      isBlack: true,
    },
    {
      item: (
        <div className={styles.itemText}>
          <h1 className={styles.itemTitle}>{context?.dataContext.connectors_h1}</h1>
          <h2 className={styles.itemSubtitle}>
            {context?.dataContext.connectors_h2}
          </h2>
        </div>
      ),
      hoverItem: (
        <img
          alt={context?.dataContext.connectors_title as string}
          className={styles.itemText}
          src={context?.dataContext.connectors_image}
        />
      ),
      title: context?.dataContext.connectors_title as string,
    },
    {
      item: <Cards className={styles.cardsIcon} />,
      hoverItem: (
        <ul className={styles.itemList}>
          {cardsList?.map((item, index) => (
            <li className={styles.listText} key={index}>
              {item.payment_method}
            </li>
          ))}
        </ul>
      ),
      title: context?.dataContext.payment_methods_title as string,
    },
  ];

  const itemsAdaptive: ItemAdaptiveType[] = [
    {
      title: context?.dataContext.simple_integration_title as string,
      content: (
        <div className={styles.itemsCardAdaptive}>
          <div className={styles.cardsText}>
            <h1 className={styles.cardsTitle}>{context?.dataContext.simple_integration_h1}</h1>
            <h2
              className={cn(styles.cardsSubtitle, styles.integrationSubtitle)}
            >
              {context?.dataContext.simple_integration_h2}
            </h2>
          </div>
          <img
            alt={context?.dataContext.simple_integration_title}
            src={context?.dataContext.simple_integration_image}
          />
        </div>
      ),
    },
    {
      title: context?.dataContext.payment_service_provider_title as string,
      content: (
        <div className={styles.pspBlockAdaptive}>
          <div className={styles.pspTitle}>
            <Point className={styles.iconPoint} /> {context?.dataContext.you_psp_title}
          </div>
          <p className={styles.pspSubtitle}>{context?.dataContext.brand_domain_name}</p>
          <p className={styles.pspFooter}>{context?.dataContext.powered_by}</p>
        </div>
      ),
    },
    {
      title: context?.dataContext.connectors_title as string,
      content: (
        <div className={styles.itemsCardAdaptive}>
          <div className={styles.cardsText}>
            <h1 className={styles.cardsTitle}>{context?.dataContext.connectors_h1}</h1>
            <h2 className={cn(styles.cardsSubtitle, styles.connectorsSubtitle)}>
              {context?.dataContext.connectors_h2}
            </h2>
          </div>
          <img
            alt={context?.dataContext.connectors_title as string}
            src={context?.dataContext.connectors_image}
          />
        </div>
      ),
    },
  ];

  const buttonsLinkItemsTop = [
    {
      id: 1,
      link: context?.dataContext.all_shopping_carts_button_link,
      textButton: context?.dataContext.all_shopping_carts_button_text,
    },
    {
      id: 2,
      link: context?.dataContext.request_a_list_button_link,
      textButton: context?.dataContext.request_a_list_button_text,
    },
  ];
  const buttonsLinkItemsBottom = [
    {
      id: 3,
      link: context?.dataContext.all_payment_brands_button_link,
      textButton: context?.dataContext.all_payment_brands_button_text,
    },
  ];

  return (
    <div className={styles.paymentBlock}>
      <h1 className={styles.title}>
        {context?.dataContext.build_screen_title}
      </h1>
      <h2 className={styles.subtitle}>
        <HighlightText
          text={context?.dataContext.build_screen_subtitle as string}
          highlight={context?.dataContext.build_screen_subtitle_orange as string}
          className={styles.subtitle}
          highlightClass={styles.highlight}
        />
      </h2>
      <div className={styles.itemsBlockDesktop}>
        <div className={styles.itemsWrapper}>
          {items.map((element, index) => (
            <Item
              key={index}
              item={element.item}
              hoverItem={element.hoverItem}
              isBlack={'isBlack' in element ? element.isBlack : false}
              title={element.title}
            />
          ))}
        </div>
      </div>
      <div className={styles.itemsBlockAdaptive}>
        <h1 className={styles.itemsTitleAdaptive}>{context?.dataContext.merchant_title}</h1>
        <div className={styles.blockMerchantAdaptive}>
          <Merchant className={styles.merchantIconAdaptive} />
          <Merchant className={styles.merchantIconAdaptive} />
          <Merchant className={styles.merchantIconAdaptive} />
        </div>
        {itemsAdaptive.map((element, index) => (
          <ItemAdaptive
            title={element.title}
            content={element.content}
            key={index}
          />
        ))}
        <h1 className={styles.itemsTitleAdaptive}>{context?.dataContext.payment_methods_title}</h1>
        <div className={styles.blockMerchantAdaptive}>
          <Cards className={styles.cardsIconAdaptive} />
          <Cards className={styles.cardsIconAdaptive} />
          <Cards className={styles.cardsIconAdaptive} />
        </div>
        <div className={styles.cardsList}>
          <ul className={styles.listAdaptive}>
            {cardsList?.map((item, index) => (
              <li className={styles.listTextAdaptive} key={index}>
                {item.payment_method}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.buttons}>
        <div className={styles.lincksContent}>
          {buttonsLinkItemsTop.map((item) => {
            return (
              <a href={item.link} key={item.id} className={styles.buttonLink}>
                <OrageRow />
                <span>{item.textButton}</span>
              </a>
            );
          })}
        </div>
        <div className={styles.lincksContent}>
          {buttonsLinkItemsBottom.map((item) => {
            return (
              <a href={item.link} key={item.id} className={styles.buttonLink}>
                <OrageRow />
                <span>{item.textButton}</span>
              </a>
            );
          })}
          <CustomButton
            className={styles.linkButton}
            onClick={() => {}}
            variant={ButtonVariant.Primary}
          >
            <a
              href={context?.dataContext.request_demo_button_link}
              className={styles.btnText}
              rel="noreferrer"
              target="_blank"
            >
              {context?.dataContext.request_demo_button_text}
            </a>
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default PaymentBuild;
