import React, {useRef} from 'react';

import Autoplay from 'embla-carousel-autoplay';
import {Carousel} from '@mantine/carousel';

import styles from './verticalSlider.module.scss';

interface SliderProps {
  children: JSX.Element[];
  classNameStepper?: string;
}

export const VerticalSlider: React.FunctionComponent<SliderProps> = ({
  children,
  classNameStepper,
}) => {
  const autoplay = useRef(Autoplay({delay: 5000}));
  return (
    <div className={styles.container}>
      <Carousel
        slideSize="100%"
        classNames={{
          root: styles.contentWrapper,
          viewport: styles.contentWrapper,
          indicator: styles.indicator,
          indicators: styles.indicators,
        }}
        orientation="vertical"
        align="start"
        loop
        withControls={false}
        withIndicators
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
      >
        {children.map((child, index) => (
          <div className={styles.oneContentWrapper} key={index}>
            {child}
          </div>
        ))}
      </Carousel>
    </div>
  );
};
