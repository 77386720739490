import React, {useContext} from 'react';

import {ReactComponent as TechSupport} from '../../../assets/icons/tech_support.svg';
import {ReactComponent as Calendar} from '../../../assets/icons/calendar.svg';
import ContactCard from '../../../components/contactCard/ContactCard';
import LinksCard from '../../../components/linksCard/LinksCard';

import {ContactsContext} from '../../../context/ContactsContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import {LinkCard} from '../../../utils/types/commonTypes';

import styles from './currentClientsBlock.module.scss';

export const CurrentClientsBlock: React.FunctionComponent = () => {
  const context = useContext(ContactsContext);

  const onClick = (link: string | undefined) => {
    if (isValidURL(link)) {
      window.open(link, '_blank');
    }
  };

  const cardItems = [
    {
      icon: <TechSupport />,
      title: context?.dataContext.get_technical_support_title,
      text: context?.dataContext.get_technical_support_subtitle,
      buttonText: context?.dataContext.get_technical_support_button_text,
      buttonLink: context?.dataContext.get_technical_support_button_link,
    },
    {
      icon: <Calendar />,
      title: context?.dataContext.new_development_title,
      text: context?.dataContext.new_development_subtitle,
      buttonText: context?.dataContext.new_development_button_text,
      buttonLink: context?.dataContext.new_development_button_link,
    },
  ];

  const links: LinkCard[] = context?.dataContext
    .other_form_contact_info.map((item) => {
      const link: LinkCard = {
        img: item.other_contact_info_image,
        text: item.other_contact_info_text,
        link: item.other_contact_info_link,
        underlineStyle: item.other_contact_info_link_underline,
      };
      return link;
    }) || [];

  return (
    <div className={styles.currentClientsBlock}>
      <h2 className={styles.title}>{context?.dataContext.contacts_subtitle_for_current}</h2>
      <div className={styles.cardsContainer}>
        {cardItems.map((item, id) => (
          <ContactCard
            key={id}
            icon={item.icon}
            title={item.title}
            text={item.text}
            buttonText={item.buttonText}
            onClick={() => {onClick(item.buttonLink);}}
          />
        ))}
        <LinksCard links={links}/>
      </div>
    </div>
  );
};

export default CurrentClientsBlock;
