import React, {FC} from 'react';

import {TitlePageParagraphs} from '../titlePageParagraphs/TitlePageParagraphs';

import {TParagraphProps} from '../../config/config';

import styles from './mobileApps.module.scss';

export const MobileApps: FC<TParagraphProps> = ({paragraphs}) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TitlePageParagraphs
          paragraphs={paragraphs}
          id={3}
        />
      </div>
    </div>
  );
};
