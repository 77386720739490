import {FC, useContext, useEffect} from 'react';

import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import picture from '../../assets/images/aboutUsHead.png';

import useApi from '../../api/useApi';

import {TAboutUsTextData} from '../../utils/types/pagesTypes';

import {API_URLS} from '../../api/routes';

import {AboutUsContext} from '../../context/AboutUsContext';

import {Preloader} from '../../components/preloader/Preloader';

import {WelcomeBlockAboutUs} from './components/welcomeBlockAboutUs/WelcomeBlockAboutUs';
import {WhoWeAreAboutUs} from './components/whoWeAreAboutUs/WhoWeAreAboutUs';
import {WhatIsOurHistory} from './components/whatIsOurHistory/WhatIsOurHistory';
import {WhoIsInOurTeam} from './components/whoIsInOurTeam/WhoIsInOurTeam';
import {WhatDoWeTrust} from './components/whatDoWeTrust/WhatDoWeTrust';
import {WhatAreOurCases} from './components/whatAreOurCases/WhatAreOurCases';
import {WhatAreTheQuestions} from './components/whatAreTheQuestions/WhatAreTheQuestions';
import {HowToContactUs} from './components/howToContactUs/HowToContactUs';
import {WhereToFindUs} from './components/howToContactUs/whereToFindUs/WhereToFindUs';

import styles from './styles.module.scss';

type TAboutUsData = {
  'about_us_group': TAboutUsTextData;
}

const AboutUs: FC = () => {

  const {data, isLoading, error} =
    useApi<TAboutUsData>(API_URLS.ABOUT_US, {method: 'GET'});

  const context = useContext(AboutUsContext);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['about_us_group']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <WelcomeBlockAboutUs />
      <WhoWeAreAboutUs />
      <WhatIsOurHistory />
      <WhoIsInOurTeam />
      <WhatDoWeTrust />
      <WhatAreOurCases />
      <WhatAreTheQuestions />
      <HowToContactUs />
      <WhereToFindUs />
    </div>
  );
};

export default AboutUs;
