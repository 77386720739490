import {memo, useContext, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';

import mockUp from '../../../assets/images/configurationPayment/mockup.png';

import {Slider} from '../../../components/slider/Slider';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import styles from './configurationPayment.module.scss';

type TTabItem = {
  img: string,
  triggertext: string
};

const ConfigurationPayment = () => {
  const context = useContext(MerchantManagementContext);

  const [activeIndex, setActiveIndex] = useState(0);

  const renderTrigger = useCallback(
    (item: TTabItem, index: number) => {
      const handleClick = () => {
        setActiveIndex(index);
      };

      const triggerClasses = classNames(
        styles.tabTrigger,
        activeIndex === index && styles.active,
      );

      return(
        <div className={styles.itemWrap} key={index}>
          <div className={triggerClasses} onClick={handleClick}>
            {item.triggertext}
          </div>
        </div>
      );
    }, [activeIndex, setActiveIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if( context?.dataContext.mockdata_tabs.length &&
        activeIndex === context?.dataContext.mockdata_tabs.length - 1) {
        setActiveIndex(0);
        return;
      }

      setActiveIndex(prev => prev + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex, context, setActiveIndex]);

  const renderSlide = useCallback((item: TTabItem, index: number) => {
    return(
      <div className={styles.slideItem} key={index}>
        <div className={styles.tabTrigger}>
          {item.triggertext}
        </div>
        <div className={styles.imgWrap}>
          <div className={styles.poster}>
            <img src={item.img} alt="poster" />
          </div>
          <div className={styles.holder}>
            <img src={mockUp} alt="holder" />
          </div>
        </div>
      </div>

    );
  }, []);

  return(
    <div className={styles.configurationPayment}>
      <div className={styles.container}>
        <div className={styles.titleWrap}>
          <h2 className={styles.title}>{context?.dataContext.mockdata_title}</h2>
          <div className={styles.textBlock}>
            {context?.dataContext.mockdata_description.map((item, index) => {
              return(
                <p
                  className={styles.descr}
                  key={`mockdata_description${index}`}
                >
                  {item.mockdata_text}
                </p>
              );
            })}
          </div>
        </div>

        <div className={styles.desktopTab}>
          <div className={styles.contentWrap}>
            <div className={styles.tabsWrap}>
              {context?.dataContext.mockdata_tabs.map(
                (item, index) => renderTrigger(item, index),
              )}
            </div>
            <div className={styles.imgWrap}>
              <div className={styles.poster}>
                <img src={context?.dataContext.mockdata_tabs[activeIndex].img} alt="poster" />
              </div>
              <div className={styles.holder}>
                <img src={mockUp} alt="holder" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mobSlider}>
          {context?.dataContext.mockdata_tabs &&
          <Slider>
            {context?.dataContext.mockdata_tabs.map(renderSlide)}
          </Slider>
          }
        </div>
      </div>
    </div>
  );
};

export default memo(ConfigurationPayment);
