import {createContext, useState} from 'react';

import {TReportAndBalanceTextData} from '../utils/types/pagesTypes';

interface Props {
    children?: JSX.Element
}

interface DataContextProps {
  dataContext: TReportAndBalanceTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TReportAndBalanceTextData>>;
}

export const ReportAndBalanceContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TReportAndBalanceTextData = {
  reports_title: '',
  reports_subtitle: '',
  reports_main_text: '',
  transaction_reports_image: '',
  transaction_reports_title: '',
  transaction_reports_text: '',
  risk_reports_image: '',
  risk_reports_title: '',
  risk_reports_text: '',
  statistical_reports_title: '',
  statistical_reports_title_orange: '',
  statistical_reports_subtitle: '',
  statistical_reports_carousel: [{
    carousel_image: '',
    carousel_title: '',
    carousel_text: [{
      carousel_text_item: '',
      need_list_in_text: false,
      list_in_text_item: '',
    }],
  }],
  balance_management_title: '',
  balance_management_text_list: [{
    balance_management_text: '',
    need_bold_text: false,
    balance_management_bold_text: '',
    need_list_in_text: false,
    balance_management_list_in_text: [{balance_management_list_item: ''}],
  }],
  balance_management_image: '',
  report_api_title: '',
  report_api_text_list: [{
    report_api_list_item: '',
  }],
  report_api_bottom_text: '',
  report_api_bottom_text_url: '',
};

export const ReportAndBalanceProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TReportAndBalanceTextData>(initialState);
  return (
    <ReportAndBalanceContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </ReportAndBalanceContext.Provider>
  );
};
