import {useCallback, useContext} from 'react';

import paymentOrchestrationToolsWelcom
  from '../../../../assets/images/paymentOrchestrationTools/welcomImg.png';

import {PaymentContext} from '../../../../context/PaymentContext';

import styles from './WelcomBlock.module.scss';


const WelcomBlock: React.FunctionComponent = () => {

  const context = useContext(PaymentContext);

  const renderText = useCallback((item: any, index: number) => {
    return <div className={styles.descr} key={index}>{item.text_paragraph}</div>;
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.infoBlock}>
        <h1 className={styles.title}>{context?.dataContext.payment_title}</h1>
        <div className={styles.textBlcok}>
          {context?.dataContext.payment_main_text.map(renderText)}
        </div>
      </div>
      <div className={styles.imgWrap}>
        <img
          className={styles.img}
          src={paymentOrchestrationToolsWelcom}
          alt=""
        />
      </div>
    </div>
  );
};

export default WelcomBlock;
