import React, {FC} from 'react';

import cn from 'classnames';
import {Carousel} from '@mantine/carousel';

import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './carouselWrapper.module.scss';


interface CarouselWrapperProps {
  children: JSX.Element[] | undefined;
  title: {
    theme?: 'black' | 'white';
    text: string;
  };
  description?: {
    theme: 'white' | 'black';
  };
  highlightText?: string
  descriptionText?: string
}
export const CarouselWrapper: FC<CarouselWrapperProps> = ({
  title,
  description,
  children,
  highlightText,
  descriptionText,
}) => {
  return (
    <div className={styles.container}>
      <h1 className={cn(styles.title, {[styles[title.theme!]]: title.theme})}>{title.text}</h1>
      {children && (
        <Carousel
          withControls={false}
          initialSlide={0}
          align={'start'}
          loop
        >
          {children}
        </Carousel>
      )}
      {
        description &&
        <p className={cn(styles.description, styles[description.theme])}>
          <HighlightText
            text={descriptionText as string}
            highlight={highlightText as string}
            className={''}
            highlightClass={styles.description__orange}
          />
        </p>
      }
    </div>
  );
};
