import React, {createContext, useState} from 'react';

import {TPricingOptionsTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TPricingOptionsTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TPricingOptionsTextData>>;
}

export const PricingOptionsContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TPricingOptionsTextData = {
  pricing_title: '',
  pricing_subtitle: '',
  existing_location_head_title: '',
  new_server_head_title: '',
  table_row: [
    {
      table_row_title: '',
      table_row_subtitle: '',
      existing_location_price_title: '',
      need_existing_location_orange_subtitle: false,
      existing_location_orange_subtitle: '',
      need_existing_location_text: false,
      existing_location_text: '',
      need_existing_location_text_list: false,
      existing_location_text_list_title: '',
      existing_location_text_list: [],
      new_server_price_title: '',
      need_new_server_price_orange_subtitle: false,
      new_server_price_orange_subtitle: '',
      need_new_server_price_text: false,
      new_server_text: '',
      need_new_server_text_list: false,
      new_server_text_list_title: '',
      new_server_text_list: [],
    },
  ],
  following_block_title: '',
  following_block_list: [
    {
      following_block_list_item: '',
    },
  ],
  steps_block_title: '',
  steps_block_list: [
    {
      steps_block_list_item: '',
    },
  ],
};

export const PricingOptionsProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TPricingOptionsTextData>(initialState);

  return (
    <PricingOptionsContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </PricingOptionsContext.Provider>
  );
};
