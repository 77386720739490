import {FC, useContext} from 'react';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './TableBlock.module.scss';
import TableDesk from './TableDesk/TableDesk';
import TableTab from './TableTab/TableTab';
import TableMob from './TableMob/TableMob';

type TTableRow = {
  renting_table_first_column_text: string,
  renting_table_middle_column_text: string,
  renting_table_second_column_need_link: boolean,
  renting_table_second_column_link_text: string,
  renting_table_second_column_link: string,
  renting_table_third_column_text: string,
  renting_table_third_column_need_link: boolean,
  renting_table_third_column_link_text: string,
  renting_table_third_column_link: string
}

export type TTableProps = {
  renting_table_middle_column_title?: string,
  renting_table_last_column_title?: string,
  renting_table?: Array<TTableRow>,
}

export type TTable = FC<TTableProps>;

const TableBlock: React.FunctionComponent = () => {

  const context = useContext(CaseSingleContext);

  const tableData: TTableProps = {
    renting_table_middle_column_title: context?.dataContext.renting_table_middle_column_title,
    renting_table_last_column_title: context?.dataContext.renting_table_last_column_title,
    renting_table: context?.dataContext.renting_table,
  };

  return(
    <div className={styles.container}>
      <h2 className={styles.title}>{context?.dataContext.renting_title}</h2>
      <div className={styles.descr}>
        {context?.dataContext.renting_subtitle}
      </div>
      <TableDesk {...tableData}/>
      <TableTab {...tableData} />
      <TableMob {...tableData} />
    </div>
  );
};

export default TableBlock;
