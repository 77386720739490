import React, {useContext, useEffect} from 'react';

import {SliderHome} from '../../components/sliderHome/SliderHome';

import useApi from '../../api/useApi';

import {API_URLS} from '../../api/routes';

import {TFooterData, THomeTextData} from '../../utils/types/pagesTypes';

import {HomeContext} from '../../context/HomeContext';

import {Preloader} from '../../components/preloader/Preloader';

import {FooterContext} from '../../context/FooterContext';

import WelcomeBlock from './welcomeBlock/WelcomeBlock';
import PaymentBuild from './paymentBuild/PaymentBuild';
import BrandsBlock from './brandsBlock/BrandsBlock';
import BenefitFromBlock from './benefitFromBlock/BenefitFromBlock';
import SettingUpPaymentSystem from './settingUpPaymentSystem/SettingUpPaymentSystem';
import SaveTimeAndResourcesBlock from './saveTimeBlock/SaveTimeAndResourcesBlock';
import FightFraud from './fightFraud/FightFraud';
import Options from './options/Options';
import UseCases from './useCases/UseCases';
import RunningLine from './runningLine/RunningLine';

import styles from './home.module.scss';

type THomeData = {
  'homepage_fields': THomeTextData
}

export const Home: React.FunctionComponent = () => {
  const {data, isLoading, error} =
    useApi<THomeData>(API_URLS.HOME, {method: 'GET'});

  const footer =
      useApi<TFooterData>(`${API_URLS.HOME}${API_URLS.FOOTER}`, {method: 'GET'});
  const footerContext = useContext(FooterContext);

  const context = useContext(HomeContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);


  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['homepage_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return (
    <div className={styles.container}>
      <WelcomeBlock />
      <PaymentBuild />
      <SliderHome />
      <BrandsBlock />
      <SettingUpPaymentSystem />
      <Options />
      <SaveTimeAndResourcesBlock />
      <FightFraud />
      <UseCases />
      <BenefitFromBlock />
      <RunningLine />
    </div>
  );
};

export default Home;
