import React from 'react';

import {ISliderHomeItem} from '../../../../utils/types/sliderHomeTypes';

import styles from './sliderItem.module.scss';

interface SliderItemProps {
  values: ISliderHomeItem
}

export const SliderItem: React.FunctionComponent<SliderItemProps> = (
  {values},
) => {

  const {carousel_title, carousel_description, carousel_image} = values;

  return <div className={styles.container}>
    <div className={styles.textWrapper}>
      <h3 className={styles.title}>
        {carousel_title}
      </h3>
      <p className={styles.description}>
        {carousel_description}
      </p>
    </div>
    <div className={styles.imgWrapper}>
      <img src={carousel_image} alt={carousel_title} className={styles.img} />
    </div>
  </div>;
};
