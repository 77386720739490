import React, {FC} from 'react';

import cn from 'classnames';

import {IParagraph} from '../../config/config';

import styles from './titlePageParagraphs.module.scss';

interface TitlePageParagraphsProps {
  id: number;
  className?: string;
  paragraphs: IParagraph[]
}

const stylesColor = {
  black: styles.black,
  white: styles.white,
};

export const TitlePageParagraphs: FC<TitlePageParagraphsProps> = ({id, className, paragraphs}) => {
  const {title, variant, paragraph, description, annotations} = paragraphs[id];

  return (
    <div className={cn(styles.container, stylesColor[variant], className)}>
      <h1 className={styles.title}>
        {paragraph}. <span className={styles.title__text}>{title}</span>
      </h1>
      <div className={styles.descriptionWrapper}>
        <h2 className={styles.description}>{description}</h2>
        <p className={styles.annotations}>{annotations}</p>
      </div>
    </div>
  );
};
