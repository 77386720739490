import {FC, useRef, useState} from 'react';

import cn from 'classnames';

import {ReactComponent as Card1} from '../../../../../../assets/icons/aboutUsCards/card__1.svg';
import {ReactComponent as Card2} from '../../../../../../assets/icons/aboutUsCards/card__2.svg';
import {ReactComponent as Card3} from '../../../../../../assets/icons/aboutUsCards/card__3.svg';
import {ReactComponent as Card4} from '../../../../../../assets/icons/aboutUsCards/card__4.svg';


import {ReactComponent as ArrowDown} from '../../../../../../assets/icons/arrowDown.svg';

import styles from './whoIsInOurTeamSliderItem.module.scss';
export interface ISliderWIIOT {
  our_team_card_title: string;
  our_team_card_description: string;
  our_team_card_description_2: string;
  our_team_card_image: string;
};
interface IWhoIsInOurTeamSliderItemProps {
  values: ISliderWIIOT;
  activeValue: boolean;
  cardHolderIndex: number;
  classes?: string;
}

export const WhoIsInOurTeamSliderItem: FC<IWhoIsInOurTeamSliderItemProps> = ({
  values,
  activeValue,
  cardHolderIndex,
  classes,
}) => {
  const {
    our_team_card_image,
    our_team_card_title,
    our_team_card_description,
    our_team_card_description_2,
  } = values;

  const [isOpen, setIsMore] = useState<boolean>(activeValue);
  const contentRef = useRef<HTMLDivElement>(null);
  const contentTabRef = useRef<HTMLDivElement>(null);

  const cardClasses = [
    styles.fourth,
    styles.first,
    styles.second,
    styles.third,
  ];

  const renderCardHolder = () => {
    switch (cardHolderIndex) {
    case 0:
      return <Card4 className={styles.cardHolder} />;
    case 1:
      return <Card1 className={styles.cardHolder} />;
    case 2:
      return <Card2 className={styles.cardHolder} />;
    case 3:
      return <Card3 className={styles.cardHolder} />;
    }
  };

  const isMobile = window.innerWidth < 744;

  const onClick = () => setIsMore((pr) => !pr);
  return (
    <div
      className={cn(styles.container, classes, {
        [styles.openCard]: isMobile && isOpen,
        [styles.activeCard]: isMobile && isOpen,
        [styles.activeCard]: activeValue && !isMobile,
      })}
    >
      {renderCardHolder()}
      <div
        className={cn(styles.img, cardClasses[cardHolderIndex])}
        style={{
          backgroundImage: `url(${our_team_card_image})`,
        }}
      ></div>
      <h3 className={styles.title}>{our_team_card_title}</h3>
      <div
        ref={contentTabRef}
        className={cn(styles.descriptionWrapperTab, {
          [styles.active]: activeValue,
        })}
        style={{
          maxHeight: activeValue ? contentTabRef.current?.scrollHeight : '0',
        }}
      >
        <p className={styles.desc1}>{our_team_card_description}</p>
        <div className={styles.desc2Wrapper}>
          <div className={styles.point} />
          <div className={styles.desc2}>{our_team_card_description_2}</div>
        </div>
      </div>
      <div
        ref={contentRef}
        className={cn(styles.descriptionWrapperMob, {
          [styles.active]: activeValue,
          [styles.openCard]: isMobile && isOpen,
        })}
        style={{maxHeight: isOpen ? contentRef.current?.scrollHeight : '0'}}
      >
        <p className={styles.desc1}>{our_team_card_description}</p>
        <div className={styles.desc2Wrapper}>
          <div className={styles.point} />
          <div className={styles.desc2}>{our_team_card_description_2}</div>
        </div>
      </div>
      <div className={styles.controller} onClick={onClick}>
        <div className={styles.controller__text}>
          {isOpen ? 'Less' : 'More'}
        </div>
        <div
          className={cn(styles.controller__svgWrapper, {
            [styles.controller__svgWrapper_active]: isOpen,
          })}
        >
          <ArrowDown />
        </div>
      </div>
    </div>
  );
};
