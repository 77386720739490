import React, {useContext} from 'react';

import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {HomeContext} from '../../../context/HomeContext';
import HighlightText from '../../../components/highlightText/HighlightText';
import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './settingUpPaymentSystem.module.scss';

export const SettingUpPaymentSystem: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const handleClick = () => {
    if (isValidURL(context?.dataContext.compare_pricing_button_link)) {
      window.open(context?.dataContext.compare_pricing_button_link, '_blank');
    }
  };

  return (
    <div className={styles.settingUpPaymentSystem}>
      <h3 className={styles.title}>
        {context?.dataContext.up_payment_system_title}
      </h3>
      <p className={styles.subTitle}>
        <HighlightText
          text={context?.dataContext.up_payment_system_subtitle as string}
          highlight={context?.dataContext.up_payment_system_subtitle_orange as string}
          className={styles.subTitle}
          highlightClass={styles.orange}
        />
      </p>
      <div className={styles.listContainer}>
        <p className={styles.listTitle}>
          {context?.dataContext.up_payment_system_list_title}
        </p>
        <ol className={styles.listContent}>
          {context?.dataContext.up_payment_system_list.map((item, index) => {
            return <li key={index}>{item.up_payment_system_list_item}</li>;
          })}
        </ol>
      </div>
      <CustomButton
        className={styles.linkButton}
        onClick={handleClick}
        variant={ButtonVariant.Primary}
      >
        <span className={styles.btnText}>
          {context?.dataContext.compare_pricing_button_text}
        </span>
      </CustomButton>
    </div>
  );
};

export default SettingUpPaymentSystem;
