import React, {useContext} from 'react';


import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';
import board from '../../../assets/images/board.png';

import {ForDevelopersContext} from '../../../context/DevelopersContext';
import {isValidURL} from '../../../utils/hooks/isValidUrl';

import styles from './pspApiReference.module.scss';

export const PspApiReference: React.FunctionComponent = () => {
  const context = useContext(ForDevelopersContext);

  const onClick = (link: string | undefined) => {
    if (isValidURL(link)) {
      window.open(link, '_blank');
    }
  };

  return (
    <div className={styles.pspApiReference}>
      <div className={styles.overlay}></div>
      <div className={styles.content}>
        <div className={styles.textContent}>
          <h3 className={styles.title}>{context?.dataContext.psp_api_title}</h3>
          <p className={styles.text}>
            {context?.dataContext.psp_api_subtitle}
          </p>
        </div>
        <div className={styles.card}>
          <div>
            <p className={styles.cardTitle}>{context?.dataContext.dwh_api_title}</p>
            <p className={styles.cardText}>
              {context?.dataContext.dwh_api_subtitle}
            </p>
          </div>
          <CustomButton
            className={styles.cardBtn}
            onClick={() => onClick(context?.dataContext.dwh_api_button_link)}
            variant={ButtonVariant.Primary}
          >
            <span className={styles.btnText}>{context?.dataContext.dwh_api_button_text}</span>
          </CustomButton>
        </div>
      </div>
      <img src={board} alt="board" className={styles.boardImage} />
    </div>
  );
};

export default PspApiReference;
