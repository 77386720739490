import {useContext} from 'react';

import {CaseSingleContext} from '../../../context/CaseSingleContext';

import styles from './challengeSolutionBlock.module.scss';

const ChallengeSolutionBlock: React.FunctionComponent = () => {
  const context = useContext(CaseSingleContext);
  return(
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.imgWrap}>
          <img src={context?.dataContext.case_main_image} alt="" />
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.contentItem}>
            <h3 className={styles.title}>{context?.dataContext.chalenge_title}</h3>
            <div className={styles.textBlock}>
              {context?.dataContext.chalenge_text_list.map((item, index) => {
                return(
                  <p className={styles.descr} key={index}>{item.chalenge_text}</p>
                );
              })}
            </div>
          </div>
          <div className={styles.contentItem}>
            <h3 className={styles.title}>{context?.dataContext.solution_title}</h3>
            <div className={styles.textBlock}>
              {context?.dataContext.solution_text_list.map((item, index) => {
                return(
                  <span  key={index}>
                    <p className={styles.descr}>{item.solution_text}</p>
                    {item.solution_need_list_in_text &&
                      <ul className={styles.list}>
                        {item.solution_list_in_text.map((listItem, id) => {
                          return(
                            <li className={styles.listItem} key={id}>{listItem.list_item}</li>
                          );
                        })}
                      </ul>
                    }
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeSolutionBlock;

