import {useCallback, useContext, useState} from 'react';

import classNames from 'classnames';

import {ReactComponent as ArrowRight} from '../../../../assets/icons/arrowRightOrange.svg';

import {PaymentContext} from '../../../../context/PaymentContext';

import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './TabsBlock.module.scss';

const TabsBlock: React.FunctionComponent = () => {

  const context = useContext(PaymentContext);

  const {tabItems} = {
    tabItems: [
      <div className={styles.tabContent}>
        <p>{context?.dataContext.smart_routing_tab_description}</p>
        <span>
          <strong>
            <HighlightText
              text={context?.dataContext.smart_routing_tab_bold_text as string}
              highlight={context?.dataContext.smart_routing_tab_orange_in_bold as string}
              highlightClass={styles.orange}
            />
          </strong>
        </span>
        <ul>
          {context?.dataContext.smart_routing_tab_list.map((item, index) => {
            return(
              <li key={index}>{item.tab_list_item}</li>
            );
          })}
        </ul>
      </div>
      ,
      <div className={styles.tabContent}>
        <p>{context?.dataContext.settings_tab_description}</p>
        <span>
          <strong>
            <HighlightText
              text={context?.dataContext.settings_tab_bold_text as string}
              highlight={context?.dataContext.settings_tab_orange_in_bold as string}
              highlightClass={styles.orange}
            />
          </strong>
        </span>
      </div>,
    ],
  };

  const [activeTab, setActiveTab] = useState(0);

  const renderTriggers = useCallback((trigger: string, index: number) => {

    const handleClick = () => {
      setActiveTab(index);
    };

    return(
      <div
        className={classNames(styles.tabTrigger, index === activeTab && styles.active)}
        onClick={handleClick}
        key={index}
      >
        <span className={styles.tabTriggerText}>
          {trigger}
        </span>
      </div>
    );
  }, [activeTab]);

  const renderTabs = useCallback(() => {

    const tabs = {
      triggers: [
        context?.dataContext.smart_routing_tab_title as string,
        context?.dataContext.settings_tab_title as string,
      ],
      content: tabItems,
    };

    return(
      <div className={styles.tabsContainer}>
        <div className={styles.tabTriggerWrap}>
          {tabs.triggers.map(renderTriggers)}
        </div>
        <div className={(styles.tabItemsWrap)}>
          <div className={styles.tabItem}>
            {tabs.content[activeTab]}

          </div>

        </div>
      </div>
    );
  }, [
    activeTab,
    tabItems,
    renderTriggers,
    context?.dataContext.smart_routing_tab_title,
    context?.dataContext.settings_tab_title,
  ]);

  return (
    <div className={styles.block}>
      <div className={styles.container}>
        <div className={styles.tabsBlockWrap}>
          <div className={styles.infoWrap}>
            <span className={styles.topPrompt}>{context?.dataContext.interface_block_text}</span>
            {renderTabs()}
            <a
              href={context?.dataContext.contact_sales_link}
              className={styles.link}
              rel="noreferrer"
              target="_blank"
            >
              <ArrowRight />
              {context?.dataContext.contact_sales_link_text}
            </a>
            <span className={styles.botPrompt}>{context?.dataContext.bot_prompt}</span>
          </div>
          <div className={styles.imgWrap}>
            <span className={styles.tabPrompt}>{context?.dataContext.interface_block_text}</span>
            <img src={context?.dataContext.interface_block_image} alt="tab-img" />
          </div>
        </div>

        <div className={styles.triangle}>
          <div className={styles.ellipse}></div>
        </div>
      </div>
    </div>
  );
};

export default TabsBlock;
