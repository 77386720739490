import React, {createContext, useState} from 'react';

import {TOfferYourMerchantsTextData} from '../utils/types/pagesTypes';

interface Props {
  children?: JSX.Element
}

interface DataContextProps {
  dataContext: TOfferYourMerchantsTextData;
  setDataContext: React.Dispatch<React.SetStateAction<TOfferYourMerchantsTextData>>;
}

export const OfferYourMerchantsContext = createContext<DataContextProps | undefined>(undefined);

const initialState: TOfferYourMerchantsTextData = {
  welcome_title: '',
  welcome_subtitle: '',
  welcome_title_2: '',
  welcome_subtitle_2: '',
  payment_widget_title: '',
  payment_widget_description: '',
  payment_widget_annotation: '',
  payment_widget_carousel: [{
    item_image: '',
    item_text: [{
      item_title: '',
      item_description: '',
      item_list: [{list_item: ''}],
    }],
  }],
  hosted_payment_title: '',
  hosted_payment_subtitle: '',
  hosted_payment_text: '',
  shareable_payment_title: '',
  shareable_payment_subtitle: '',
  shareable_payment_annotation: '',
  shareable_payment_list_links: [{
    link_title: '',
    link_description: '',
    link_image: '',
  }],
  mobile_apps_title: '',
  mobile_apps_subtitle: '',
  mobile_apps_description: '',
  server_title: '',
  server_subtitle: '',
  server_description: '',
  virtual_terminal_title: '',
  virtual_terminal_subtitle: '',
  virtual_terminal_description: '',
  simple_integration_title: '',
  simple_integration_subtitle: '',
  simple_integration_description: '',
  simple_integration_link_text: '',
  simple_integration_link: '',
  simple_integration_image: '',
  global_payment_title: '',
  global_payment_subtitle: '',
  global_payment_annotations: [{
    annotation: '',
    annotation_bold_text: '',
  }],
  global_payment_image: '',
  enable_revenue_title: '',
  enable_revenue_subtitle: '',
  enable_revenue_carousel: [{
    carousel_image: false,
    carousel_title: '',
    carousel_description: '',
  }],
  merchant_business_title: '',
  merchant_business_subtitle: '',
  merchant_business_annotation: '',
  merchant_business_image: '',
  simplify_title: '',
  transaction_title: '',
  transaction_subtitle: '',
  transaction_annotation: '',
  finance_service_title: '',
  finance_service_subtitle: '',
  finance_service_annotation: '',
  balance_management_title: '',
  balance_management_subtitle: '',
  balance_management_annotation: '',
};

export const OfferYourMerchantsProvider: React.FunctionComponent<Props> = ({children}) => {
  const [dataContext, setDataContext] = useState<TOfferYourMerchantsTextData>(initialState);

  return (
    <OfferYourMerchantsContext.Provider value={{dataContext, setDataContext}}>
      {children}
    </OfferYourMerchantsContext.Provider>
  );
};
