import React, {useContext} from 'react';

import {MerchantManagementContext} from '../../../context/MerchantManagementContext';

import styles from './merchantAccountManagement.module.scss';

export const MerchantAccountManagement: React.FunctionComponent = () => {
  const context = useContext(MerchantManagementContext);

  return (
    <div className={styles.merchantAccountManagement}>
      <div className={styles.orangeBlock}>
        <h3 className={styles.title}>
          {context?.dataContext.main_content_title}
        </h3>
        <p className={styles.text}>{context?.dataContext.main_content_description}</p>
      </div>
      <div className={styles.mainContent}>
        <div className={styles.content}>
          <h3 className={styles.title}>{context?.dataContext.merchant_account_title}</h3>
          <img
            className={styles.image}
            src={context?.dataContext.merchant_account_image}
            alt="merchant_account_management_image"
          />
          <div className={styles.listContent}>
            {context?.dataContext.merchant_account_text_list.map((item, index) =>
              (<div key={index}>
                <p className={styles.listText}>{item.merchant_account_text_item}</p>
                {(item.merchant_account_text_need_list && item.merchant_account_list_in_text) &&
                  <ol className={styles.list}>
                    {item.merchant_account_list_in_text.map((point, id) => {
                      return <li key={id}>{point.merchant_account_list_item_in_text}</li>;
                    })}
                  </ol>}
              </div>
              ))}
          </div>
        </div>
        <img
          className={styles.imageDesktop}
          src={context?.dataContext.merchant_account_image}
          alt="imageSubscriptionManagements"
        />
      </div>
    </div>
  );
};

export default MerchantAccountManagement;
