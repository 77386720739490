import {FC, useContext, useMemo} from 'react';
import cn from 'classnames';
import {useLocation} from 'react-router';

import {CustomButton} from '../../../customButton/CustomButton';
import {ButtonVariant} from '../../../../utils/constants/buttonVariantEnum';
import {headersNavigation} from '../../../../utils/constants/headerConstants';
import {isValidURL} from '../../../../utils/hooks/isValidUrl';
import {splitStringByBr} from '../../../../utils/hooks/splitString';
import {FooterContext} from '../../../../context/FooterContext';
import {PATH} from '../../../../utils/constants/routeConstants';

import styles from './welcomeBlockFooter.module.scss';

const isGetContent = (path: string): boolean => {
  if (path === '/') {
    return true;
  }

  for(const header of headersNavigation) {
    if(header.link === path) {
      return header.isGetContent;
    }

    for(const additional of header.additionalFooterMenu ?? []) {
      if(additional.link === path) {
        return header.isGetContent;
      }
    }
    for(const additional of header.additionalMenu ?? []) {
      if(additional.link === path) {
        return header.isGetContent;
      }
    }
  }
  return false;
};

export const WelcomeBlockFooter: FC = () => {
  const context = useContext(FooterContext);
  const location = useLocation();
  const isMountContent = useMemo(() => isGetContent(location.pathname), [location]);

  if(!isMountContent) {
    return null;
  }

  const onClick = () => {
    if (isValidURL(context?.dataContext.ready_to_get_button_link)) {
      window.open(context?.dataContext.ready_to_get_button_link, '_blank');
    };
  };

  const checkTheme = () => {
    const blackContainer =
      [PATH.features('antiFraudTools'),
        PATH.features('pricingOptions'),
        PATH.company('PCIDSScertificate'),
        PATH.features('merchantManagementTools'),
        PATH.sitemap];

    if (location.pathname === PATH.resources('useCases')) {
      return cn(styles.partTheme, styles.blackTheme);
    }
    if (location.pathname === PATH.company('eComChargeAndTeam')) {
      return cn(styles.partTheme, styles.whiteTheme);
    }
    if (blackContainer.includes(location.pathname)) {
      return styles.blackTheme;
    }
    return styles.whiteTheme;
  };

  return (<div className={checkTheme()}>
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>
            {splitStringByBr(context?.dataContext.ready_to_get_title as string).map((part, id) =>
              <span key={id}>
                {part}
                <br />
              </span>)}
          </h1>
          <p className={styles.description}>{context?.dataContext.ready_to_get_subtitle}</p>
        </div>
        <CustomButton className={styles.btn} onClick={onClick} variant={ButtonVariant.Primary}>
          {context?.dataContext.ready_to_get_button_text}
        </CustomButton>
      </div>
    </div>
  </div>);
};
