import React, {useCallback, useContext} from 'react';

import {CustomButton} from '../../../components/customButton/CustomButton';
import {ButtonVariant} from '../../../utils/constants/buttonVariantEnum';

import {ReliabilitySafetyContext} from '../../../context/ReliabilitySafetyContext';

import {isValidURL} from '../../../utils/hooks/isValidUrl';

import HighlightText from '../../../components/highlightText/HighlightText';

import styles from './beGatewaySafety.module.scss';

const BeGatewaySafety: React.FunctionComponent = () => {
  const context = useContext(ReliabilitySafetyContext);

  const onClick = useCallback(() => {
    if(isValidURL(context?.dataContext.begateway_safety_button_link)) {
      window.open(context?.dataContext.begateway_safety_button_link, '_blank');
    }
  }, [context?.dataContext.begateway_safety_button_link]);


  const renderSubList = useCallback((item: any, index: number) => {
    return(
      <li key={index} className={styles.subItem}>
        {item.begateway_safety_list_text_item}
      </li>
    );
  }, []);

  const renderListItems = useCallback((item: any, index: number) => {
    return(
      <li key={index} className={styles.listItem}>
        <span>{item.begateway_safety_list_title}</span>
        {item.begateway_safety_list_text}
        {item.begateway_safety_list_text_in_list && (
          <ul className={styles.listSubItem}>
            {item.begateway_safety_list_text_in_list.map(renderSubList)}
          </ul>
        )}
      </li>
    );
  }, [renderSubList]);

  const renderLists = useCallback((arr: Array<any>) => {

    const length = arr.length;
    const middleIndex = Math.floor(length / 2);

    const firstArray = arr.slice(0, middleIndex + (length % 2));
    const secondArray = arr.slice(middleIndex + (length % 2));

    return(
      <>
        <ul className={styles.list}>
          {firstArray.map(renderListItems)}
        </ul>
        <ul className={styles.list}>
          {secondArray.map(renderListItems)}
          <li>
            {' '}
            <CustomButton
              className={styles.linkBlock}
              variant={ButtonVariant.Redirect}
              onClick={onClick}
            >
              {context?.dataContext.begateway_safety_button_text}
            </CustomButton>
          </li>
        </ul>
      </>
    );

  }, [renderListItems, context?.dataContext.begateway_safety_button_text, onClick]);

  return (
    <div className={styles.beGatewaySafety}>
      <div className={styles.content}>
        <h2 className={styles.title}>
          <HighlightText
            text={context?.dataContext.begateway_safety_title as string}
            highlight={context?.dataContext.begateway_safety_title_orange as string}
            className={''}
            highlightClass={styles.orange}
          />
        </h2>
        {context?.dataContext.begateway_safety_img_list && (
          <div className={styles.blockPartners}>
            {context?.dataContext.begateway_safety_img_list.map((item, index) => {
              return (
                item.begateway_safety_image_item
                &&
                <img src={item.begateway_safety_image_item} key={index} alt={'icon'} />
              );
            })}
          </div>
        )}
        <div className={styles.listsBlock}>
          {renderLists(context?.dataContext.begateway_safety_list as Array<any>)}
        </div>
      </div>
    </div>
  );
};

export default BeGatewaySafety;
