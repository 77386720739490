import {useContext, useEffect} from 'react';

import picture from '../../assets/images/sitemapPicture.png';
import HeaderBlock from '../../components/headerBlock/HeaderBlock';

import useApi from '../../api/useApi';
import {TFooterData, TUseCasesTextData} from '../../utils/types/pagesTypes';
import {API_URLS} from '../../api/routes';
import {FooterContext} from '../../context/FooterContext';
import {UseCasesContext} from '../../context/UseCasesContext';
import {Preloader} from '../../components/preloader/Preloader';

import UseCasesSlider from './useCasesSlider/UseCasesSlider';
import styles from './useCases.module.scss';

type TUseCasesData = {
  'use_case_fields': TUseCasesTextData,
}

const UseCases: React.FunctionComponent = () => {

  const {data, isLoading, error} =
      useApi<TUseCasesData>(API_URLS.USE_CASES, {method: 'GET'});

  const footer =
      useApi<TFooterData>(`${API_URLS.PRICING_OPTIONS}${API_URLS.FOOTER}`, {method: 'GET'});
  const footerContext = useContext(FooterContext);

  const context = useContext(UseCasesContext);

  useEffect(() => {
    if (footerContext && footer.data) {
      footerContext?.setDataContext(footer.data['get_ready_fields']);
    }
  },[footerContext, footer]);

  useEffect(() => {
    if (context && data) {
      context.setDataContext(data['use_case_fields']);
    }
  },[context, data]);

  if (isLoading || error || !data) {
    return <Preloader />;
  }

  return(
    <div className={styles.container}>
      <HeaderBlock src={picture} />
      <UseCasesSlider />
    </div>
  );
};

export default UseCases;
