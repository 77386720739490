import React, {useContext} from 'react';

import {ReactComponent as Question} from '../../assets/icons/question.svg';

import {ContactsContext} from '../../context/ContactsContext';

import {LinkCard} from '../../utils/types/commonTypes';

import styles from './linksCard.module.scss';

type Props = {
  links: LinkCard[],
};

export const LinksCard: React.FunctionComponent<Props> = ({links}) => {
  const context = useContext(ContactsContext);

  return (
    <div className={styles.card}>
      <Question />
      <p className={styles.cardTitle}>{context?.dataContext.other_form_title}</p>
      <ul className={styles.linksContainer}>
        {links.map((item, id) =>
          <li className={styles.link} key={id}>
            <img className={styles.img} src={item.img} alt="icon" />
            <a
              href={item.link}
              style={{textDecoration: item.underlineStyle ? 'underline' : 'none'}}
            >{item.text}</a>
          </li>)
        }
      </ul>
    </div>
  );
};

export default LinksCard;
