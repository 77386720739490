import React, {useContext} from 'react';

import PrivacyPolicyLists from '../../../components/privacyPolicyLists/PrivacyPolicyLists';

import {PrivacyPolicyContext} from '../../../context/PrivacyPolicyContext';

import styles from './generalProvisions.module.scss';

export const GeneralProvisions: React.FunctionComponent = () => {
  const context = useContext(PrivacyPolicyContext);

  return (
    <div className={styles.generalProvisions}>
      <h1 className={styles.globalTitle}>{context?.dataContext.privacy_policy_title}</h1>
      <h2 className={styles.title}>{context?.dataContext.general_provisions_title}</h2>
      <ol className={styles.listContainer}>
        {context?.dataContext.general_provisions_ol.map((item, id) => (
          <PrivacyPolicyLists key={id} text={item.general_provisions_li} />
        ))}
      </ol>
    </div>
  );
};

export default GeneralProvisions;
