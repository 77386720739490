import React, {useCallback, useContext, useState, useTransition} from 'react';
import classNames from 'classnames';

import {HomeContext} from '../../../context/HomeContext';

import {ReactComponent as Arrow} from '../../../assets/icons/whiteArrow.svg';
import {ReactComponent as CircleArrow} from '../../../assets/icons/circleArrow.svg';
import {ReactComponent as BlackArrow} from '../../../assets/icons/blackRow.svg';

import styles from './useCases.module.scss';

const UseCases: React.FunctionComponent = () => {
  const context = useContext(HomeContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPending, startTransition] = useTransition();

  const nextSlide = useCallback(() => {
    if (context?.dataContext.cases_slider){
      startTransition(() => {
        if(activeIndex === context.dataContext.cases_slider.length - 1) {
          setActiveIndex(0);
          return;
        }
        setActiveIndex(activeIndex + 1);
      });
    }
  }, [context, activeIndex]);

  const prevSlide = useCallback(() => {
    if (context?.dataContext.cases_slider) {
      startTransition(() => {
        if(activeIndex === 0) {
          setActiveIndex(context.dataContext.cases_slider.length - 1);
          return;
        }
        setActiveIndex(activeIndex - 1);
      });
    }
  }, [context, activeIndex]);


  const textClasses = classNames(
    styles.textBlock,
    isPending ? styles.fadeOut : styles.fadeIn,
  );

  const imgClasses = classNames(
    styles.imgWrap,
    isPending ? styles.fadeOut : styles.fadeIn,
  );

  return (
    <div className={styles.useCases}>
      <div className={styles.contentContainer}>
        <h3 className={styles.title}>{context?.dataContext.cases_title}</h3>
        <div className={styles.orangeBlock}>
          <div className={textClasses}>

            <h3 className={styles.orangeBlockTitle}>
              {context?.dataContext.cases_slider[activeIndex].title}
            </h3>
            <p className={styles.orangeBlockSubTitle}>
              {context?.dataContext.cases_slider[activeIndex].subtitle}
            </p>
            <div className={styles.textContent}>
              {context?.dataContext.cases_slider[activeIndex].textInformationItems
                .map((item, id) => {
                  return (
                    <div key={id}>
                      <p className={styles.itemTitle}>{item.title}</p>
                      <p className={styles.itemText}>{item.text}</p>
                    </div>
                  );
                })}
            </div>
            <a
              href={context?.dataContext.cases_slider[activeIndex].case_link}
              className={styles.linkContainer}
            >
              <Arrow />
              <span>Read the full story</span>
            </a>
            <div className={styles.arrowsBlock}>
              <CircleArrow
                style={{transform: 'rotate(-180deg)'}}
                className={styles.circleArrow}
                onClick={prevSlide}
              />
              <CircleArrow className={styles.circleArrow} onClick={nextSlide} />
            </div>
          </div>
        </div>
      </div>
      <div className={imgClasses}>
        <picture>
          <source
            srcSet={context?.dataContext.cases_slider[activeIndex].img}
            media="(min-width: 1440px)"
          />
          <source
            srcSet={context?.dataContext.cases_slider[activeIndex].img_md}
            media="(min-width: 744px)"
          />
          <source
            srcSet={context?.dataContext.cases_slider[activeIndex].img_s}
            media="(min-width: 320px)"
          />
          <img
            src={context?.dataContext.cases_slider[activeIndex].img}
            alt="slider"
            className={styles.manPhoto} />
        </picture>
      </div>
      <div className={styles.arrowsBlockDesktop}>
        <BlackArrow
          style={{transform: 'rotate(-180deg)'}}
          className={styles.blackArrow}
          onClick={prevSlide}
        />
        <BlackArrow className={styles.blackArrow} onClick={nextSlide} />
      </div>
    </div>
  );
};

export default UseCases;
