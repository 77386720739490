import {FC} from 'react';
import cn from 'classnames';

import {ISPLConstant} from '../../../../config/config';

import styles from './variantDescSPL.module.scss';

interface VariantDescSPLProps {
  index: number;
  variantDesc: ISPLConstant;
  isActive: boolean;
  img: string;
  onClick: (value: number) => void;
}

export const VariantDescSPL: FC<VariantDescSPLProps> = ({
  index,
  variantDesc,
  isActive,
  img,
  onClick,
}) => {
  const onTitleClick = () => {
    onClick(index);
  };
  return (
    <div
      className={cn(styles.container, {[styles.container__active]: isActive})}
    >
      <div className={cn(styles.block, {[styles.block__active]: isActive})} />
      <div className={styles.annotationsWrapper}>
        <div
          onClick={onTitleClick}
          className={cn(styles.title, {[styles.title__active]: isActive})}
        >
          {variantDesc.link_title}
        </div>
        <div className={styles.desc}>{variantDesc.link_description}</div>
        <div className={styles.imgWrapper}>
          <img src={img} alt="" className={styles.img} />
        </div>
      </div>
    </div>
  );
};
