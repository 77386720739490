
import {useCallback, useContext} from 'react';

import {PaymentContext} from '../../../../context/PaymentContext';

import styles from './VisaToken.module.scss';


const VisaToken: React.FunctionComponent = () => {

  const context = useContext(PaymentContext);

  const renderText = useCallback((item: any, index: number) => {
    return <div className={styles.descr} key={index}>{item.visa_token_paragraph}</div>;
  }, []);

  const renderPicture = () => {
    return(
      <img src={context?.dataContext.visa_token_service_image} alt="token-img" />
    );
  };

  return(
    <div className={styles.block}>
      <div className={styles.container}>
        <h2 className={styles.title}>{context?.dataContext.visa_token_service_title}</h2>
		  	<div className={styles.imgTextBlockWrap}>
		  		<div className={styles.imgWrap}>
		  			{renderPicture()}
		  		</div>
		  		<div className={styles.textWrap}>
		  			{context?.dataContext.visa_token_service_text.map(renderText)}
		  		</div>
		  	</div>
      </div>
    </div>
  );
};

export default VisaToken;
