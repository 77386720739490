import React, {useContext} from 'react';

import PrivacyPolicyLists from '../../../components/privacyPolicyLists/PrivacyPolicyLists';

import {PrivacyPolicyContext} from '../../../context/PrivacyPolicyContext';

import styles from './finalProvisions.module.scss';

export const FinalProvisions: React.FunctionComponent = () => {
  const context = useContext(PrivacyPolicyContext);

  return (
    <div className={styles.finalProvisions}>
      <h2 className={styles.title}>{context?.dataContext.final_provisions_title}</h2>
      <ol className={styles.listContainer}>
        {context?.dataContext.final_provisions_ol.map((item, id) => (
          <PrivacyPolicyLists
            key={id}
            text={item.final_provisions_li}
            className={styles.listItem}
          />
        ))}
      </ol>
    </div>
  );
};

export default FinalProvisions;
