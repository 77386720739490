import React, {useContext} from 'react';

import {Slider} from '../slider/Slider';

import {HomeContext} from '../../context/HomeContext';

import {SliderItem} from './components/sliderItem/SliderItem';


import styles from './sliderHome.module.scss';

export const SliderHome: React.FunctionComponent = () => {

  const context = useContext(HomeContext);

  const slides = context?.dataContext.carousel as Array<any>;

  return (
    <div className={styles.container}>
      <div className={styles.block} />
      <Slider>
        {slides?.map((values, index) => {
          return(<SliderItem key={index} values={values} />);
        })}
      </Slider>
    </div>
  );
};
