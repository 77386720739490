import React, {FC, useContext} from 'react';

import processTransactions1 from '../../../../assets/images/processTransactions1.png';
import processTransactions2 from '../../../../assets/images/processTransactions2.png';
import processTransactions3 from '../../../../assets/images/processTransactions3.png';

import {OfferYourMerchantsContext} from '../../../../context/OfferYourMerchantContexts';

import styles from './processTransactionsOYM.module.scss';

export const ProcessTransactionsOYM: FC = () => {

  const context = useContext(OfferYourMerchantsContext);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {context?.dataContext.simplify_title}
      </div>
      <div className={styles.paragraphs}>
        <div className={styles.paragraphWrapper}>
          <img
            alt="" src={processTransactions1}
            className={styles.paragraphWrapper__imgProcessTransactions1}
          />
          <div className={styles.paragraphWrapper__transactionStatistic}>
            <h3 className={styles.paragraphWrapper__title}>
              {context?.dataContext.transaction_title}
            </h3>
            <p className={styles.paragraphWrapper__desc}>
              {context?.dataContext.transaction_subtitle}
            </p>
            <p className={styles.paragraphWrapper__annotations}>
              {context?.dataContext.transaction_annotation}
            </p>
          </div>
        </div>
        <div className={styles.paragraphWrapper}>
          <img
            alt=""
            src={processTransactions2}
            className={styles.processTransactions2}
          />
          <div className={styles.paragraphWrapper__financeService}>
            <h3 className={styles.paragraphWrapper__title}>
              {context?.dataContext.finance_service_title}
            </h3>
            <p className={styles.paragraphWrapper__desc}>
              {context?.dataContext.finance_service_subtitle}
            </p>
            <p className={styles.paragraphWrapper__annotations}>
              {context?.dataContext.finance_service_annotation}
            </p>
          </div>
        </div>
        <div className={styles.paragraphWrapper}>
          <img
            alt=""
            src={processTransactions3}
            className={styles.processTransactions3} />
          <div className={styles.paragraphWrapper__balanceManagement}>
            <h3 className={styles.paragraphWrapper__title}>
              {context?.dataContext.balance_management_title}
            </h3>
            <p className={styles.paragraphWrapper__desc}>
              {context?.dataContext.balance_management_subtitle}
            </p>
            <p className={styles.paragraphWrapper__annotations}>
              {context?.dataContext.balance_management_annotation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
