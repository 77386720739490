import React, {useContext} from 'react';

import {PrivacyPolicyContext} from '../../../context/PrivacyPolicyContext';

import styles from './personalDataForClient.module.scss';

export const PersonalDataForClient: React.FunctionComponent = () => {
  const context = useContext(PrivacyPolicyContext);

  const items = [
    {
      title: context?.dataContext.processing_data_title,
      text: context?.dataContext.processing_data_text,
    },
    {
      title: context?.dataContext.third_party_title,
      text: context?.dataContext.third_party_text,
    },
    {
      title: context?.dataContext.logotype_title,
      text: context?.dataContext.logotype_text,
    },
    {
      title: context?.dataContext.ensuring_information_title,
      text: context?.dataContext.ensuring_information_text,
    },
  ];

  return (
    <div className={styles.personalDataForClient}>
      <div className={styles.contentClient}>
        {items.map((item, id) => {
          return (
            <div key={id}>
              <p className={styles.title}>{item.title}</p>
              <p className={styles.text}>{item.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PersonalDataForClient;
