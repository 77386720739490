import {FC, useContext} from 'react';

import bigSeven from '../../../../assets/images/bigSeven.png';

import {AboutUsContext} from '../../../../context/AboutUsContext';

import HighlightText from '../../../../components/highlightText/HighlightText';

import {ContactPersonsHTCU} from './components/contactPersonsHTCU/ContactPersonsHTCU';
import styles from './howToContactUs.module.scss';

export const HowToContactUs: FC = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <img className={styles.img} alt="bigSeven" src={bigSeven} />
        <h1 className={styles.text}>
          <HighlightText
            text={context?.dataContext.how_to_connect_title as string}
            highlight={context?.dataContext.how_to_connect_title_bold as string}
            className={styles.text}
            highlightClass={styles.boldText}
          />
        </h1>
      </div>
      <ContactPersonsHTCU />
    </div>
  );
};
