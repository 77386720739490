import {useContext} from 'react';

import {ReportAndBalanceContext} from '../../../context/ReportAndBalanceContext';

import styles from './titleBlock.module.scss';

export const TitleBlock = () => {
  const context = useContext(ReportAndBalanceContext);

  return (
    <div className={styles.titleBlock}>
      <h1 className={styles.title}>{context?.dataContext.reports_title}</h1>
      <div className={styles.textBlock}>
        <h3 className={styles.suntitle}>{context?.dataContext.reports_subtitle}</h3>
        <p className={styles.text}>{context?.dataContext.reports_main_text}</p>
      </div>
    </div>
  );
};
