import {FunctionComponent, useState, useEffect} from 'react';
import {Select} from '@mantine/core';

import {useAppDispatch} from '../../utils/hooks/hooksRedux';

import {HeaderLink} from '../header/components/headerLink/HeaderLink';

import {ReactComponent as Close} from '../../assets/icons/close.svg';
import {ReactComponent as ArrowDown} from '../../assets/icons/arrowDown.svg';

import {headersNavigation} from '../../utils/constants/headerConstants';
import {PATH} from '../../utils/constants/routeConstants';

import {IHeaderLink} from '../../utils/types/headerTypes';

import {setIsOpen} from '../../store/reducers/shadowBlockSlice';

import {CustomButton} from '../customButton/CustomButton';
import {ButtonVariant} from '../../utils/constants/buttonVariantEnum';

import styles from './globalMenuMobile.module.scss';

const home: IHeaderLink = {
  id: 'home',
  name: 'Home',
  isGetContent: true,
  link: PATH.home,
  additionalMenu: null,
  additionalFooterMenu: null,
};

const newArrayObj: IHeaderLink[] = [home, ...headersNavigation];

export const GlobalMenuMobile: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const languages = ['en', 'es'];

  const [lang, setLang] = useState<string | undefined>((() => {
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage || 'en';
  }));

  const [isDown, setIsDown] = useState<boolean>(true);

  useEffect(() => {
    if (lang) {
      localStorage.setItem('language', lang);
    }
  }, [lang]);

  const changeLang = (newLang: string | null) => {
    if (newLang && newLang !== lang) {
      setLang(newLang);
      window.location.reload();
    };
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleClickCloseMenu = () => {
    dispatch(setIsOpen(false));
  };
  const handleClickLink = (
    {additionalMenu, link}: IHeaderLink,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (link === activeLink) {
      setActiveLink(null);
      e.preventDefault();
      return;
    }
    if (additionalMenu) {
      setActiveLink(link);
      e.preventDefault();
      return;
    }
    dispatch(setIsOpen(false));
    setActiveLink(null);
  };
  return (
    <div className={styles.container}>
      <div onClick={handleClickMenu} className={styles.wrapper}>
        <div className={styles.langAndCloseBtnsWrapper}>
          {lang && <Select
            variant="unstyled"
            data={languages}
            value={lang}
            onChange={changeLang}
            rightSection={isDown ? <ArrowDown /> : <ArrowDown className={styles.arrowUp} />}
            onDropdownOpen={() => { setIsDown(false); }}
            onDropdownClose={() => { setIsDown(true); }}
            styles={{rightSection: {pointerEvents: 'none'}}}
            classNames={{
              root: styles.langRoot,
              input: styles.langInput,
              dropdown: styles.langDropdown,
              item: styles.langItem,
              rightSection: styles.langArrow,
            }}
          />}
          <Close onClick={handleClickCloseMenu} />
        </div>
        <div className={styles.linkWrapper}>
          {newArrayObj.map((values, index) => (
            <HeaderLink
              key={values.id}
              activeLink={activeLink}
              handleClickLink={handleClickLink}
              values={values}
              positionHeaderLink={index}
            />
          ))}
        </div>
        <div className={styles.bottomBtnWrapper}>
          <CustomButton
            className={styles.btn}
            onClick={() => { }}
            variant={ButtonVariant.OutLine}
          >
            Contact sales
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
