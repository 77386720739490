import React, {useContext} from 'react';

import bigOne from '../../../../assets/images/bigOne.png';

import HighlightText from '../../../../components/highlightText/HighlightText';
import {AboutUsContext} from '../../../../context/AboutUsContext';

import styles from './whoWeAreAboutUs.module.scss';

export const WhoWeAreAboutUs = () => {

  const context = useContext(AboutUsContext);

  return (
    <div className={styles.container}>
      <img className={styles.img} alt="who we are" src={context?.dataContext.who_we_are_image} />
      <div className={styles.annotation}>
        <div className={styles.titleWrapper}>
          <img className={styles.titleWrapper__bigOne} alt="big one" src={bigOne} />
          <h1 className={styles.titleWrapper__title}>
            <HighlightText
              text={context?.dataContext.who_we_are_title as string}
              highlight={context?.dataContext.who_we_are_title_bold as string}
              className={styles.titleWrapper__title}
              highlightClass={styles.titleWrapper__title_big}
            />
          </h1>
        </div>
        <div className={styles.description}>
          {context?.dataContext.who_we_are_text_list.map((item, index) => {
            return(
              <p key={index}>
                {item.who_we_are_paragraph}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};
