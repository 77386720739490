import classNames from 'classnames';

import {TTable} from '../TableBlock';

import HighlightText from '../../../../components/highlightText/HighlightText';

import styles from './tableDesk.module.scss';

const TableDesk: TTable = ({
  renting_table_middle_column_title,
  renting_table_last_column_title,
  renting_table,
}) => {
  return(
    <div className={styles.tableDesk}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>
              <div className={styles.cell}>
              </div>
            </th>
            <th>
              <div className={styles.cell}>
                {renting_table_middle_column_title}
              </div>
            </th>
            <th>
              <div className={styles.cell}>
                {renting_table_last_column_title}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {renting_table?.map((row, index) => {
            return(
              <tr key={index}>
                <td>
                  <div className={styles.cell}>
                    {row.renting_table_first_column_text}
                  </div>
                </td>
                <td>
                  <div className={classNames(styles.cell, styles.value)}>
                    {row.renting_table_second_column_need_link
                      ?
                      <HighlightText
                        text={row.renting_table_middle_column_text}
                        highlight={row.renting_table_second_column_link_text}
                        className={''}
                        highlightClass={''}
                        href={row.renting_table_second_column_link}
                      />
                      :
                      <>
                        {row.renting_table_middle_column_text}
                      </>
                    }
                  </div>
                </td>
                <td>
                  <div className={classNames(styles.cell, styles.value)}>
                    {row.renting_table_third_column_need_link
                      ?
                      <HighlightText
                        text={row.renting_table_third_column_text}
                        highlight={row.renting_table_third_column_link_text}
                        className={''}
                        highlightClass={''}
                        href={row.renting_table_third_column_link}
                      />
                      :
                      <>
                        {row.renting_table_third_column_text}
                      </>
                    }
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

    </div>
  );
};

export default TableDesk;
